<template>
    <div>
        <div class="row" style="overflow: hidden">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="alert alert-info" role="alert">
                                    Produtos com o botão <strong>Baixar</strong> inativos, não
                                    possuem Ficha de Emergência disponíveis no portal, favor entre
                                    em contato com o atendimento.
                                </div>
                                <div class="col text-center" v-if="spinner">
                                    <span class="fa fa-spinner fa-spin fa-5x"></span>
                                </div>
                                <table class="table" v-else>
                                    <thead>
                                    <tr>
                                        <!-- <th scope="col">Código</th> -->
                                        <th scope="col">Produto</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="products.length > 0">
                                    <tr v-for="product in products" :key="product.id">
                                        <!-- <td>{{ product.code }}</td> -->
                                        <td>{{ product.name }}</td>
                                        <td>
                                            <button
                                                @click="fispq(product)"
                                                target="_blank"
                                                class="btn btn-primary"
                                                :disabled="!product.fispq"
                                            >
                                                Baixar
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody v-else>
                                    <tr>
                                        <td colspan="5">
                                            Nenhum documento disponível.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {createToaster} from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
});
export default {
    data() {
        return {
            products: [],
            spinner: true
        };
    },
    computed: {},
    components: {},
    methods: {
        fispq(product) {
          
            product = btoa(
                JSON.stringify({
                    code: product.code,
                    document_id: 4,
                    name: encodeURIComponent(product.name) 
                    
                })
            );
            this.$router.push("/admin/document/fispq/" + product);
        },
        async getProducts() {
            
            const [codes, products] = await Promise.allSettled([this.getCodes(), this.getCustomerProduct()]);
            
            codes.value.forEach( (code) => {

                code.product_code.forEach( (internalCode) => {

                    products.value.forEach( (product) => {
                        if (internalCode.product_code === product.code) this.products.push(product);
                    })
                })

            })

            this.spinner = false;
        },
        async getCodes() {
            const self = this;
            const api = this.$store.state.api + "intertox_codes?paginated=false&with[]=product_code";

            try {
                const response = await axios.get(api);
                return response.data.data;
            } catch (error) {
                this.$toast.error(error.response.data.message);
                self.loading = false;
                return [];
            }
        },
        async getCustomerProduct() {
            const self = this;
            const api = this.$store.state.api + "customer-products";

            try {
                const response = await axios.get(api);
                return Object.values(response.data.data);
            } catch (error) {
                self.$toast.error(error.response.data.message);
                self.loading = false;
                return [];
            }
        }
    },
    mounted() {
        const self = this;

        self.getProducts();
    },
};
</script>
<style scoped>
.card {
    margin: 45px;
}

#ModalNovo {
    padding: 15px;
}
</style>
