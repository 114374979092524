<template>
    <div class="bg-login">
        <img class="login-img" src="../assets/images/logo.png" alt/>
        <div class="login-container">
            <div class="text-center">
                <h6 class="login-welcome">Bem-vindo ao Portal do Cliente</h6>
            </div>
            <div class="forgot-password" v-show="forgot_password">
                <p class="text-center">
                    Insira o seu email para iniciar o processo de alteração de senha
                </p>
                <label class="login-label">E-mail</label>
                <br/>
                <input class="login-input" type="text" v-model="email"/>
                <br/>
            </div>
            <div class="login-form" v-show="!forgot_password">
                <label class="login-label">E-mail</label>
                <br/>
                <input
                    class="login-input"
                    v-on:keyup.enter="login"
                    type="text"
                    v-model="email"
                />
                <br/>
                <label class="login-label mt-3">Senha</label>
                <br/>
                <input
                    class="login-input"
                    v-on:keyup.enter="login"
                    type="password"
                    v-model="password"
                />
                <br/>
            </div>
            <div class="text-center">
                <div
                    class="login-forgot-password clickable"
                    @click="forgot_password = !forgot_password"
                >
                    {{ forgot_password_text }}
                </div>
               
            </div>            
            <button class="login-btn" @click="login" v-show="!forgot_password">
                Acessar o portal
            </button>
            <button class="login-btn" @click="forgot" v-show="forgot_password">
                Enviar
            </button>
            <hr>
            
            <div class="text-center">
                <router-link
                    v-show="!forgot_password"
                    class="login-forgot-password clickable"
                    to="/register"
                >
                    Ainda não tem Cadastro? Solicite seu acesso!
                </router-link>
            </div>  
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: "",
            password: "",
            forgot_password: false,
        };
    },
    computed: {
        forgot_password_text() {
            return this.forgot_password ? "Fazer login" : "Esqueci minha senha";
        },
    },
    methods: {
        login() {
            const self = this;
            const api = this.$store.state.api + "login";

            axios
                .post(api, {
                    email: self.email,
                    password: self.password,
                })
                .then((response) => {
                    self.$store.dispatch("setToken", response.data);
                })
                .catch((error) => {
                    // if (error.response.status == 403) this.$route.push({name: 'terms-of_use'})
                    this.$toast.error("Credenciais Inválidas");
                });
        },
        forgot() {
            const self = this;
            const api = this.$store.state.api + "user/recovery";

            axios
                .post(api, {
                    email: self.email,
                })
                .then((response) => {
                    this.$toast.success(
                        "Sucesso, enviamos um email para " +
                        self.email +
                        ". Por favor, confira a sua caixa de entrada! "
                    );
                })
                .catch((error) => {
                    this.$toast.error("Falha ao enviar email para " + self.email + ".");
                });
        },
    },
};
</script>
<style scoped>
body{
    height: 100vh !important;
}
.bg-login {
    background: rgba(0, 0, 0, 0) url("../assets/images/bg-login.jpg") no-repeat scroll;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 100%;
    height: 100vh;
    overflow-y: hidden;
    background-position: 30% 45%;
}
</style>
