<template>
    <div>
        <div class="card" v-if="$store.state.user.type_id == 4">
            <div :class="'card-header '">
                <h5 class="card-title">Alerta de Manutenção</h5>
            </div>
            <div class="card-body">
                <div class="row mt-4">
                    <div class="col-md-2">Status Manutenção:</div>
                    <div class="col-md-10">
                        <Toggle
                            class="toggleStyle"
                            v-model="config.is_maintenance"
                            v-bind="simNao"
                        />
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-3">
                        <label>Data/Hora Início:</label>
                        <input
                            type="datetime-local"
                            class="form-control"
                            v-model="config.start_maintenance"
                        />
                    </div>
                    <div class="col-md-3">
                        <label>Data/Hora Fim:</label>
                        <input
                            type="datetime-local"
                            class="form-control"
                            v-model="config.end_maintenance"
                        />
                    </div>
                    <div class="col-md-10 mt-2">
                        <label>Mensagem para o Usuário:</label>
                        <input
                            type="text"
                            class="form-control"
                            v-model="config.msg_maintenance"
                        />
                    </div>

                    <div class="col-md-12 mt-3">
                        <button
                            class="btn btn-primary white float-right"
                            @click="saveConfigs"
                        >
                            Salvar Configurações
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="$store.state.user.type_id == 1 || $store.state.user.type_id == 4">
            <div class="card-header">
                <h5 class="card-title">Termos de uso</h5>
            </div>
            <div class="card-body">
                <div class="ckeditor-classic">
                    <ckeditor v-model="termsOfUse" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <div class="row">
                    <div class="col mt-4">
                        <button
                            class="btn btn-success white float-right"
                            @click="saveTerms"
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div :class="'card-header '">
                <h5 class="card-title">Boletos - Mensagens Adicionais</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <button
                            class="btn btn-success white float-right"
                            @click="openModalNovo"
                        >
                            Nova Mensagem
                        </button>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Código do Banco</th>
                                <th scope="col">Mensagem</th>
                                <th scope="col">Última Atualização</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody v-if="messages.length > 0">
                            <tr v-for="message in messages" :key="message.id">
                                <td>{{ message.id }}</td>
                                <td>
                                    <span v-html="bank_image(message.bank_id)"></span>
                                </td>
                                <td>{{ message.description }}</td>
                                <td>{{ message.updated_at }}</td>
                                <td align="center">
                                    <font-awesome-icon
                                        :icon="['fa', 'trash-alt']"
                                        @click="deleteMessage(message.id)"
                                        class="clickable"
                                    />
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="5" align="center">
                                    Nenhuma mensagem cadastrada.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div :class="'card-header '">
                <h5 class="card-title">Destinatários dos E-mails</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <button
                            class="btn btn-success white float-right"
                            @click="openModalNovoEmail"
                        >
                            Novo
                        </button>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <table class="table">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">E-mail</th>
                                <th scope="col">Módulo</th>
                                <th scope="col">Ações</th>
                            </tr>
                            </thead>
                            <tbody v-if="emails.length > 0">
                            <tr v-for="email in emails" :key="email.id">
                                <td>{{ email.id }}</td>

                                <td>{{ email.mail }}</td>
                                <td>{{ changeModule(email.module) }}</td>
                                <td align="center">
                                    <font-awesome-icon
                                        :icon="['fa', 'trash-alt']"
                                        @click="deleteEmail(email.id)"
                                        class="clickable"
                                    />
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="5" align="center">Nenhum e-mail cadastrado.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="$store.state.user.type_id == 1 || $store.state.user.type_id == 4">
            <div :class="'card-header '">
                <h5 class="card-title">De/Para Intertox</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <button
                            class="btn btn-success white float-right"
                            @click="openModalNovoCodigo"
                        >
                            Novo Código
                        </button>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="text-center">#</th>
                                <th class="text-center">Código Intertox</th>
                                <th class="text-center">Código Produto</th>
                                <th class="text-center">Ações</th>
                            </tr>
                            </thead>
                            <tbody v-if="codes.length > 0">
                            <tr v-for="code in codes" :key="code.id">
                                <td class="text-center">{{ code.id }}</td>

                                <td class="text-center">{{ code.code }}</td>
                                <td class="text-center" v-if="code.product_code">
                                    <template v-for="product_code in code.product_code">
                                        <span>
                                            {{ product_code.product_code }},
                                        </span>
                                    </template>
                                </td>
                                <td class="text-center" v-else></td>
                                <td class="text-center">
                                    <font-awesome-icon
                                        :icon="['fa', 'trash-alt']"
                                        @click="deleteCode(code.id)"
                                        class="clickable"
                                    />
                                </td>
                            </tr>
                            </tbody>
                            <tbody v-else>
                            <tr>
                                <td colspan="3" class="text-center">Nenhum código cadastrado.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Produto -->

        <div id="modalNovo">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="ModalNovo">
                            <div class="row">
                                <div class="col-md-6 bold">Nova mensagem de boleto</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModalNovo()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <label>Código do Banco</label>
                                    <select v-model="new_message.bank_id" class="form-select">
                                        <option value="237">Bradesco</option>
                                        <option value="756">Sicoob</option>
                                        <option value="001">Banco do Brasil</option>
                                        <option value="341">Itaú</option>
                                    </select>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <label>Mensagem a imprimir no boleto</label>
                                    <textarea
                                        v-model="new_message.description"
                                        class="form-control"
                                        cols="30"
                                        rows="10"
                                    ></textarea>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <button
                                        class="btn btn-success white float-right"
                                        @click="saveMessage"
                                    >
                                        Salvar Mensagem
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Novo Codigo -->

        <div id="modalNovoCodigo">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="ModalNovo">
                            <div class="row">
                                <div class="col-md-6 bold">Novo código intertox</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModalNovoCodigo()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <label>Código do Intertox</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="new_code.code"
                                    />
                                </div>
                                <div class="col-md-12 mt-3">
                                    <label>Código dos Produtos</label>
                                    <v-select
                                        multiple
                                        taggable
                                        v-model="new_code.product_codes"
                                        label="title"
                                    ></v-select>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <button
                                        class="btn btn-success white float-right"
                                        @click="saveCodes"
                                    >
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Novo Email -->

        <div id="modalNovoEmail">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="ModalNovo">
                            <div class="row">
                                <div class="col-md-6 bold">Novo e-mail de destino</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModalNovoEmail()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <label>E-mail</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="new_email.mail"
                                    />
                                </div>
                                <div class="col-md-12 mt-3">
                                    <label>Módulo de Envio</label>
                                    <select v-model="new_email.module" class="form-select">
                                        <option value="1">Relatório de Cotações</option>
                                        <option value="2">Relatório de Pedidos</option>
                                        <option value="3">
                                            Notificação de Nova Cotação - Público
                                        </option>
                                        <option value="4">
                                            Notificação de Nova Cotação - Privado
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <button
                                        class="btn btn-success white float-right"
                                        @click="saveEmail"
                                    >
                                        Salvar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {createToaster} from "@meforma/vue-toaster";
import Toggle from "@vueform/toggle";
import vSelect from "vue-select";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const toaster = createToaster({
    position: "top-right",
});
export default {
    data() {
        return {
            messages: [],
            codes: [],
            emails: [],
            new_message: {},
            new_code: {
                code: '',
                product_codes: ''
            },
            new_email: {},
            config: {
                is_maintenance: false
            },
            simNao: {
                onLabel: "SIM",
                offLabel: "NÃO",
            },
            editor: ClassicEditor,
            termsOfUse: '',
            editorConfig: {
                pasteFromPasteText: true,
                additionalFilters: 'plainText',
                toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo', '|', 'bulletedList', 'numberedList'],
            }
        };
    },
    computed: {},
    components: {
        Toggle,
        vSelect,
        ckeditor: CKEditor.component
    },
    methods: {
        changeModule(id) {
            id = id + "";
            switch (id) {
                case "1":
                    return "Relatório de Cotações";
                case "2":
                    return "Relatório de Pedidos";
                case "3":
                    return "Notificação de Nova Cotação - Publico";
                case "4":
                    return "Notificação de Nova Cotação - Privado";
                default:
                    return "N/A";
            }
        },
        bank_image(bank_id) {
            bank_id = bank_id + "";
            switch (bank_id) {
                case "237":
                    return "<img src='/banks/bradesco.png'>";
                case "001":
                    return "<img src='/banks/banco_do_brasil.png'>";
                case "341":
                    return "<img src='/banks/itau.png'>";
                case "756":
                    return "<img src='/banks/sicoob.png'>";
                default:
                    return "N/A";
            }
        },
        openModalNovo() {
            show_modal("modalNovo");
        },
        closeModalNovo() {
            close_modal("modalNovo");
        },
        openModalNovoCodigo() {
            show_modal("modalNovoCodigo");
        },
        closeModalNovoCodigo() {
            close_modal("modalNovoCodigo");
        },
        openModalNovoEmail() {
            show_modal("modalNovoEmail");
        },
        closeModalNovoEmail() {
            close_modal("modalNovoEmail");
        },
        saveEmail() {
            const self = this;
            const api = this.$store.state.api + "email_senders";

            self.closeModalNovo();

            axios
                .post(api, self.new_email)
                .then((response) => {
                    this.$toast.success("Email incluído com sucesso!");
                    self.getEmails();
                    self.new_email = {};
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);
                });
        },
        saveConfigs() {
            const self = this;
            const api = this.$store.state.api + "save-configs/";

            axios
                .post(api, self.config)
                .then((response) => {
                    self.$toast.success("Configurações atualizadas com sucesso!");
                    self.getConfigs();

                    self.$store.dispatch("setMaintenance", response.data.is_maintenance);
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);
                });
        },
        saveTerms() {
            const self = this;
            const api = this.$store.state.api + "save-terms-of-use/";

            axios
                .post(api, {terms: self.termsOfUse})
                .then(() => {
                    self.$toast.success("Configurações atualizadas com sucesso!");
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);
                });
        },
        saveMessage() {
            const self = this;
            const api = this.$store.state.api + "ticket_messages/";

            self.closeModalNovo();

            axios
                .post(api, self.new_message)
                .then((response) => {
                    this.$toast.success("Mensagem criada com sucesso!");
                    self.getMessages();
                    self.new_message = {};
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);
                });
        },
        saveCodes() {
            const self = this;
            const api = this.$store.state.api + "intertox_codes_new";

            self.closeModalNovoCodigo();

            axios
                .post(api, self.new_code)
                .then((response) => {
                    this.$toast.success("Código criado com sucesso!");
                    self.new_code = {};
                    self.getCodes();
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);
                });
        },
        deleteEmail(id) {
            const self = this;
            const api = this.$store.state.api + "email_senders/" + id;

            axios
                .delete(api)
                .then((response) => {
                    this.$toast.success("E-mail excluído com sucesso!");
                    self.getEmails();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        deleteMessage(id) {
            const self = this;
            const api = this.$store.state.api + "ticket_messages/" + id;

            axios
                .delete(api)
                .then((response) => {
                    this.$toast.success("Mensagem excluída com sucesso!");
                    self.getMessages();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        deleteCode(id) {
            const self = this;
            const api = this.$store.state.api + "intertox_codes/" + id;

            axios
                .delete(api)
                .then((response) => {
                    this.$toast.success("Código excluído com sucesso!");
                    self.getCodes();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getMessages() {
            const self = this;
            const api = this.$store.state.api + "ticket_messages/";

            axios
                .get(api)
                .then((response) => {
                    self.messages = response.data.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getTerms() {
            const self = this;
            const api = this.$store.state.api + "get-terms/";

            axios
                .get(api)
                .then((response) => {
                    const div = document.querySelector('.ck-content');
                    div.style.height = '450px';
                    self.termsOfUse = response.data[0] ? response.data[0].terms : '';
                })
                .catch((error) => {
                    this.$toast.error(error.response);
                    self.loading = false;
                });
        },
        getConfigs() {
            const self = this;
            const api = this.$store.state.api + "configurations/";

            axios
                .get(api, self.config)
                .then((response) => {
                    self.config = response.data.data[0];
                    self.config.is_maintenance =
                        self.config.is_maintenance == 1 ? true : false;
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);
                });
        },
        getCodes() {
            const self = this;
            const api =
                this.$store.state.api +
                "intertox_codes?paginated=false&with[]=product_code";

            axios
                .get(api)
                .then((response) => {
                    self.codes = response.data.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getEmails() {
            const self = this;
            const api = this.$store.state.api + "email_senders?paginated=false";

            axios
                .get(api)
                .then((response) => {
                    self.emails = response.data.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
    },
    mounted() {
        this.getMessages();
        this.getConfigs();
        this.getCodes();
        this.getEmails();
        this.getTerms();
    },
};
</script>
<style scoped>
.card {
    margin: 45px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    max-height: 10px; /* Defina a altura desejada */
}


#ModalNovo {
    padding: 15px;
}
</style>
