<template>
  <div class="container-fluid">
    <!-- <div class="col-12 col-md-12">
      <h3 style="text-align: center">Requisições de logs</h3>
      <MonthlyAmountOrders></MonthlyAmountOrders>
    </div> -->
    <hr />
    <div class="alert alert-primary" role="alert">
      Para usar corretamente o Logs use o filtro <strong>Decrescente!</strong>
    </div>
    <base-table
      :ref="table"
      :filters="filters"
      :crudName="crudName"
      :newText="newText"
      :table="table"
      :endPoint="endPoint"
      :enableAdd="false"
      :enableEdit="false"
      :enableDelete="false"
      :relationships="relationships"
      :extras="extras"
      :columns="columns"
      :enableExtract="false"
      :orders="orders"
      @body="body"
    ></base-table>
  </div>
</template>

<script>
import MonthlyAmountOrders from "@/components/Charts/MonthAmountOrders.vue";
import BaseTable from "@/components/Layouts/BaseTable.vue";
export default {
  data() {
    return {
      extras: [],
      crudName: "Tempo de Logs",
      filters: [
        {
          id: "id",
          name: "id",
          label: "ID",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
        {
          id: "user_name",
          name: "user_name",
          label: "Nome do Usuário",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
        {
          id: "start_date",
          name: "start_date",
          label: "ÍNICIO DA REQUISIÇÃO",
          value: "",
          type: "date",
          class: "form-control",
          columnSize: "col-4 mt-3",
        },
        {
          id: "end_date",
          name: "end_date",
          label: "FIM DA REQUISIÇÃO",
          value: "",
          type: "date",
          class: "form-control",
          columnSize: "col-4 mt-3",
        },
        {
          id: "status",
          name: "status",
          label: "STATUS",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
      ],
      newText: "",
      table: "time_logs",
      endPoint: "time_logs/",
      relationships: [],
      orders: [
        {
          name: "ID",
          column: "id",
          selected: true,
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "ínicio da Requisição",
          field: "start_date_f",
        },
        {
          label: "Fim da Requisição",
          field: "end_date_f",
        },
        {
          label: "Total em Milissegundos",
          field: "total",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Mensagem",
          field: "message",
        },
        {
          label: "Rotina",
          field: "routine",
        },
        {
          label: "Nome do Usuário",
          field: "user_name",
        },
        {
          label: "Body",
          type: "button",
          callbackFunction: "body",
          class: "btn btn-info btn-sm",
          condition: function (row) {
            return false;
          },

          conditionShow: function (row) {
            return true;
          },
        },
      ],
    };
  },
  components: {
    MonthlyAmountOrders,
    BaseTable,
  },
  methods: {
    body(data) {
      this.$router.push("/admin/timelogs/" + data.id);
    },
    setLastMonthAmount(value) {
      this.last_month_amount = value;
    },
  },
};
</script>