<template>
  <tr>
    <td>{{ invoice.E1_NUM }}</td>
    <td>{{ format_money_br(invoice.E1_VALOR) }}</td>
    <td>{{ format(invoice.E1_VENCTO) }}</td>
    <td>
      <!-- <template v-if="invoice.E1_NUMBCO.trim() != ''">
        <button
          class="btn btn-info white"
          @click="download_ticket(invoice.E1_NUM)"
        >
          Baixar
        </button>
        <button
          @click="copyCode(invoice.E1_NUM)"
          class="btn fa fa-copy"
        ></button>
      </template>
      <template v-else>
        <span>Débito em conta</span>
      </template> -->
    </td>
    <!-- <td>{{ download_ticket(invoice.E1_NUM) }}</td> -->
    <!-- <td>{{ Math.abs(diff_in_days(invoice.E1_VENCTO)) }}</td> -->
  </tr>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["invoice"],
  methods: {
    notOverdue(date) {
      return moment(date, "DD/MM/YY").isSameOrAfter(this.today);
    },
    format(date) {
      return moment(date, "DD/MM/YY").format("DD/MM/YYYY");
    },
    // diff_in_days(date){
    //     return moment(date, 'DD/MM/YY').diff(moment().format('YYYY-MM-DD'), 'days')
    // },
    format_money_br(value) {
      let value_converted = parseFloat(value);
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value_converted);
    },
    status_name(status) {
      switch (status) {
        case "A":
          return "Aberto";
        case "B":
          return "Baixado";
        case "R":
          return "Reliquidado";
        default:
          return "";
      }
    },
    download_ticket(num) {
      window
        .open(
          // "http://localhost:8001/api/v1/invoices/boleto?num=" + num,
          "/api/v1/invoices/boleto?num=" +
            num,
          "_blank"
        )
        .focus();
    },
    copyCode(invoiceNumber) {
      const self = this;
      const api = this.$store.state.api + "invoices/boleto";

      axios
        .get(api, {
          params: {
            invoiceNumber: invoiceNumber,
            codigoDeBarras: true,
          },
        })
        .then((response) => {
          navigator.clipboard.writeText(response.data);
          this.$toast.success("Código de barras copiado com sucesso");
        })
        .catch((error) => {
          this.$toast.error("Não foi possível copiar o código de barras");
        });
    },
  },
};
</script>
<style>
.fa-copy {
  font-size: 25px !important;
}
</style>
