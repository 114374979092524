<template>
    <div class='gauge-container'>
        <div class='text-center'>
            <b>Porcentagem de prazo consumido</b>
        </div>
        <div class='gauge-draw-container'>
            <div class="gauge" :style="'--rotation: ' + graph_rotation + 'deg; --color:#1b96d4; --background:#e9ecef;'">
                <div class="percentage"></div>
                <div class="mask"></div>
                <span class="value">{{ period_percentage + '%' }}</span>
                
                <!-- <div v-if="expiring && selected_contract" class="expiring alert alert-danger" role="alert">
                    Este contrato pode esgotar em volume antes que chegue sua data de fim em {{ selected_contract.CN9_DTFIM }}
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'selected_contract',
        ],
        computed: {
            expiring(){
                if(!this.selected_contract) return

                let period_percentage = this.calculate_period_percentage()
                return period_percentage < 100 && this.period_percentage - this.date_percentage > 10
            },
            period_percentage() {
                if(!this.selected_contract) return

                let start_date_moment = moment(this.selected_contract.CN9_DTINIC, 'DD/MM/YYYY')
                let end_date_moment = moment(this.selected_contract.CN9_DTFIM, 'DD/MM/YYYY')

                let total_days = end_date_moment.diff(start_date_moment, 'days')

                let completed_days = moment().diff(start_date_moment, 'days')

                let completed_days_percentage = completed_days * 100 / total_days
                completed_days_percentage = completed_days_percentage.toFixed(2)
                
                let return_percentage = completed_days_percentage > 100 ? 100 : completed_days_percentage
                
                return return_percentage
            },
            graph_rotation(){
                if(!this.selected_contract) return

                return this.period_percentage * 180 / 100
            },
        },
    };
</script>