<template>
  <div class="container-fluid">
    <div class="row mb-3 mr-3">
      <div class="col-md-12">
        <a
          :href="$store.state.api + 'user/export'"
          target="_blank"
          class="btn btn-primary float-right"
          >Exportar Usuários</a
        >
        <a
          :href="$store.state.api + 'userpermissions/export'"
          target="_blank"
          class="btn btn-primary float-right me-2"
          >Exportar Permissões de Usuários</a
        >
      </div>
    </div>
    <base-table
      :ref="table"
      :filters="filters"
      :crudName="crudName"
      :newText="newText"
      :table="table"
      :endPoint="endPoint"
      :enableAdd="
        $store.getters.user.type_id == 1 || $store.getters.user.type_id == 4
      "
      :enableEdit="true"
      :enableDelete="true"
      :relationships="relationships"
      :extras="extras"
      :columns="columns"
      :orders="orders"
      :enableExtract="false"
      @edit="edit"
      @inactivate="inactivate"
      @deletar="deletar"
    ></base-table>
  </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
export default {
  data() {
    return {
      crudName: "Usuários",
      filters: [],
      newText: "Novo Usuário",
      table: "userList",
      endPoint: "users/",
      relationships: ["customer"],
      extras: [],
      orders: [
        {
          name: "",
          column: "",
        },
        {
          name: "ID",
          column: "id",
        },
        {
          name: "Nome",
          column: "name",
        },
      ],
      filters: [
        {
          name: "name",
          label: "Nome",
          value: "",
          type: "text",
          applied: false,
          class: "form-control",
          columnSize: "col-6",
        },
        {
          name: "customer[name]",
          label: "Cliente",
          value: "",
          type: "text",
          applied: false,
          class: "form-control",
          columnSize: "col-6",
        },
        {
          name: "type_id",
          label: "Tipo do Usuário",
          value: "",
          type: "user_type",
          applied: false,
          class: "form-control",
          columnSize: "mt-2 col-6",
        },
      ],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Cliente",
          field: "customer.name",
        },
        {
          label: "Tipo do Usuário",
          field: "type_name",
        },
        {
          label: "Status",
          type: "fieldClickable",
          callbackFunction: "inactivate",
          callback: function (row) {
            if (row.status == "1") {
              return "<span class='badge badge-success'>Ativo</span>";
            }
            if (row.status == "2") {
              return "<span class='badge badge-danger'>Inativo</span>";
            }
          },
        },
        {
          label: "Editar",
          type: "button",
          callbackFunction: "edit",
          class: "btn btn-info btn-sm",
          condition: function (row) {
            return false;
          },
          conditionShow: function (row) {
            return true;
          },
        },
        {
          label: "Deletar",
          type: "button",
          callbackFunction: "deletar",
          class: "btn btn-danger btn-sm",
          condition: function (row) {
            return false;
          },
          conditionShow: function (row) {
            return true;
          },
        },
      ],
    };
  },
  components: {
    BaseTable,
  },
  methods: {
    edit(row) {
      this.$router.push("/admin/user/" + row.id);
    },
    inactivate(row) {
      const self = this;
      const api = this.$store.state.api + "users/change-status";

      axios
        .post(api, row)
        .then((response) => {
          self.$refs.userList.get_data(1);
        })
        .catch((error) => {
          var msg = error.response.data;

          if (typeof msg === "object") {
            let html = "<ul>";

            $.each(msg, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            msg = html;
          }

          toaster.error(msg);

          self.loading = false;
        });
    },
    deletar(row) {
      const self = this;
      const api = this.$store.state.api + "users/" + row.id;

      this.$swal
        .fire({
          title: "Deseja deletar o registro?",
          text: "Essa operação não pode ser desfeita",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, deletar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(api).then((response) => {
              self.$refs.userList.get_data(1);
              this.$swal(
                "Sucesso",
                "Registro excluído com sucesso!",
                "success"
              );
            });
          }
        });
    },
  },
};
</script>