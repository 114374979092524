<template>
    <div class="container-fluid">
        <base-table
            :ref="table"
            :filters="filters"
            :crudName="crudName"
            :newText="newText"
            :table="table"
            :endPoint="endPoint"
            :enableAdd="false"
            :enableEdit="true"
            :enableDelete="true"
            :enableExtract="true"
            :layoutNames="layoutNames"
            :relationships="relationships"
            :enableSaveColumns="true"
            :extras="extras"
            :orders="orders"
            :columns="columns"
            @report="report"
            :styleHeader="'border-bottom: 5px solid orange'"
            @tracking="tracking"
            @danfe="danfe"
        ></base-table>
    </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
import env from "../../env.js";

export default {
    name: "DeliveryList",
    data() {
        return {
            extras: [],
            orders: [
                {
                    name: "",
                    column: "",
                },
                {
                    name: "N° da Nota",
                    column: "DAI_NFISCA",
                },
                {
                    name: "N° do Pedido",
                    column: "DAI_PEDIDO",
                },
                {
                    name: "Produto",
                    column: "B1_DESC",
                },
                {
                    name: "Previsão Entrega",
                    column: "C6_ENTREG",
                },
                {
                    name: "Data Faturamento",
                    column: "F2_EMISSAO",
                    selected: true,
                },
            ],
            filters: [
                {
                    id: "deliveryType",
                    type: "hidden",
                    name: "status",
                    label: "Pendentes",
                    value: "delivered",
                    checked: true,
                },
                {
                    name: "DAI_NFISCA",
                    label: "Nota Fiscal",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "CN9_ZCONCL",
                    label: "N° Contrato do cliente",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control ",
                    columnSize: "col-3",
                },
                {
                    name: "DAI_PEDIDO",
                    label: "N° do Pedido",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control ",
                    columnSize: "col-3",
                },
                {
                    name: "C6_NUMPCOM",
                    label: "Pedido do Cliente",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control ",
                    columnSize: "col-3",
                },
                {
                    name: "B1_DESC",
                    label: "Produto",
                    value: "",
                    type: "product",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "C6_ENTREG",
                    label: "Previsão de Entrega",
                    value: "",
                    type: "date",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3  mt-2",
                },
                {
                    name: "A1_ZAPLENT",
                    label: "Local de Entrega",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3 mt-2",
                },
                {
                    name: "F2_EMISSAO_INI",
                    label: "Data Faturamento Início",
                    value: "",
                    type: "date",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3 mt-2",
                },
                {
                    name: "F2_EMISSAO_FIM",
                    label: "Data Faturamento Fim",
                    value: "",
                    type: "date",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3 mt-2",
                },
                {
                    id: "DAK_FILIAL",
                    name: "DAK_FILIAL",
                    label: "Filial",
                    type: "branch",
                    value: "",
                    applied: false,
                    columnSize: "col-6",
                },
            ],
            crudName: "Entregas",
            newText: "",
            table: "delivery-list",
            endPoint: "deliveries/",
            relationships: [],
            columns: [],
        };
    },
    components: {
        BaseTable,
    },
    methods: {
        danfe(data) {
            const self = this;

            if (data.DAI_NFISCA.trim()) {
                data = btoa(
                    JSON.stringify({
                        nota_fiscal: data.DAI_NFISCA.trim(),
                        loja_ent: data.DAI_LOJA.trim(),
                        contrato: data.C5_CONTRA.trim(),
                        filial: data.DAK_FILIAL.trim(),
                    })
                );
                this.$router.push("/admin/invoice/danfe/" + data);
                // window
                //   .open(
                //     self.$store.state.api +
                //       "invoices/danfe/" +
                //       data.E1_NUM.trim() +
                //       "/" +
                //       data.C5_LOJAENT.trim() +
                //       "/" +
                //       data.C5_MDCONTR,
                //     "_blank"
                //   )
                //   .focus();
            } else {
                alert("DANFE não disponível para esse título");
            }
        },
        tracking(data) {
            var dtEntrega = data.C5_ENTREAL.split("/");

            var ano = dtEntrega[2];
            var mes = dtEntrega[1];
            var dia = dtEntrega[0];

            if (ano.length == 2) {
                ano = "20" + ano;
            }

            dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

            var prevEntrega = data.C6_ENTREG.split("/");

            var ano_p = prevEntrega[2];
            var mes_p = prevEntrega[1];
            var dia_p = prevEntrega[0];

            if (ano_p.length == 2) {
                ano_p = "20" + ano_p;
            }

            prevEntrega = new Date(ano_p + "-" + mes_p + "-" + dia_p + " 03:00:00");

            var today = new Date();

            var yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            if (
                (dtEntrega != "" && dtEntrega < today) ||
                (prevEntrega != "" && prevEntrega < yesterday)
            ) {
                data = btoa(
                    JSON.stringify({
                        F2_CHVNFE: data.F2_CHVNFE,
                    })
                );

                this.$router.push("/admin/delivery/canhoto/" + data);
            } else {
                data = btoa(
                    JSON.stringify({
                        placa: data.DA3_PLACA,
                        placaCav: data.DAK_VEIC2,
                        transportadora: data.A4_NOME,
                        notafiscal: data.F2_DOC,
                    })
                );

                this.$router.push("/admin/delivery/tracking/" + data);
            }
        },
        report(data) {
            var filial = data.F2_FILIAL.split('-')[0].trim()

            const obj = {
                filial: filial,
                doc: data.DAI_NFISCA,
                serie: data.DAI_SERIE
            }
            data = btoa(
                JSON.stringify({
                    nota_fiscal: data.DAI_NFISCA.trim(),
                })
            );
            this.$store.dispatch("setOrderReport", obj);
            this.$router.push("/admin/order/report/" + data);
        },
        encode(string) {
            return btoa(string);
        },
        subtractMonths(date, months) {
            // 👇 Make copy with "Date" constructor
            const dateCopy = new Date(date);

            dateCopy.setMonth(dateCopy.getMonth() - months);

            return dateCopy;
        },
        setColumns() {
            if (env.isAmbientaly) {
                this.columns.push(
                    {
                        label: "Laudo",
                        type: "button",
                        callbackFunction: "report",
                        class: "btn btn-info btn-sm",
                        condition: function (row) {
                            var filial = row.F2_FILIAL.split('-')[0].trim()
                            if (['0650004','0650001','0650002','0650009','0650008'].indexOf(filial) > -1) {
                                if (row.DAI_NFISCA.trim() == "") {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        },
                        conditionShow: function (row) {
                            var filial = row.F2_FILIAL.split('-')[0].trim()
                            if (['0650004','0650001','0650002','0650009','0650008'].indexOf(filial) > -1) {
                                if (row.DAI_NFISCA.trim() == "") {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    }
                )
            }
            this.columns.push(
                {
                    labelCallback: function (row) {
                        var placa = row.DAK_VEIC2;
                        var placaCavalo = row.DA3_PLACA;
                        var transp = row.A4_NOME;
                        var filial = row.F2_FILIAL.trim();

                        var devolucao = row.D2_QTDEDEV;

                        if (devolucao > 0) {
                            return "NF DEVOLVIDA";
                        }

                        // var dtEntrega = row.C5_ENTREAL;
                        // if (dtEntrega == "  /  /  ") {
                        //   return "Rastreamento Indisponível";
                        // }

                        var prevEntrega = row.C6_ENTREG.split("/");

                        var ano_p = prevEntrega[2];
                        var mes_p = prevEntrega[1];
                        var dia_p = prevEntrega[0];

                        if (ano_p.length == 2) {
                            ano_p = "20" + ano_p;
                        }

                        prevEntrega = new Date(
                            ano_p + "-" + mes_p + "-" + dia_p + " 03:00:00"
                        );

                        var dtEntrega = row.C5_ENTREAL.split("/");

                        var ano = dtEntrega[2];
                        var mes = dtEntrega[1];
                        var dia = dtEntrega[0];

                        if (ano.length == 2) {
                            ano = "20" + ano;
                        }

                        dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

                        var dtEmissao = row.F2_EMISSAO.split("/");

                        var ano_e = dtEmissao[2];
                        var mes_e = dtEmissao[1];
                        var dia_e = dtEmissao[0];

                        if (ano_e.length == 2) {
                            ano_e = "20" + ano_e;
                        }

                        dtEmissao = new Date(
                            ano_e + "-" + mes_e + "-" + dia_e + " 03:00:00"
                        );

                        dia = parseInt(dia);

                        var today = new Date();

                        var yesterday = new Date(today);
                        yesterday.setDate(today.getDate() - 1);

                        var six_months = new Date(today);
                        six_months.setMonth(six_months.getMonth() - 6);
                        // today = today.getDate();

                        if (env.isAmbientaly) {
                            var transportadoras = [
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                                "AMBIENTALY IND E COM DE PROD QUIMICOS LT"
                            ];
                        } else {
                            /* elton 02/08/23 tirei essas da lista da bauminas
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                              */
                            var transportadoras = [
                                "BAUMINAS LOG E TRANSPORTE LTDA.",
                                "BAUMINAS LOG E TRANSPORTE LTDA",
                                "BAUMINAS LOG E TRANSPORTES LTDA",
                                "BAUMINAS LOG E TRANSPORTE S/A",
                                "BAUMINAS LOG E TRANSPORTES S/A",
                            ];
                        }

                        return "Entrega OK / Gerar Canhoto";
                    },
                    type: "buttonLabel",
                    callbackFunction: "tracking",
                    class: "btn btn-info btn-sm",
                    condition: function (row) {

                        // ajuste em 21/11/23
                        // antes só habilitava para transportadoras Bauminas e Ambientaly
                        return false;

                        var placa = row.DAK_VEIC2;
                        var placaCavalo = row.DA3_PLACA;
                        var transp = row.A4_NOME;

                        var devolucao = row.D2_QTDEDEV;

                        if (devolucao > 0) {
                            return "NF DEVOLVIDA";
                        }


                        var dtEntrega = row.C5_ENTREAL.split("/");

                        var ano = dtEntrega[2];
                        var mes = dtEntrega[1];
                        var dia = dtEntrega[0];

                        if (ano.length == 2) {
                            ano = "20" + ano;
                        }

                        dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

                        var dtEmissao = row.F2_EMISSAO.split("/");

                        var ano_e = dtEmissao[2];
                        var mes_e = dtEmissao[1];
                        var dia_e = dtEmissao[0];

                        if (ano_e.length == 2) {
                            ano_e = "20" + ano_e;
                        }

                        dtEmissao = new Date(
                            ano_e + "-" + mes_e + "-" + dia_e + " 03:00:00"
                        );

                        var today = new Date();

                        var six_months = new Date(today);
                        six_months.setMonth(six_months.getMonth() - 6);

                        if (dtEmissao < six_months) {
                            return true;
                        }

                        if (env.isAmbientaly) {
                            var transportadoras = [
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                                "AMBIENTALY IND E COM DE PROD QUIMICOS LT"
                            ];
                        } else {
                            var transportadoras = [
                                "BAUMINAS LOG E TRANSPORTE LTDA",
                                "BAUMINAS LOG E TRANSPORTE LTDA.",
                                "BAUMINAS LOG E TRANSPORTES LTDA",
                                "BAUMINAS LOG E TRANSPORTE S/A",
                                "BAUMINAS LOG E TRANSPORTES S/A",
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                            ];
                        }

                        if (env.isAmbientaly) {
                            return true;
                        }

                        if (dtEntrega != "" && dtEntrega < today) {
                            return true;
                        }

                        // if (transportadoras.includes(transp.trim())) {
                        //     return false;

                        // } else {
                        //     return true;
                        // }
                    },

                    conditionShow: function (row) {
                        return true;
                    },
                },
                {
                    label: "Pedido do Cliente",
                    field: "C6_NUMPCOM",
                },
                {
                    label: "Nota Fiscal",
                    field: "DAI_NFISCA",
                    type: "buttonLabel",
                    callbackFunction: "danfe",
                    class: "btn btn-info btn-sm",
                    labelCallback: function (row) {
                        return row.DAI_NFISCA;
                    },
                    condition: function (row) {
                        if (row) {
                            return row.DAI_NFISCA.trim() ? false : true;
                        }
                        return false;
                    },
                    conditionShow: function (row) {
                        if (row) {
                            return row.DAI_NFISCA.trim() == "" ? false : true;
                        }
                        return true;
                    },
                },
                {
                    label: "Local de Entrega",
                    callback: function (row) {
                        var field = row.A1_ZAPLENT;
                        if (field.trim() == "") {
                            return "";
                        }
                        return field;
                    },
                },

                {
                    label: "Nome do Produto",
                    callback: function (row) {
                        var field = row.B1_ESPECIE;
                        if (field.trim() == "") {
                            return "N/A";
                        }
                        return field;
                    },
                },
                {
                    label: "Quantidade",
                    callback: function (row) {
                        var C6_XUNFAT = row.C6_XUNFAT;

                        var D2_QTSEGUM = row.D2_QTSEGUM;
                        var C6_QTDVEN = row.C6_QTDVEN;
                        var D2_QUANT = row.D2_QUANT;

                        if (C6_XUNFAT.trim() != "") {
                            return new Intl.NumberFormat("pt-BR", {
                                style: "decimal",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(D2_QTSEGUM);
                        } else {
                            return new Intl.NumberFormat("pt-BR", {
                                style: "decimal",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(D2_QUANT);
                        }
                    },
                    // callback: function (row) {
                    //   row.C6_QTDVEN = row.C6_QTDVEN.split(".").join("");
                    //   return row.C6_QTDVEN.toString().replace(
                    //     /\B(?=(\d{3})+(?!\d))/g,
                    //     "."
                    //   );
                    // },
                },
                // {
                //   label: "Quantidade novo",
                //   callback: function (row) {
                //     row.D2_QUANT = row.D2_QUANT.split(".").join("");
                //     return row.D2_QUANT.toString().replace(
                //       /\B(?=(\d{3})+(?!\d))/g,
                //       "."
                //     );
                //   },
                // },
                {
                    label: "Unidade de Medida",
                    callback: function (row) {
                        var C6_XUNFAT = row.C6_XUNFAT;

                        var D2_SEGUM = row.D2_SEGUM;
                        var C6_UM = row.C6_UM;

                        if (C6_XUNFAT.trim() != "") {
                            if (D2_SEGUM.trim() == "") {
                                return "N/A";
                            }
                            return D2_SEGUM;
                        } else {
                            if (C6_UM.trim() == "") {
                                return "N/A";
                            }
                            return C6_UM;
                        }
                    },
                },
                {
                    label: "Previsão de Entrega",
                    field: "C6_ENTREG",
                },
                {
                    label: "Data de Entrega",

                    field: "C5_ENTREAL",
                },
                {
                    label: "Data Faturamento",
                    field: "F2_EMISSAO",
                },
                {
                    label: "Valor da nota",
                    callback: function (row) {
                        return new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                        }).format(row.D2_TOTAL);
                    },
                },
                {
                    label: "Ticket",
                    field: "C5_ZTICKET",
                },
                {
                    label: "Lacre",
                    field: "C5_ZLACRE",
                },
                {
                    label: "Nome do motorista",
                    callback: function (row) {
                        var field = row.DA4_NOME;
                        if (field.trim() == "") {
                            return "N/A";
                        }
                        return field;
                    },
                },
                {
                    label: "Transportadora",
                    field: "A4_NOME",
                },
                {
                    label: "Placa do Veículo",
                    callback: function (row) {
                        var field = row.DA3_PLACA;
                        if (field.trim() == "") {
                            return "N/A";
                        }
                        return field;
                    },
                },
                {
                    label: "Placa da Carreta",
                    callback: function (row) {
                        var field = row.DAK_VEIC2;
                        if (field.trim() == "") {
                            return "N/A";
                        }
                        return field;
                    },
                },
                {
                    label: "Filial",
                    callback: function (row) {
                        if (row) {
                            var string = row.F2_FILIAL;
                            string = string.substring(string.indexOf(" - ") + 2);
                            return string;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    label: "N° do Pedido",
                    field: "DAI_PEDIDO",
                },
                //AQUI

                // {
                //   label: "Peso bruto",
                //   callback: function (row) {
                //     row.F2_PBRUTO = row.F2_PBRUTO.split(".").join("");
                //     return row.F2_PBRUTO.toString().replace(
                //       /\B(?=(\d{3})+(?!\d))/g,
                //       ","
                //     );
                //   },
                // },
                {
                    label: "Nº da Negociação",
                    callback: function (row) {
                        var field = row.C5_MDCONTR;
                        if (field.trim() == "") {
                            return "N/A";
                        }
                        return field;
                    },
                },
                {
                    label: "Contrato do Cliente",
                    callback: function (row) {
                        var field = row.CN9_ZCONCL;
                        if (field.trim() == "") {
                            return "N/A";
                        }
                        return field;
                    },
                },
                // {
                //   label: "Peso líquido",
                //   callback: function (row) {
                //     row.F2_PLIQUI = row.F2_PLIQUI.split(".").join("");
                //     return row.F2_PLIQUI.toString().replace(
                //       /\B(?=(\d{3})+(?!\d))/g,
                //       ","
                //     );
                //   },
                // },
            )
        }
    },
    mounted() {
        const self = this;
        this.setColumns();
    },
};
</script>