<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Cliente</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="customer.name"
                  />
                </div>
              </div>
              <div class="col-6">
                <Toggle
                  class="toggleStyle"
                  v-model="customer.exibir_boleto"
                  v-bind="simNao"
                />
                <label class="ml-3">Exibir coluna de boleto?</label>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12">
                <button class="btn btn-primary float-right" @click="save">
                  Salvar
                </button>
                <router-link
                  :to="'/admin/user-list'"
                  class="btn btn-danger float-right mr-3"
                  >Voltar</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@vueform/toggle";
export default {
  data() {
    return {
      customers: [],
      customer: {
        name: "",
        boleto: false,
      },
      simNao: {
        onLabel: "SIM",
        offLabel: "NÃO",
      },
    };
  },
  components: {
    Toggle,
  },
  methods: {
    get_data(id) {
      const self = this;

      const api = this.$store.state.api + "customers/" + id;

      axios
        .get(api)
        .then((response) => {
          self.customer = response.data.data[0];
          self.customer.exibir_boleto =
            self.customer.exibir_boleto == 1 ? true : false;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
    save() {
      const self = this;
      let api = self.$store.state.api + "customers/" + self.$route.params.id;

      self.customer._method = "PUT";

      axios
        .post(api, self.customer)
        .then((response) => {
          this.$toast.success("Registro salvo com sucesso");
          self.$router.push("/admin/customer-list");
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
        });
    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id) {
      this.get_data(id);
    }
  },
};
</script>
<style scoped>
.toggleStyle {
  --toggle-width: 4rem !important;
  --toggle-height: 1.45rem !important;
  --toggle-font-size: 0.85rem !important;
  font-weight: bold;
}
</style>
