<template>
    <div class="bg-maintenance">

        <div class="login-container">
            <div class="text-center">
                <img class="banner-img mt-4" src="@/assets/images/em_manutencao.png" alt />
                <br />
                <br />
                <p class="maintenance-msg">{{ config.msg_maintenance }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            config: {},
        };
    },
    computed: {},
    methods: {
        getConfig() {
            const self = this;
            const api = this.$store.state.api + "configurations";

            axios
                .get(api)
                .then((response) => {
                    self.config = response.data.data[0];
                })
                .catch((error) => {
                    this.$toast.error("Credenciais Inválidas");
                });
        },
    },
    mounted() {
        this.getConfig();
    },
};
</script>
<style scoped>
.maintenance-msg {
    font-size: 16px !important;
}

.login-container {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.banner-img {
    width: 100%;
}

.bg-maintenance {
    /* background: rgba(0, 0, 0, 0) url("@/assets/images/bg-login.jpg") no-repeat scroll; */
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 100%;
    height: 920px;
    overflow-y: hidden;
    background-position: 30% 45%;
}


@media (min-width: 1024px) {
    .banner-img {
        width: 60%;
    }
}

@media (min-width: 1440px) {
    .banner-img {
        width: 48%;
    }
}

@media (min-width: 2560px) {

    .maintenance-msg {
        font-size: 27px !important;
    }


    .banner-img {
        width: 60%;
    }

    .login-container {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .bg-maintenance {
        height: 1600px;
    }
}
</style>