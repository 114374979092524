<template>
  
  <div class="row mb-2">
    <div class="col-11 col-md-3">
      <b> Nº da Negociação: </b><br />
      {{ contract.CN9_NUMERO }}
    </div>
    <div class="col-11 col-md-3">
      <b> Contrato do cliente: </b><br />
      {{ contract.CN9_ZCONCL }}
    </div>
    <div class="col-11 col-md-3">
      <b> Filial: </b><br />
      {{ contract.CN9_FILIAL.substring(9) }}
    </div>
    <div class="col-11 col-md-3">
      <b> Descrição: </b><br />
      {{ contract.CNB_DESCRI }}
    </div>
  </div>
  <div class="row mb-2 mt-3">
    <div class="col-11 col-md-3">
      <b> % de prazo: </b><br />
      {{ calcule_date_percentage(contract.CN9_DTINIC, contract.CN9_DTFIM) }}%
    </div>
    <div class="col-11 col-md-3">
      <b> Data inicial: </b><br />
      {{ contract.CN9_DTINIC }}
    </div>
    <div class="col-11 col-md-3">
      <b> Data final: </b><br />
      {{ contract.CN9_DTFIM }}
    </div>
    <div class="col-11 col-md-3">
      <b> Valor unitário: </b><br />
      R$ {{ contract.CNB_VLUNIT }} KG
    </div>
  </div>

  <div class="row mb-2 mt-3">
    <div class="col-11 col-md-3">
      <b> Prazo em dias: </b><br />
      {{ diff_in_days_between(contract.CN9_DTFIM, contract.CN9_DTINIC) }}
    </div>
    <div
      :class="[
        'col-11 col-md-3',
        {
          flashing_red:
            diff_in_days(contract.CN9_DTFIM) <= 60 &&
            !['01', '06', '08'].includes(contract.CN9_SITUAC),
        },
      ]"
    >
      <b> Nº de dias para vencimento: </b><br />
      {{ diff_in_days(contract.CN9_DTFIM) }}
    </div>
    <div class="col-11 col-md-3">
      <b> Volume inicial: </b><br />
      {{ brNumber(contract.CNB_QUANT) }} {{ contract.CNB_UM }}
    </div>
    <div class="col-11 col-md-3">
      <b> Volume consumido: </b><br />
      {{ brNumber(contract.CNB_QTDMED) }} {{ contract.CNB_UM }}
    </div>
    <div class="col-11 col-md-3 mt-3">
      <b> Saldo de volume: </b><br />
      {{ brNumber(contract.CNB_SLDMED) }} {{ contract.CNB_UM }}
    </div>
    <div class="col-11 col-md-3 mt-3">
      <b> % consumido: </b><br />
      {{ calcule_value_percentage(contract.CNB_QTDMED, contract.CNB_QUANT) }}%
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["contract"],

  methods: {
    brNumber(number) {
      return new Intl.NumberFormat("pt-BR", {}).format(number);
    },
    diff_in_days_between(date_end, date_start) {
      return moment(date_end, "DD/MM/YY").diff(
        moment(date_start, "DD/MM/YY").format("YYYY-MM-DD"),
        "days"
      );
    },
    diff_in_days(date) {
      let diff_in_days_total = moment(date, "DD/MM/YY").diff(
        moment().format("YYYY-MM-DD"),
        "days"
      );
      return Math.floor(diff_in_days_total);
    },
    calcule_value_percentage(current, total) {
      let percentage = (current * 100) / total;
      let percentage_fixed = percentage.toFixed(2);

      return percentage_fixed;
    },
    calcule_date_percentage(start_date, end_date) {
      let start_date_moment = moment(start_date, "DD/MM/YY");
      let end_date_moment = moment(end_date, "DD/MM/YY");

      let total_days = end_date_moment.diff(start_date_moment, "days");

      let completed_days = moment().diff(start_date_moment, "days");

      let completed_days_percentage = (completed_days * 100) / total_days;
      completed_days_percentage = completed_days_percentage.toFixed(2);

      let return_percentage =
        completed_days_percentage > 100 ? 100 : completed_days_percentage;

      return return_percentage;
    },
  },
};
</script>