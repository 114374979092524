<template>
    <div class="container-fluid">
        <div class="card ">
            <!-- Filters -->

            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        <h3 class="text-center">Títulos Pagos, A Vencer e Vencidos</h3>
                    </div>
                    <div class="col-4">
                        <label for="">De</label>
                        <input type="date" class="form-control" v-model="dateInicial"/>
                    </div>
                    <div class="col-4">
                        <label for="">Até</label>
                        <input type="date" class="form-control" v-model="dateFinal"/>
                    </div>
                    <div class="col-4" style="margin-top: 29px">
                        <button class="btn btn-primary" @click="getChartData">
                            Buscar
                        </button>
                    </div>
                    <div class="row mt-4" v-if="!loading">
                        <div class="col-md-4">
                            <div class="widget-stat card">
                                <div class="card-body p-4">
                                    <div class="media ai-icon">
                    <span class="mr-3 bgl-success text-success">
                      <img src="/src/assets/images/icons/financeiro.png" width="35"/>
                    </span>
                                        <div class="media-body">
                                            <p class="mb-1">Valor Total Pago</p>
                                            <h6 class="mb-0">{{ counters.pagosTotal }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="widget-stat card">
                                <div class="card-body p-4">
                                    <div class="media ai-icon">
                    <span class="mr-3 bgl-info text-info">
                      <img src="/src/assets/images/icons/financeiro.png" width="35"/>
                    </span>
                                        <div class="media-body">
                                            <p class="mb-1">Valor Total A Vencer</p>
                                            <h6 class="mb-0">{{ counters.emDiaTotal }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="widget-stat card">
                                <div class="card-body p-4">
                                    <div class="media ai-icon">
                    <span class="mr-3 bgl-danger text-danger">
                      <img src="/src/assets/images/icons/financeiro.png" width="35"/>
                    </span>
                                        <div class="media-body">
                                            <p class="mb-1">Valor Total Atrasado</p>
                                            <h6 class="mb-0">{{ counters.atrasadosTotal }}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row " v-if="!loading">
                        <div class="col-md-12 centered">
                            <div class="alert alert-primary" role="alert">
                                Você pode ocultar barras do gráfico clicando na legenda abaixo.
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center mt-3" v-if="!loading">
                        <div class="col-md-2 clickable" @click="ocultarPagos">
                            <div class="retangulo" :style="'background-color: #27AE60'">
                                <span :class="bar_data.data.datasets[0].hidden ? 'tachar' : ''">Pagos</span>
                            </div>
                        </div>
                        <div class="col-md-2 clickable" @click="ocultarAVencer">
                            <div class="retangulo" :style="'background-color: #305084'">
                                <span :class="bar_data.data.datasets[1].hidden ? 'tachar' : ''">A Vencer</span>
                            </div>
                        </div>
                        <div class="col-md-2 clickable ml-3" @click="ocultarAtrasado">
                            <div class="retangulo" :style="'background-color: #C70039'">
                                <span :class="bar_data.data.datasets[2].hidden ? 'tachar' : ''">Atrasado</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3" v-if="!loading" style="max-height: 350px;display: inline;">
                        <vue3-chart-js :id="'id'" ref="chartRef" :type="'bar'" :data="bar_data.data"
                                       :options="bar_data.options"
                                       :height="550">
                        </vue3-chart-js>

                    </div>
                    <div class="col-12" v-if="loading">
                        <div class="card-body text-center">
                            <span class="fa fa-spinner fa-spin fa-5x"></span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bar from "./Charts/Financial/Bar.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";


export default {
    components: {
        Bar,
        Vue3ChartJs,
    },
    data() {

        return {
            counters: {},
            is_first_click: 1,
            loading: false,
            dateInicial: new Date().toISOString().substr(0, 10),
            dateFinal: new Date().toISOString().substr(0, 10),
            bar_data: {
                data: {
                    labels: [],
                    datasets: [
                        // {
                        //     label: 'Todos',
                        //     backgroundColor: ["#2a4a80"],
                        //     data: [],
                        // },
                        {
                            label: "Pagos",
                            backgroundColor: ["#27AE60"],
                            data: [],
                            datalabels: {
                                color: "yellow",
                            },
                        },
                        {
                            label: "A Vencer",
                            backgroundColor: ["#2a4a80"],
                            data: [],
                            datalabels: {
                                color: "blue",
                            },
                        },
                        {
                            label: "Atrasado",
                            backgroundColor: ["#C70039"],
                            data: [],
                            datalabels: {
                                align: "end",
                                color: "black",
                                labels: {
                                    title: {
                                        font: {
                                            weight: "bold",
                                        },
                                    },
                                },
                            },
                        },
                    ],
                },

                options: {

                    maintainAspectRatio: false,
                    interaction: {
                        mode: 'point'
                    },
                    plugins: {

                        legend: {
                            display: false,
                        },
                        // labels: {
                        //   padding: 60,
                        // },
                        // title: {
                        //   padding: 70,
                        // },
                        tooltip: {
                            margin: 10,
                            padding: 10,
                            position: 'average',
                            intersect: true,
                            callbacks: {
                                label: function (context) {
                                    return (
                                        context.dataset.label + ": R$ " + context.formattedValue
                                    );
                                },
                            },
                        },

                    },
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true,
                        },
                        y: {
                            stacked: true,
                            // beginAtZero: true,
                            // ticks: {
                            //   callback: (label) =>
                            //     ` ${label.toLocaleString("pt-br", {
                            //       style: "currency",
                            //       currency: "BRL",
                            //     })}`,
                            // },
                        },
                    },
                },
            },
        };
    },
    methods: {
        ocultarPagos() {
            this.firstClick();
            this.bar_data.data.datasets[0].hidden =
                !this.bar_data.data.datasets[0].hidden;
            this.$refs.chartRef.update();
        },
        ocultarAVencer() {
            this.firstClick();
            this.bar_data.data.datasets[1].hidden =
                !this.bar_data.data.datasets[1].hidden;
            this.$refs.chartRef.update();
        },
        ocultarAtrasado() {
            this.firstClick();
            this.bar_data.data.datasets[2].hidden =
                !this.bar_data.data.datasets[2].hidden;
            this.$refs.chartRef.update();
        },
        firstClick() {
            if (this.is_first_click == 1) {
                this.bar_data.data.datasets[0].hidden = true;
                this.bar_data.data.datasets[1].hidden = true;
                this.bar_data.data.datasets[2].hidden = true;
            }

            this.is_first_click++;

            if (this.is_first_click > 3) {
                this.is_first_click = 1;
            }
        },
        getChartData() {
            let self = this;
            self.loading = true;

            const api = self.$store.state.api + "invoices/dashboard?";
            axios
                .get(api, {
                    params: {
                        initialDate: self.dateInicial,
                        finalDate: self.dateFinal,
                    },
                })
                .then((response) => {
                    self.counters.emDiaTotal = response.data.emDiaTotal;
                    self.counters.atrasadosTotal = response.data.atrasadosTotal;
                    self.counters.pagosTotal = response.data.pagosTotal;
                    self.bar_data.data.labels = response.data.labels;
                    self.bar_data.data.datasets[0].data = response.data.pagosList;
                    self.bar_data.data.datasets[0].datalabels = {
                        // color: "#0d0d0d",
                        anchor: 'false',
                        clamp: true,
                        borderColor: 'white',
                        color: '#353536',
                        // display: 'auto',
                        font: {
                            // weight: "bold",
                            size: 13
                        },
                        formatter: function (value, context) {
                            if (value != 0) {

                                return (
                                    parseFloat(value).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })
                                );
                            } else {
                                return "";
                            }
                        },
                    };
                    self.bar_data.data.datasets[1].data = response.data.emDiaLista;
                    self.bar_data.data.datasets[1].datalabels = {
                        color: "#0d0d0d",
                        anchor: 'false',
                        clamp: true,
                        // display: 'auto',
                        font: {
                            // weight: "bold",
                            size: 13
                        },
                        formatter: function (value, context) {
                            if (value != 0) {
                                return (
                                    parseFloat(value).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })
                                );
                            } else {
                                return "";
                            }
                        },
                    };
                    self.bar_data.data.datasets[2].data = response.data.atrasadosLista;
                    self.bar_data.data.datasets[2].datalabels = {
                        color: "#0d0d0d",
                        anchor: 'false',
                        clamp: true,
                        // display: 'auto',
                        font: {
                            // weight: "bold",
                            size: 13,
                        },
                        formatter: function (value, context) {
                            if (value != 0) {
                                return (
                                    parseFloat(value).toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                    })
                                );
                            } else {
                                return "";
                            }
                        },
                    };
                })
                .then(() => {
                    self.loading = false;
                });
        },
    },
    mounted() {
        this.dateFinal = new Date();
        this.dateFinal.setDate(this.dateFinal.getDate() + 365);
        this.dateFinal = this.dateFinal.toISOString().substr(0, 10);

        this.dateInicial = new Date();
        this.dateInicial.setDate(this.dateInicial.getDate() - 365);
        this.dateInicial = this.dateInicial.toISOString().substr(0, 10);

        this.getChartData();
    },
};
</script>
<style scoped>
.retangulo {
    display: inline-flex;
    align-items: center;
    width: 40px;
    height: 15px;
    font-size: 16px;
}

.retangulo span {
    margin-left: 48px;
    /* Espaçamento entre o retângulo e o texto */
    white-space: nowrap;
}

.tachar {
    text-decoration: line-through;
}
</style>
