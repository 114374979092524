<template>
    <div class="container-fluid">
        <div class="row" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Meu Perfil</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 centered mb-5">
                                <div class="image-bx">
                                    <img :src="user.photo" alt="" />
                                    <label style="cursor: pointer" for="file" @click="$('input[name=file]').click()"><i
                                            class="fa fa-retweet" aria-hidden="true"></i></label>
                                </div>
                                <input type="file" name="file" id="file" style="display: none"
                                    accept="image/png, image/jpeg" />
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Nome</label>
                                    <input type="text" class="form-control" v-model="user.name" />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>E-mail</label>
                                    <input type="text" class="form-control" v-model="user.email"
                                        autocomplete="new-password" disabled />
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label>Nível de Acesso</label>
                                    <select class="form-select" v-model="user.type_id" disabled>
                                        <option value="4">Administrador Master</option>
                                        <option value="1">Administrador</option>
                                        <option value="2">Cliente</option>
                                        <option value="3">Usuário Interno</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label>Cliente</label>

                                    <v-select v-model="selectedCustomer" @search="searchCustomers" :filterable="false"
                                        label="name" :options="customers" :disabled="
                      $store.getters.user.type_id == 1 ||
                      $store.getters.user.type_id == 4 ||
                      $store.getters.user.type_id == 3
                        ? false
                        : true
                    " @change="getStores">
                                        <template #no-options="{ search, searching, loading }">
                                            Digite para pesquisar clientes
                                        </template>

                                        <template #option="{ name }">
                                            <div class="d-center">
                                                {{ name }}
                                            </div>
                                        </template>
                                        <template #selected-option="{ name }">
                                            <div class="selected d-center">
                                                {{ name }}
                                            </div>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Unidades</label>

                                    <v-select class="form-select" v-model="user.stores" multiple :filterable="false"
                                        label="store_id" :options="stores" :disabled="
                      $store.getters.user.type_id == 1 ||
                      $store.getters.user.type_id == 4 ||
                      $store.getters.user.type_id == 3
                        ? false
                        : true
                    ">
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="alert alert-primary" role="alert">
                                    <strong>Atenção!</strong> Ao clicar no botão
                                    <strong>Gerar Nova Senha </strong>, será enviado um link para
                                    alteração de senha no e-mail cadastrado aqui.
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-primary" @click="sendNewPassword">
                                    Gerar Nova Senha
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-primary float-right" @click="save">
                                    Salvar
                                </button>
                                <router-link :to="'/admin'" class="btn btn-danger float-right mr-3">Voltar
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" v-if="loading">
            <div class="card-body text-center">
                Atualizando informações do cliente...
                <span class="fa fa-spinner fa-spin fa-5x"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import vSelect from "vue-select";
    import _ from "lodash";

    export default {
        components: {
            vSelect,
        },
        data() {
            return {
                loading: false,
                customers: [],
                stores: [],
                selectedCustomer: {},
                user: {
                    name: "",
                    email: "",
                    password: "",
                    customer_id: null,
                    stores: [],
                },
            };
        },
        computed: {
            customerList() {
                return this.customers;
            },
        },
        methods: {
            sendNewPassword() {
                let self = this;
                const api = self.$store.state.api + "user/new-password";

                axios
                    .post(api, { id: self.user.id })
                    .then((response) => {
                        this.$toast.success(
                            "OK! Uma nova senha foi enviada para o e-mail: " + self.user.email
                        );
                    })
                    .catch((error) => {
                        // vm.$toast.error(error.response.data.message);
                    });
            },
            searchCustomers(search, loading) {
                if (search.length) {
                    loading(true);
                    this.search(loading, search, this);
                }
            },
            search: _.debounce((loading, search, vm) => {
                const api = vm.$store.state.api + "customers?name=" + search;

                axios
                    .get(api)
                    .then((response) => {
                        vm.customers = response.data.data;
                    })
                    .then(() => {
                        loading(false);
                    })
                    .catch((error) => {
                        vm.$toast.error(error.response.data.message);
                    });
            }, 350),
            get_data(id) {
                const self = this;

                const api = this.$store.state.api + "me/";

                axios
                    .post(api)
                    .then((response) => {
                        self.user = response.data.user;
                    })
                    .then(() => {
                        self.getSelectedCustomer();
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.message);
                        self.loading = false;
                    });
            },
            getStores() {
                let self = this;
                const api =
                    self.$store.state.api +
                    "customer_stores?paginated=false&orderByAsc=store_id&customer_id=" +
                    self.selectedCustomer.code;

                axios
                    .get(api)
                    .then((response) => {
                        self.stores = response.data.data;
                    })
                    .catch((error) => {
                        // vm.$toast.error(error.response.data.message);
                    });
            },
            save() {
                const self = this;
                let api = self.$store.state.api + "users/";

                this.loading = true

                if (self.user.id) {
                    api += self.user.id;
                }

                let photo = document.getElementById("file");

                const fd = new FormData();
                fd.append("name", self.user.name);
                fd.append("customer_id", self.selectedCustomer.id);
                fd.append("type_id", self.user.type_id);
                fd.append("email", self.user.email);
                fd.append("access", self.user.access);
                fd.append("photo", photo.files[0] ? photo.files[0] : "");
                if (self.user.password) {
                    fd.append("password", self.user.password);
                }
                if (self.user.password_confirm) {
                    fd.append("password_confirmation", self.user.password_confirm);
                }
                if (self.user.id) {
                    fd.append("id", self.user.id);
                    fd.append("_method", "PUT");
                }

                if (
                    self.$store.getters.user.type_id == 1 ||
                    self.$store.getters.user.type_id == 4 ||
                    self.$store.getters.user.type_id == 3
                ) {
                    if (self.user.stores.length > 0) {
                        self.user.stores.forEach((selecteds) => {
                            fd.append("stores[]", selecteds.id);
                        });
                    }

                    fd.append("customer_id", self.selectedCustomer.id);
                }

                axios
                    .post(api, fd)
                    .then((response) => {
                        self.loading = false
                        self.$store.dispatch("initialize");
                        self.$toast.success("Registro salvo com sucesso");
                        self.$router.push("/admin");
                    })
                    .catch((error) => {
                        self.loading = false
                        if (error.response.data.message) {
                            self.$toast.error(error.response.data.message);
                        } else if (typeof error.response.data === "object") {
                            for (let item in error.response.data) {
                                self.$toast.error(error.response.data[item][0]);
                            }
                        }
                    });
            },
            getSelectedCustomer() {
                let self = this;
                const api =
                    self.$store.state.api + "customers?id=" + this.user.customer_id;

                axios
                    .get(api)
                    .then((response) => {
                        self.selectedCustomer = response.data.data[0];
                        self.getStores();
                        self.getSelectedStoreCustomer();
                    })
                    .catch((error) => {
                        // vm.$toast.error(error.response.data.message);
                    });
            },
            getSelectedStoreCustomer() {
                let self = this;
                const api =
                    self.$store.state.api +
                    "customer_user_stores?with[]=customer_store&user_id=" +
                    this.user.id;

                self.user.stores = [];

                axios
                    .get(api)
                    .then((response) => {
                        response.data.data.forEach((element) => {
                            self.user.stores.push(element.customer_store);
                        });
                    })
                    .catch((error) => {
                        // vm.$toast.error(error.response.data.message);
                    });
            },
        },
        mounted() {
            this.get_data();
        },
    };
</script>
<style scoped>
    .centered {
        text-align: center;
    }

    .image-bx {
        position: relative;
        display: inline-block;
        height: 165px;
        width: 165px;
        margin-bottom: 12px;
    }

    .image-bx img {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .image-bx label {
        color: var(--primary);
        position: absolute;
        top: -10px;
        right: -8px;
        font-size: 20px;
    }
</style>