<template>
    <div class="container-fluid">
        <button @click="$router.back()" class="btn btn-primary mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'arrow-left']" />
            Voltar
        </button>
        <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'retweet']" />
            Atualizar
        </button>
        <div class="card-body text-center" v-if="loading">
            <span class="fa fa-spinner fa-spin fa-5x"></span>
            <div class="row" v-if="show_retry_button"></div>
        </div>
        <div v-if="!loading">
            <div class="detail-container">
                <div class="row">
                    <div class="col-md-12 position-relative">
                        <iframe :src="'data:application/pdf;base64,' + danfe.base64" alt="" width="100%" height="750px"
                            id="scaled-frame" />
                        <button @click="downloadPDF" class="btn btn-secondary download-btn">
                            <font-awesome-icon :icon="['fa', 'download']" />
                            Baixar PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            map: "",
            loaded: false,
            danfe: {},
            loading: true,
        };
    },
    computed: {},
    methods: {
        getDetail() {
            const self = this;
            self.loaded = false;
            let data = self.$route.params.params;

            self.loading = true;

            var api = self.$store.getters.api + "invoice/danfe";

            axios
                .post(api, { info: data })
                .then((response) => {
                    self.danfe = response.data;
                    self.loading = false;
                })
                .catch((error) => {
                    this.$toast.error(
                        "Falha ao processar o PDF da DANFE, favor contatar o Atendimento."
                    );
                });
        },
        downloadPDF() {
            const self = this;
            const link = document.createElement('a');
            link.href = 'data:application/pdf;base64,' + self.danfe.base64;
            link.download = self.danfe.file_name || 'download.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },


    mounted() {
        const self = this;

        self.getDetail();
    },
};
</script>
<style scoped>
.download-btn {
    position: absolute;
    top: 5px;
    right: 25px;
    z-index: 1000;
    background-color: #007bff;
    color: white;
}
</style>