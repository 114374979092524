import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue'

import BasicLayout from '@/components/Layouts/BasicLayout.vue'
import Navbar from '@/components/Layouts/Navbar.vue'

import ContractList from '@/components/Contract/List.vue'
import ContractView from '@/components/Contract/View.vue'
import TimeLogView from '@/components/TimeLog/View.vue'
// import InvoiceList from '@/components/Invoice/List.vue'

const InvoiceList = defineAsyncComponent(() =>
    import('@/components/Invoice/List.vue')
)
import InvoiceListTicket from '@/components/Invoice/Ticket.vue'

import PackagingList from '@/components/Invoice/PackagingList.vue'

import Login from '@/components/Login.vue'
import Maintenance from '@/components/Maintenance.vue'
import TermsOfUse from "@/components/TermsOfUse.vue";
import FinancialDashboard from '@/components/FinancialDashboard.vue'
import DeliveryList from '@/components/Delivery/List.vue'
import DeliveryDeliveredList from '@/components/Delivery/ListDelivered.vue'
import Tracking from '@/components/Delivery/Tracking.vue'
import DANFE from '@/components/Invoice/Danfe.vue'
import Canhoto from '@/components/Delivery/Canhoto.vue'
import OrderList from '@/components/Order/List.vue'
import OrderListFinished from '@/components/Order/ListFinished.vue'
import OrderUnbilledList from '@/components/Order/ListUnbilled.vue'
import OrderListNotIntegrated from '@/components/Order/ListNotIntegrated.vue'
import OrderDraft from '@/components/Order/ListDraft.vue'
import OrderNew from '@/components/Order/New.vue'
import OrderView from '@/components/Order/View.vue'
import FISPQ from '@/components/Document/FISPQ.vue'
import DocumentsFDS from '@/components/Document/FDS.vue'
import DocumentsFDE from '@/components/Document/FDE.vue'
import WinnerDoc from '@/components/Document/WinnerDoc.vue'
import WinnerView from '@/components/Document/WinnerDocView.vue'
import Report from '@/components/Order/Report.vue'
import MainScreen from '@/components/MainScreen.vue'
import UserList from '@/components/User/List.vue'
import CustomerList from '@/components/Customer/List.vue'
import CustomerDetail from '@/components/Customer/Detail.vue'
import UserDetail from '@/components/User/Detail.vue'
import Profile from '@/components/Profile.vue'
import TimeLog from '@/components/TimeLog/List.vue'
import LicitationForm from '@/components/Licitation/Form.vue'
import LicitationList from '@/components/Licitation/List.vue'
import LicitationView from '@/components/Licitation/View.vue'
import Access from '@/components/Access/List.vue'
import Configs from '@/components/Configs/Index.vue'
import Reports from '@/components/Reports/Index.vue'
import Kanban from '@/components/Kanban/Kanban.vue'
import Register from "@/components/Register.vue";

const routes = [
    {
        path: '/register/:token?',
        name: 'Cadastro',
        component: Register
    },
    {
        path: '/terms_of_use',
        name: 'Politicas de Privacidade e Termos de Uso',
        component: TermsOfUse
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/',
        component: BasicLayout,
        children: [
            
            {
                path: 'in_maintenance',
                name: 'Em Manutenção',
                component: Maintenance
            },
        ]
    },
    {
        path: '/admin',
        component: Navbar,
        children: [
            {
                path: '',
                name: 'Destaques',
                component: MainScreen
            },
            {
                path: 'kanban',
                name: 'Resumo Geral',
                component: Kanban
            },
            {
                path: 'financial-dash',
                name: 'Dashboard Financeiro',
                component: FinancialDashboard
            },
            {
                path: 'packaging-list',
                name: 'Embalagens',
                component: PackagingList
            },
            {
                path: 'contract-list',
                name: 'Lista de Contratos',
                component: ContractList
            },
            {
                path: 'contract/:params',
                name: 'Detalhes do Contrato',
                component: ContractView
            },
            {
                path: 'invoice-list',
                name: 'Financeiro',
                component: InvoiceList
            },
            {
                path: 'invoice-list/ticket/:params',
                name: 'Boletos',
                component: InvoiceListTicket
            },
            {
                path: 'invoice/danfe/:params',
                name: 'Exibição de Danfe',
                component: DANFE
            },
            {
                path: 'delivery/tracking/:params',
                name: 'Mapa de Localização',
                component: Tracking
            },
            {
                path: 'delivery/canhoto/:params',
                name: 'Canhoto de Entrega',
                component: Canhoto
            },
            {
                path: 'delivery-list',
                name: 'Entregas em Andamento',
                component: DeliveryList
            },
            {
                path: 'delivered-list',
                name: 'Entregas Concluídas',
                component: DeliveryDeliveredList
            },
            {
                path: 'order-list',
                name: 'Pedidos em Rota',
                component: OrderList
            },
            {
                path: 'order-unbilled-list',
                name: 'Pedidos a Faturar',
                component: OrderUnbilledList
            },
            {
                path: 'order-finished-list',
                name: 'Pedidos Entregues',
                component: OrderListFinished
            },
            {
                path: 'order-list-ni',
                name: 'Pedidos Realizados',
                component: OrderListNotIntegrated
            },
            {
                path: 'order-draft',
                name: 'Pedidos em Rascunho',
                component: OrderDraft
            },
            {
                path: 'new-order-view/:id?',
                name: 'Visualizar Pedido',
                component: OrderView
            },
            {
                path: 'order-new',
                name: 'Novo Pedido',
                component: OrderNew
            },
            {
                path: 'order-edit/:id?',
                name: 'Editar Pedido',
                component: OrderNew
            },
            {
                path: 'order/report/:params?',
                name: 'Laudo',
                component: Report
            },
            {
                path: 'fds-list',
                name: 'Documentos (FDS)',
                component: DocumentsFDS
            },
            {
                path: 'fde-list',
                name: 'Documentos (Ficha de Emergência)',
                component: DocumentsFDE
            },
            {
                path: 'winner-docs',
                name: 'Certidões',
                component: WinnerDoc
            },
            {
                path: 'winner-docs-view/:id?',
                name: 'Visualizar Certidões',
                component: WinnerView
            },
            {
                path: 'document/fispq/:params?',
                name: 'Visualizar Documento',
                component: FISPQ
            },
            {
                path: 'user-list',
                name: 'Usuários',
                component: UserList
            },
            {
                path: 'user/:id?',
                name: 'Usuário',
                component: UserDetail
            },
            {
                path: 'users/add',
                name: 'Novo Usuário',
                component: UserDetail
            },
            {
                path: 'profile',
                name: 'Meu Perfil',
                component: Profile
            },
            {
                path: 'customer-list',
                name: 'Clientes',
                component: CustomerList
            },
            {
                path: 'customer/:id?',
                name: 'Editar Cliente',
                component: CustomerDetail
            },
            {
                path: 'timelogs',
                name: 'Tempo de Logs',
                component: TimeLog
            },
            {
                path: 'timelogs/:params',
                component: TimeLogView
            },
            {
                path: 'licitation-form',
                name: 'Formulário de Solicitação',
                component: LicitationForm
            },
            {
                path: 'licitation-list',
                name: 'Lista de Solicitações',
                component: LicitationList
            },
            {
                path: 'licitation-view/:params',
                name: 'Visualizar Solicitação',
                component: LicitationView
            },
            {
                path: 'licitation-form',
                name: 'Formulário de Cotação',
                component: LicitationForm
            },
            {
                path: 'licitation-list',
                name: 'Lista de Solicitações',
                component: LicitationList
            },
            {
                path: 'licitation-view/:params',
                name: 'Visualizar Solicitação',
                component: LicitationView
            },
            {
                path: 'logaccess',
                name: 'Tela de Acessos',
                component: Access
            },
            {
                path: 'configs',
                name: 'Configurações',
                component: Configs
            },
            {
                path: 'reports',
                name: 'Relatórios',
                component: Reports
            },
        ]
    },
]

const Router = createRouter({
    history: createWebHistory(),
    routes
})

Router.afterEach((to, from, failure) => {
    close_modal('pending-invoices-modal')
    document.documentElement.scrollTop = 0; // Para navegadores como o Firefox, Edge, etc.
    document.body.scrollTop = 0; // Para nav
})


Router.beforeEach((to) => {
    const token = localStorage.getItem('token')
    const token_expire = localStorage.getItem('token_expire')

    if (token) {
        var m = moment();
        var isafter = m.isAfter(moment(token_expire));

        if (isafter) {
            Router.push('/login')
            localStorage.clear();
            window.location.href = "/";
        }
        if (to.path == '/login' || to.path == '/') {
            Router.push('/admin')
        }
    } else {
        if (to.path != '/login' && !to.path.startsWith('/register/') && to.path != '/register') {
            Router.push('/login')
        }
    }
})

export default Router
