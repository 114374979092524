<template>
    <div class="container-fluid">
        <button @click="$router.go(-1)" class="btn btn-primary mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'arrow-left']"/>
            Voltar
        </button>
        <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'retweet']"/>
            Atualizar
        </button>
        <div>
            <div class="detail-container">
                <div class="row">
                    <div class="col-md-12">
                        <iframe v-if="loaded"
                                width="100%"
                                height="1000"
                                :src="'data:application/pdf;base64, ' + pdfDataUrl + ''"
                        ></iframe>
                        <div class="card-body text-center" v-if="spinner">
                            <span class="fa fa-spinner fa-spin fa-5x"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
            spinner: true,
            data: {},
            pdfDataUrl: ''
        };
    },
    computed: {
        positionTime() {
            return moment(this.map[0].PositionTime).format("DD/MM/YYYY H:m:s");
        },
    },
    methods: {
        getDetail() {

            const formData = this.$store.state.order_report;
            if (Object.keys(formData).length === 0) this.$router.push("/admin/order-list");

            const self = this;
            self.loaded = false;

            var api = self.$store.getters.api + "order-report/";

            console.log(formData);

            axios
                .post(api, formData)
                .then((response) => {
                    this.pdfDataUrl = response.data.laudo;
                    self.loaded = true;
                })
                .catch((error) => {
                    this.$toast.error(
                        "Este laudo não está disponível na plataforma, favor, entre em contato com o Atendimento."
                    );
                })
                .finally(() => {
                        self.spinner = false;
                    }
                )
        },
    },

    mounted() {
        const self = this;

        self.getDetail();
    },
};
</script>