<template>
    <div class="container-fluid">
        <button @click="$router.go(-1)" class="btn btn-primary mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'arrow-left']" />
            Voltar
        </button>
        <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'retweet']" />
            Atualizar
        </button>

        <div>
            <div class="detail-container" v-if="loaded">
                <div class="row">
                    <div class="col-md-12">
                        <iframe width="100%" height="1000"
                            :src="'data:application/pdf;base64,' + encodeURI(data.base64)"></iframe>
                        <button v-if="loaded" @click="downloadPDF" class="btn btn-primary ml-3 mt-3 mb-3 download-btn">
                            <font-awesome-icon :icon="['fa', 'download']" />
                            Baixar PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            loaded: false,
            data: {},
            selectedProductName: '',
        };
    },
    computed: {
        positionTime() {
            return moment(this.map[0].PositionTime).format("DD/MM/YYYY H:m:s");
        },
    },
    methods: {
        getDetail() {
            const self = this;
            self.loaded = false;
            let data = self.$route.params.params;

           

            var api = self.$store.getters.api + "order-fispq/";

            axios
                .post(api, { info: data })
                .then((response) => {
                    self.data = response.data.data;
                    self.loaded = true;
                })
                .catch((error) => {
                    this.$toast.error(
                        "Documento não disponível no momento, favor tente novamente."
                    );
                });
        },
        downloadPDF() {

            let data = this.$route.params.params;
             data = atob(data);
             data = JSON.parse(data);
             console.log(data);
            const blob = this.data.base64 ? this.base64ToBlob(this.data.base64, 'application/pdf') : null;
            if (blob) {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = decodeURIComponent(data.name) +'.pdf'; // Altere o nome conforme necessário
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            } else {
                this.$toast.error('Erro ao criar o arquivo para download.');
            }
        },
        base64ToBlob(base64, contentType) {
            const sliceSize = 512;
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        }
    },

    mounted() {
        const self = this;
        self.getDetail();
    },
};
</script>
<style scoped>
.download-btn {
    position: absolute;
    top: -5px;
    right: 25px;
    z-index: 1000;
    background-color: #007bff;
    color: white;
}
</style>
