<template>
    <div class="pt-3">
        <div class="container mt-5">
            <div class="card p-5">
                <img class="login-img" src="@/assets/images/logo.png" alt/>
                <div class="text-center">
                    <h5 class="mt-4">Solicitação de Acesso</h5>
                </div>
                <div class="alert alert-info" v-if="id != 0">
                    <p>{{ justification }}</p>
                </div>
                <form id="form">
                    <div class="row">
                        <div class="col-md-6">
                            <label class="login-label">Razão Social<span class="text-danger"> *</span></label>
                            <input class="form-control" required type="text" v-model="socialReason"/>
                        </div>
                        <div class="col-md-6">
                            <label class="login-label">CNPJ<span class="text-danger"> *</span></label>
                            <input class="form-control" v-maska="'##.###.###/####-##'" required type="text"
                                   v-model="cnpjForm" />
                        </div>
                        <div class="col-md-6">
                            <label class="login-label">Nome Completo<span class="text-danger"> *</span></label>
                            <input class="form-control" required type="text" v-model="name"/>
                        </div>
                        <div class="col-md-6">
                            <label class="login-label">CPF<span class="text-danger"> *</span></label>
                            <input class="form-control" required v-maska="'###.###.###-##'" type="text"
                                   v-model="cpfForm"/>
                        </div>
                        <div class="col-md-6">
                            <label class="login-label">E-mail Corporativo<span class="text-danger"> *</span></label>
                            <input class="form-control" required type="email" v-model="email"/>
                        </div>
                        <div class="col-md-3">
                            <label class="login-label">Cargo<span class="text-danger"> *</span></label>
                            <input class="form-control" required type="text" v-model="office"/>
                        </div>
                        <div class="col-md-3">
                            <label class="login-label">Telefone<span class="text-danger"> *</span></label>
                            <input class="form-control" required v-maska="['(##) ####-####', '(##) #####-####']"
                                   type="text"
                                   v-model="phone"/>
                        </div>
                        <div class="col-12 mb-2">
                            <label>Terá acesso a todas as unidades de compras/entregas?</label><br>
                            <div class="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="readonlyStores"
                                    checked
                                    @click="locked = !locked"
                                />
                                <label class="custom-control-label" for="readonlyStores">Sim</label>
                            </div>
                        </div>
                        <div class="col-md-12" v-if="locked">
                            <label class="stations_list">Descreva a lista de estações que precisa de acesso com o nome, UF e município<span class="text-danger"> *</span></label>                                                        
                            <textarea style="resize: none" class="form-control" rows="4" v-model="stations_list"></textarea>
                        </div>
                        
                    </div>
                </form>
                <div class="row">
                    <div class="col text-center my-4">
                        <button class="login-btn w-25" @click="confirm">
                            Enviar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {toastError, ValidateForm} from "@/components/Composables/functions";
import {cpf, cnpj} from 'cpf-cnpj-validator';

export default {
    data() {
        return {
            socialReason: "",
            cnpjForm: "",
            name: "",
            cpfForm: "",
            office: "",
            email: "",
            phone: "",
            id: 0,
            justification: '',
            locked: false,
            uf: '',
            city: '',
            stations_list: ''
        };
    },
    methods: {
        
        save() {
            if (!ValidateForm('form')) {
                return;
            }
            const self = this;
            let api = this.$store.state.api + "access_requests";

            if (!cnpj.isValid(this.cnpjForm)) {
                toastError('CNPJ Inválido!');
                return;
            }

            if (!cpf.isValid(this.cpfForm)) {
                toastError('CPF Inválido!');
                return;
            }


            const formData = {
                socialReason: this.socialReason,
                cnpj: this.cnpjForm,
                name: this.name,
                cpf: this.cpfForm,
                email: this.email,
                office: this.office,
                phone: this.phone,
                stations_list: this.stations_list,
                // uf: this.uf,
                // city: this.city,
                status: this.$route.params.token ? 5 : 1,
            }

            if (this.$route.params.token) {
                formData.id = this.id;
                formData._method = 'PUT';

                api += '/' + this.id;
            }

            axios
                .post(api, formData)
                .then((response) => {
                    this.$toast.success("Solicitação enviada com sucesso! Você receberá retorno em breve no e-mail cadastrado");
                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 2000)
                })
                .catch((error) => {
                    if (error.response.data.message) {
                        self.$toast.error(error.response.data.message);
                    } else if (typeof error.response.data === "object") {
                        for (let item in error.response.data) {
                            self.$toast.error(error.response.data[item][0]);
                        }
                    }
                });
        },
        confirm() {
            this.$swal
                .fire({
                    title: "Atenção!",
                    text: "Deseja enviar seu cadastro para validação?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Cancelar",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.save();
                    }
                });
        },
        getAccessData(param) {

            const self = this;
            const api = this.$store.state.api + "access_requests_revision/" + param;

            axios
                .get(api)
                .then((response) => {

                    if (response.data.stations_list != '') this.locked = true;
                    self.socialReason = response.data.socialReason
                    self.cnpjForm = response.data.cnpj
                    self.name = response.data.name
                    self.cpfForm = response.data.cpf
                    self.office = response.data.office
                    self.email = response.data.email
                    self.phone = response.data.phone
                    self.id = response.data.id
                    self.justification = response.data.justification
                    self.stations_list = response.data.stations_list
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    },
    mounted() {
        const param = this.$route.params.token;
        if (param) {
            this.getAccessData(param)
        }
    }
};
</script>
<style scoped>

label {
    margin-top: 10px;
}

body {
    background: rgba(0, 0, 0, 0);
    background-color: #fff;
    background-size: 100%;
    height: 620px;
}
</style>
