<template>
  <div class="container-fluid">
    <base-table
      :ref="table"
      :filters="filters"
      :crudName="crudName"
      :newText="newText"
      :table="table"
      :endPoint="endPoint"
      :enableAdd="false"
      :enableEdit="false"
      :enableDelete="true"
      :enableSaveColumns="true"
      :relationships="relationships"
      :increments="increments"
      :extras="extras"
      :columns="columns"
      :orders="orders"
      :enableExtract="false"
      @edit="edit"
      @changeStatus="changeStatus"
      @deletar="deletar"
    ></base-table>

    <!-- Modal Status -->

    <div id="modalStatus">
      <div class="modal-fade-background"></div>
      <div
        :class="['modal fade show']"
        id="modal-pending"
        tabindex="-1"
        role="dialog"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
        >
          <div class="modal-content">
            <div id="modalStatus">
              <div class="row">
                <div class="col-md-6 bold">Trocar status da solicitação</div>
                <div class="col-md-6">
                  <button
                    @click="closeModal()"
                    style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                    class="btn btn-danger float-right"
                  >
                    X
                  </button>
                </div>
              </div>
              <div class="row mt-3 centered justify-content-center">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12 bold mt-1">
                      Selecione o novo status para a solicitação
                    </div>
                    <div class="col-md-12 centered" align="center">
                      <select
                        v-model="selectRow.status"
                        class="form-select mt-4"
                      >
                        <option value="0">Aguardando</option>
                        <option value="1">Em atendimento</option>
                        <option value="2">Liberado para integração</option>
                      </select>
                    </div>
                    <div
                      class="col-md-12 justify-content-left mt-4"
                      style="text-align: left"
                    >
                      <strong class="red">Aguardando:</strong> Pedido recebido,
                      porém ainda não analisado por nenhum agente.
                    </div>
                    <div
                      class="col-md-12 justify-content-left mt-4"
                      style="text-align: left"
                    >
                      <strong class="blue">Em atendimento:</strong> Pedido
                      visualizado, porém segue em análise por um agente.
                    </div>
                    <div
                      class="col-md-12 justify-content-left mt-4"
                      style="text-align: left"
                    >
                      <strong class="green">Liberado para integração:</strong>
                      Pedido analisado e liberado para integração ao ERP, o
                      pedido entrará numa fila de processamento e poderá levar
                      até 5 minutos para que seja integrado.
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mt-4 mb-4">
                  <button
                    class="btn btn-primary"
                    @click="changeStatusSolicitation"
                  >
                    Salvar Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
export default {
  data() {
    return {
      selectRow: {},
      crudName: "Pedidos em Rascunho",
      extras: [],
      markers: [
        {
          date: "2022-08-23",
          type: "line",
          color: "green",
          tooltip: [{ text: "Sugerido pelo cliente", color: "green" }],
        },
      ],
      filters: [
        {
          id: "id",
          name: "id",
          label: "ID",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
        {
          id: "company_name",
          name: "company_name",
          label: "Nome",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
        {
          id: "cnpj",
          name: "cnpj",
          label: "CNPJ",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
        {
          id: "created_at",
          name: "created_at",
          label: "Data de Solicitação",
          value: "",
          type: "date",
          class: "form-control",
          columnSize: "col-4 mt-2",
        },
        {
          id: "zaplent",
          name: "zaplent",
          label: "Loja de Entrega",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4 mt-2",
        },
      ],
      newText: "Novo Usuário",
      table: "draftList",
      endPoint: "orders-not-integrateds/",
      relationships: [""],
      increments: "&status=1",
      orders: [
        {
          name: "",
          column: "",
        },
        {
          name: "ID",
          column: "id",
        },
        {
          name: "Data de Solicitação",
          column: "created_at",
        },
      ],
    };
  },
  components: {
    BaseTable,
  },
  mounted() {
    const self = this;
    setTimeout(function () {
      if (
        self.$store.getters.user.type_id == 1 ||
        self.$store.getters.user.type_id == 4 ||
        self.$store.getters.user.type_id == 3
      ) {
        self.loaded = true;
        self.columns.splice(1, 1);
        self.columns.splice(1, 1);
        self.columns.splice(1, 1);
      }
    }, 1000);
  },
  computed: {
    columns() {
      if (
        this.$store.getters.user.type_id == 1 ||
        this.$store.getters.user.type_id == 4
      ) {
        return [
          {
            label: "ID",
            field: "id",
          },
          {
            label: "Nome",
            field: "company_name",
          },
          {
            label: "CNPJ",
            field: "cnpj",
          },
          {
            label: "Loja de Entrega",
            field: "zaplent",
          },
          {
            label: "Produto",
            field: "product_name",
          },
          {
            label: "Quantidade",
            field: "quantity_f",
          },
          {
            label: "Data de Solicitação",
            field: "created_at_f",
          },
          {
            label: "Pedido do Cliente",
            field: "client_order",
          },
          {
            label: "Status",
            type: "fieldClickable",
            callbackFunction: "changeStatus",
            callback: function (row) {
              if (row.status == "0") {
                return "<span class='badge badge-info'>Aguardando</span>";
              }
              if (row.status == "999") {
                return "<span class='badge badge-primary'>Enviado para integração</span>";
              }
              if (row.status == "1") {
                return "<span class='badge badge-warning'>Em rascunho</span>";
              }
              if (row.status == "2") {
                return "<span class='badge badge-info'>Aguardando integração</span>";
              }
              if (row.status == "3") {
                return "<span class='badge badge-success'>Integrado</span>";
              }
              if (row.status == "4") {
                return "<span class='badge badge-danger'>Não Integrado</span>";
              }
            },
          },
          {
            label: "Responsável pelo pedido",
            field: "user_name",
          },
          {
            label: "Visualizar",
            type: "buttonLabel",
            callbackFunction: "edit",
            class: "btn btn-info btn-sm",
            condition: function (row) {
              return false;
            },
            conditionShow: function (row) {
              return true;
            },
            labelCallback: function (row) {
              return row.status == 1 ? "Continuar Pedido" : "Visualizar";
            },
          },
          {
            label: "Deletar",
            type: "button",
            callbackFunction: "deletar",
            class: "btn btn-danger btn-sm",
            condition: function (row) {
              return false;
            },
            conditionShow: function (row) {
              return true;
            },
          },
        ];
      } else {
        return [
          {
            label: "Loja de Entrega",
            field: "zaplent",
          },
          {
            label: "Produto",
            field: "product_name",
          },
          {
            label: "Quantidade",
            field: "quantity_f",
          },
          {
            label: "Data de Solicitação",
            field: "created_at_f",
          },
          {
            label: "Pedido do Cliente",
            field: "client_order",
          },
          {
            label: "Status",
            type: "fieldClickable",
            callbackFunction: "changeStatus",
            callback: function (row) {
              if (row.status == "0") {
                return "<span class='badge badge-info'>Aguardando</span>";
              }
              if (row.status == "999") {
                return "<span class='badge badge-primary'>Enviado para integração</span>";
              }
              if (row.status == "1") {
                return "<span class='badge badge-warning'>Em rascunho</span>";
              }
              if (row.status == "2") {
                return "<span class='badge badge-warning'>Aguardando integração</span>";
              }
              if (row.status == "3") {
                return "<span class='badge badge-success'>Integrado</span>";
              }
              if (row.status == "4") {
                return "<span class='badge badge-danger'>Não Integrado</span>";
              }
            },
          },
          {
            label: "Responsável pelo pedido",
            field: "user_name",
          },
          {
            label: "Visualizar",
            type: "button",
            callbackFunction: "edit",
            class: "btn btn-info btn-sm",
            condition: function (row) {
              return false;
            },
            conditionShow: function (row) {
              return true;
            }
          },
          {
            label: "Deletar",
            type: "button",
            callbackFunction: "deletar",
            class: "btn btn-danger btn-sm",
            condition: function (row) {
              return false;
            },
            conditionShow: function (row) {
              return true;
            },
          },
        ];
      }
    },
  },
  methods: {
    changeStatusSolicitation() {
      const self = this;
      const api =
        this.$store.state.api + "orders-not-integrateds/" + self.selectRow.id;

      console.log(self.selectRow.delivery_date);

      var ret = moment(self.selectRow.delivery_date);

      console.log(ret.format("YYYY-MM-DD"));

      // ret = ret.split("T");

      self.selectRow.delivery_date = ret.format("YYYY-MM-DD");

      axios
        .post(api, {
          _method: "PUT",
          status: self.selectRow.status,
          delivery_date: self.selectRow.delivery_date,
        })
        .then((response) => {
          this.$toast.success("Atualizado com sucesso!");
          this.closeModal();
        })
        .catch((error) => {
          var msg = error.response.data;

          if (typeof msg === "object") {
            let html = "<ul>";

            $.each(msg, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            msg = html;
          }

          toaster.error(msg);

          self.loading = false;
        });
    },
    changeStatus(row) {
      if (row.status < 0) {
        if (this.$store.getters.user.type_id == 1) {
          this.selectRow = row;
          this.markers = row.markers;
          this.openModalStatus();
        }
      }
    },
    edit(row) {
      if (row.status > 1) {
        this.$router.push("/admin/new-order-view/" + row.id);
      } else {
        this.$router.push("/admin/order-edit/" + row.id);
      }
    },
    deletar(row) {
      const self = this;
      const api = this.$store.state.api + "orders-not-integrateds/" + row.id;

      this.$swal
        .fire({
          title: "Deseja deletar o registro?",
          text: "Essa operação não pode ser desfeita",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, deletar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios.delete(api).then((response) => {
              self.$refs.draftList.get_data(1);
              this.$swal(
                "Sucesso",
                "Registro excluído com sucesso!",
                "success"
              );
            });
          }
        });
    },
    openModalStatus() {
      show_modal("modalStatus");
    },

    closeModal() {
      close_modal("modalStatus");
    },
  },
};
</script>
<style scoped>
#modalStatus {
  padding: 15px;
}

.bold {
  font-weight: bold;
  font-size: 16px;
  margin-top: 1px;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}

.centered {
  text-align: center;
}

.green {
  color: green;
}

.modal {
  z-index: 999999999999 !important;
}

.modal-fade-background {
  z-index: 99999999 !important;
}
</style>