import axios from 'axios'
import Store from './store'


axios.interceptors.response.use(
    response => response,
    error => {
        if (parseInt(error.response.status) === 401 && error.response.data.message == 'Unauthenticated.') {
            Store.dispatch('reset')
        } else {
            return Promise.reject(error);
        }
    }
)

axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token')

export default axios