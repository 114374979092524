<template>
  <div class="">
    <div v-if="!alert">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="widget-stat card">
            <div class="card-body p-">
              <div class="media ai-icon">
                <span class="mr-3 bgl-primary text-primary">
                  <img src="../assets/images/icons/contratos.png" width="35" />
                </span>
                <div class="media-body">
                  <p class="mb-1">N° DE CONTRATOS ATIVOS</p>
                  <h5 class="mb-0">{{ contracts.length }}</h5>
                  <!-- <span class="badge badge-primary">+3.5%</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="widget-stat card">
            <div class="card-body p-">
              <div class="media ai-icon">
                <span class="mr-3 bgl-warning text-warning">
                  <span class="flaticon-044-file"></span>
                </span>
                <div class="media-body">
                  <p class="mb-1">Pedidos do mês</p>
                  <h5 class="mb-0">{{ last_month_amount }}</h5>
                  <span class="badge badge-warning">{{
                    lastMonthPercentAmmount
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="widget-stat card">
            <div class="card-body p-4">
              <div class="media ai-icon">
                <span class="mr-3 bgl-danger text-danger">
                  <img src="/src/assets/images/icons/financeiro.png" width="35" />
                </span>
                <div class="media-body">
                  <p class="mb-1">Valor de compra do mês</p>
                  <h5 class="mb-0">{{ getLastMonthValueBR }}</h5>
                  <span class="badge badge-danger">{{
                    lastMonthPercentValue
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="loaded">


        <h5 v-if="pending_invoices.length" class="mt-4 text-center clickable" @click="show_modal()">
          <font-awesome-icon class="red" :icon="['fa', 'exclamation-triangle']" />
          Pagamentos em atraso ({{ pending_invoices_total }})
        </h5>
        <div class="row justify-content-center">

            <div class="col-12 col-md-12">
              <h4 style="text-align: center">Compras por Mês</h4>
              <MonthPurchases @setLastMonthAmount="setLastMonthAmount" @setLastMonthValue="setLastMonthValue"
                @setPenultimateMonthValue="setPenultimateMonthValue"
                @setPenultimateMonthAmount="setPenultimateMonthAmount"></MonthPurchases>
            </div>

        </div>

      </div>
      <div class="row justify-content-center" v-if="loaded">
      
          <div class="col-12 mt-3 scrollable">
            <h4 style="text-align: center">Saldo restante (dias)</h4>
          </div>
          <div class="col-md-12">
            <div v-for="(contract, index) in dataChart.deadline" :key="index">
              <div class="row mt-2" style="
                      background-color: #eaeaea;
                      padding: 10px;
                      border-radius: 5px;
                    " v-if="index < deadline_total_show">
                <div class="col-md-6">
                  Negociação Nº: <strong>{{ contract.label }}</strong>
                </div>
                <div class="col-md-6">
                  <div class="progress" style="height: 20px">
                    <div class="progress-bar bg-info" role="progressbar" :style="'width:' + contract.percentage + '%'"
                      :aria-valuenow="contract.percentage" aria-valuemin="0" aria-valuemax="100">
                      {{ contract.percentage }}%
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  Contrato do Cliente:
                  <strong>{{
                    contract.label_cliente.trim() != ""
                    ? contract.label_cliente
                    : "Não informado"
                    }}</strong>
                </div>
                <div class="col-md-3 mt-2">
                  {{ contract.ini }} até {{ contract.fin }}
                </div>
                <div class="col-md-3 mt-2">
                  Prazo restante: {{ contract.prazo_restante }} dia(s)
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-2" style="margin-left: -30px" v-if="dataChart.deadline.length > 5">
            <button @click="deadline_total_show = 9999999" v-if="deadline_total_show == 5" class="btn btn-primary">
              Exibir todos
            </button>
            <button v-else @click="deadline_total_show = 5" class="btn btn-primary">
              Exibir menos
            </button>
          </div>
                           
     
      </div>
      <div class="row justify-content-center" v-if="loaded">
       
          <div class="col-12 mt-3 scrollable">
            <h4 style="text-align: center">
              Saldo restante (quantidade)
            </h4>
          </div>
          <div class="col-md-12">
            <div v-for="(contract, index) in dataChart.quantity" :key="index">
              <div class="row mt-2" style="
                background-color: #eaeaea;
                padding: 10px;
                border-radius: 5px;
              " v-if="index < quantity_total_show">
                <div class="col-md-6">
                  Negociação Nº: <strong>{{ contract.label }}</strong>
                </div>
                <div class="col-md-6">
                  <div class="progress" style="height: 20px">
                    <div class="progress-bar bg-info" role="progressbar" :style="'width:' + contract.percentage + '%'"
                      :aria-valuenow="contract.percentage" aria-valuemin="0" aria-valuemax="100">
                      <span v-if="contract.percentage > 4">{{ contract.percentage }}%</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mt-2">
                  Contrato do Cliente:
                  <strong>{{
                    contract.label_cliente.trim() != ""
                    ? contract.label_cliente
                    : "Não informado"
                    }}</strong>
                </div>
                <div class="col-md-3 mt-2">
                  Saldo total:
                  {{
                  new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  }).format(contract.prazo_total)
                  }}
                  {{ contract.unity_measure }}
                </div>
                <div class="col-md-3 mt-2">
                  Saldo restante:
                  {{
                  new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  }).format(contract.prazo_restante)
                  }}
                  {{ contract.unity_measure }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-2" style="margin-left: -30px" v-if="dataChart.quantity.length > 5">
            <button @click="quantity_total_show = 9999999" v-if="quantity_total_show == 5" class="btn btn-primary">
              Exibir todos
            </button>
            <button v-else @click="quantity_total_show = 5" class="btn btn-primary">
              Exibir menos
            </button>
          </div>
      
      </div>
      <div class="row" v-if="loaded">
        <gauges-dashboard></gauges-dashboard>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-warning centered" role="alert">
            Não existe nenhum contrato/negociação ativo para este cliente/loja,
            procure o atendimento.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GaugesDashboard from "./GaugesDashboard.vue";
  import MonthlyAmountOrders from "./Charts/MonthAmountOrders.vue";
  import MonthPurchases from "./Charts/MonthPurchases.vue";
  import Bar from "./Charts/Financial/Bar.vue";
  export default {
    components: {
      MonthlyAmountOrders,
      MonthPurchases,
      GaugesDashboard,
      Bar,
    },
    data() {
      return {
        deadline_total_show: 5,
        quantity_total_show: 5,
        contracts: [],
        dataChart: {},
        last_month_amount: "",
        last_month_value: "",
        penultimate_month_amount: "",
        penultimate_month_value: "",
        pending_invoices: [],
        pending_invoices_total: 0,
        loaded: false,
        alert: false,
      };
    },
    computed: {
      getLastMonthValueBR() {
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(this.last_month_value);
      },
      lastMonthPercentAmmount() {
        const self = this;
        if (!this.last_month_amount > 0 && !this.penultimate_month_amount > 0) {
          return "...";
        }
        setTimeout(function () {
          self.$store.dispatch("setLoadingChartAmount", true);
        }, 500);
        let percent =
          (this.last_month_amount / this.penultimate_month_amount) * 100;

        if (percent < 100) {
          return "-" + percent.toFixed(2) + "%";
        }
        return "+" + percent.toFixed(2) + "%";
      },
      lastMonthPercentValue() {
        if (!this.last_month_value > 0 && !this.penultimate_month_value > 0) {
          return "...";
        }
        let percent =
          (this.last_month_value / this.penultimate_month_value) * 100;

        if (percent < 100) {
          return "-" + percent.toFixed(2) + "%";
        }
        return "+" + percent.toFixed(2) + "%";
      },
    },
    methods: {
      setLastMonthAmount(value) {
        this.last_month_amount = value;
      },
      setLastMonthValue(value) {
        this.last_month_value = value;
      },
      setPenultimateMonthAmount(value) {
        this.penultimate_month_amount = value;
      },
      setPenultimateMonthValue(value) {
        this.penultimate_month_value = value;
      },
      getData() {
        const self = this;
        let api = self.$store.getters.api + "contracts/active-contract-list?";
        self.loading = true;
        axios
          .get(api)
          .then((response) => {
            // self.pages = response.data.pages
            // self.page = response.data.page

            if (response.data.data.length == 0) {
              self.loading = false;
              self.alert = true;
            }

            self.contracts = response.data.data.ZOOM;
            self.contract = self.contracts[0];
          })
          .then(() => {
            self.loading = false;
          })
          .catch((error) => {
            // this.$toast.error("Erro na API");
          });
      },
      getChartDeadline() {
        const self = this;
        let api = self.$store.getters.api + "contracts/chartDeadline";
        axios
          .post(api)
          .then((response) => {
            self.dataChart = response.data.data;
          })
          .then(() => {
            self.loaded = true;
          })
          .catch((error) => {
            // this.$toast.error("Erro na API");
          });
      },
    },
    beforeUnmount() {
      this.$store.dispatch("setLoadingChartAmount", false);
    },
    mounted() {
      const self = this;

      if (
        self.$store.state.user.type_id != 1 &&
        self.$store.state.maintenance == true
      ) {
      } else {
        self.getData();
        self.getChartDeadline();
      }
    },
  };
</script>

<style scoped>
  .scrollable {
    overflow: auto;
  }

  #module {
    font-size: 1rem;
    line-height: 1.5;
  }

  #module #collapseExample.collapse:not(.show) {
    display: block;
    height: 3rem;
    overflow: hidden;
  }

  #module #collapseExample.collapsing {
    height: 3rem;
  }

  #module a.collapsed::after {
    content: "+ Show More";
  }

  #module a:not(.collapsed)::after {
    content: "- Show Less";
  }

  .progress {
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #bfc0c2 !important;
    border-radius: 0.25rem;
  }
</style>