<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mb-2">
        <form :action="$store.state.url + 'log_access/'" id="export">
          <div class="block text-right">
            <button class="btn btn-success white float-right" type="submit">
              Exportar Acessos <i class="fas fa-file-export"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
    <base-table
      :ref="table"
      :filters="filters"
      :crudName="crudName"
      :newText="newText"
      :table="table"
      :endPoint="endPoint"
      :enableAdd="false"
      :enableEdit="false"
      :enableDelete="false"
      :relationships="relationships"
      :extras="extras"
      :columns="columns"
      :enableExtract="false"
      :orders="orders"
    ></base-table>
  </div>
</template>

<script>
import MonthlyAmountOrders from "@/components/Charts/MonthAmountOrders.vue";
import BaseTable from "@/components/Layouts/BaseTable.vue";
export default {
  data() {
    return {
      extras: [],
      crudName: "Logs de Acessos",
      filters: [
        {
          id: "user_name",
          name: "user_name",
          label: "Nome do Usuário",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
      ],
      count: 0,
      newText: "",
      table: "log_accesses",
      endPoint: "log_access/",
      relationships: [],
      orders: [],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "IP",
          field: "ip",
        },
        {
          label: "Rota de Acesso",
          field: "routes_t",
        },
        {
          label: "Nome do Usuário",
          field: "user_name",
        },
        {
          label: "Cliente",
          field: "customer_name",
        },
        {
          label: "Data do último acesso",
          field: "created_at_f",
        },
      ],
    };
  },
  components: {
    MonthlyAmountOrders,
    BaseTable,
  },
  methods: {},
};
</script>