<template>
  <div class="" v-if="!loading">
    <div class="row mt-5" v-if="!is_dependent">
      <div
        class="
          col-12 col-md-5
          offset-md-2
          col-xl-5
          offset-xl-4
          col-sm-9
          offset-sm-1
          text-center
        "
      >
        <label>Selecione o contrato</label>
        <select
          class="form-select mb-2"
          v-model="contract"
          @select="calculate()"
        >
          <option
            v-for="(contract, index) in contracts"
            :key="index"
            :value="contract"
            :selected="index === 0"
          >
            {{ contract.CN9_NUMERO }} - {{ contract.CNB_DESCRI }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-4">
        <gauge-chart
          :selected_contract="selected_contract || contract"
          :is_dependent="true"
        ></gauge-chart>
      </div>
      <div class="col-4">
        <period-gauge-chart
          :selected_contract="selected_contract || contract"
          :is_dependent="true"
        ></period-gauge-chart>
      </div>
      <div class="col-4">
        <value-gauge-chart
          :selected_contract="selected_contract || contract"
          :is_dependent="true"
        ></value-gauge-chart>
      </div>
    </div>
  </div>
  <div class="container-fluid text-center" v-if="loading">
    <span class="fa fa-spinner fa-spin fa-5x"></span>
  </div>
</template>

<script>
import GaugeChart from "./Charts/Gauge.vue";
import PeriodGaugeChart from "./Charts/PeriodGauge.vue";
import ValueGaugeChart from "./Charts/ValueGauge.vue";
export default {
  components: {
    GaugeChart,
    PeriodGaugeChart,
    ValueGaugeChart,
  },
  data() {
    return {
      loading: false,
      contract: null,
      contracts: [],
    };
  },
  props: ["selected_contract", "is_dependent"],
  methods: {
    getData() {
      const self = this;
      let api = self.$store.getters.api + "contracts/active-contract-list?";
      self.loading = true;
      axios
        .get(api)
        .then((response) => {
          // self.pages = response.data.pages
          // self.page = response.data.page
          self.contracts = response.data.data.ZOOM;
          self.contract = self.contracts[0];
        })
        .then(() => {
          self.loading = false;
        })
        .catch((error) => {
          // this.$toast.error("Erro na API");
        });
    },
  },
  mounted() {
    if (!this.is_dependent) {
      this.getData();
    }
  },
};
</script>

<style scoped>
.form-control {
  border: 2px solid #e0ebf5 !important;
}
</style>