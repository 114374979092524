<template>
    <div class="container-fluid">
        <base-table
            :ref="table"
            :filters="filters"
            :crudName="crudName"
            :newText="newText"
            :table="table"
            :endPoint="endPoint"
            :enableAdd="false"
            :enableEdit="false"
            :enableDelete="true"
            :relationships="relationships"
            :columns="columns"
            :orders="orders"
            :enableExtract="false"
            @edit="edit"
            @changeStatus="changeStatus"
            :extras="extras"
        ></base-table>

        <!-- Modal Status -->

        <div id="modalStatus">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="modalStatus">
                            <div class="row">
                                <div class="col-md-6 bold">Trocar status da solicitação</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModal()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3 centered justify-content-center">
                                <div class="col-md-12 bold mt-4">
                                    Selecione o novo status para a solicitação
                                </div>
                                <div class="col-md-4" align="center">
                                    <select v-model="selectRow.status" class="form-select mt-4">
                                        <option value="0">Aguardando</option>
                                        <option value="1">Em atendimento</option>
                                        <option value="2">Finalizado</option>
                                    </select>
                                </div>
                                <div class="col-md-12 mt-4 mb-4">
                                    <button
                                        class="btn btn-primary"
                                        @click="changeStatusSolicitation"
                                    >
                                        Salvar Status
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";

export default {
    data() {
        return {
            extras: [],
            selectRow: {},
            crudName: "Cotações",
            extras: [],
            filters: [
                {
                    id: "id",
                    name: "id",
                    label: "ID",
                    value: "",
                    type: "text",
                    class: "form-control",
                    columnSize: "col-4",
                },
                {
                    id: "company_name",
                    name: "company_name",
                    label: "Nome",
                    value: "",
                    type: "text",
                    class: "form-control",
                    columnSize: "col-4",
                },
                {
                    id: "cnpj",
                    name: "cnpj",
                    label: "CNPJ",
                    value: "",
                    type: "text",
                    class: "form-control",
                    columnSize: "col-4",
                },
                {
                    id: "created_at",
                    name: "created_at",
                    label: "Data de Solicitação",
                    value: "",
                    type: "date",
                    class: "form-control",
                    columnSize: "col-4 mt-2",
                },
            ],
            newText: "Novo Usuário",
            table: "user-list",
            endPoint: "licitations/",
            relationships: [""],
            orders: [
                {
                    name: "",
                    column: "",
                },
                {
                    name: "ID",
                    column: "id",
                },
                {
                    name: "Data de Solicitação",
                    column: "created_at",
                },
            ],
            columns: [
                {
                    label: "ID",
                    field: "id",
                },
                {
                    label: "Nome",
                    field: "company_name",
                },
                {
                    label: "CNPJ",
                    field: "cnpj",
                },
                {
                    label: "Data de Solicitação",
                    field: "created_at_f",
                },
                // {
                //   label: "Status",
                //   type: "fieldClickable",
                //   callbackFunction: "changeStatus",
                //   callback: function (row) {
                //     if (row.status == "0") {
                //       return "<span class='badge badge-danger'>Aguardando</span>";
                //     }
                //     if (row.status == "1") {
                //       return "<span class='badge badge-primary'>Em andamento</span>";
                //     }
                //     if (row.status == "2") {
                //       return "<span class='badge badge-success'>Finalizado</span>";
                //     }
                //   },
                // },
                {
                    label: "Visualizar",
                    type: "button",
                    callbackFunction: "edit",
                    class: "btn btn-info btn-sm",
                    condition: function (row) {
                        return false;
                    },
                    conditionShow: function (row) {
                        return true;
                    },
                },
            ],
        };
    },
    components: {
        BaseTable,
    },
    methods: {
        changeStatusSolicitation() {
            const self = this;
            const api = this.$store.state.api + "licitations/" + self.selectRow.id;

            axios
                .post(api, {_method: "PUT", status: self.selectRow.status})
                .then((response) => {
                    this.$toast.success("Atualizado com sucesso!");
                    this.closeModal();
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);

                    self.loading = false;
                });
        },
        changeStatus(row) {
            if (this.$store.getters.user.type_id == 1) {
                this.selectRow = row;
                this.openModalStatus();
            }
        },
        edit(row) {
            this.$router.push("/admin/licitation-view/" + row.id);
        },
        openModalStatus() {
            show_modal("modalStatus");
        },

        closeModal() {
            close_modal("modalStatus");
        },
    },
};
</script>
<style scoped>
#modalStatus {
    padding: 15px;
}

.bold {
    font-weight: bold;
    font-size: 16px;
    margin-top: 1px;
}

.modal-dialog {
    overflow-y: initial !important;
}

.modal-body {
    height: 250px;
    overflow-y: auto;
}
</style>