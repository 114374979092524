<template>
  <div class="container-fluid">
    <router-link :to="'/admin/contract-list'" class="btn btn-primary mt-3 mb-3"><font-awesome-icon
        :icon="['fa', 'arrow-left']" /> Voltar</router-link>

    <div v-if="contract">
      <div class="row">
        <div class="col-md-12">

          <div class="detail-container">
            <detail :contract="contract"></detail>
            <hr />
            <div class="d-flex justify-content-between">
              <h3 class="mt-3 mb-5">Pedidos da Negociação</h3>
              <button class="btn btn-info white mt-3 mb-5 pull-right" @click="contractReport">
                <i v-if='!loadingReport' class="fa fa-file"></i>
                <span v-if='loadingReport' class="fa fa-spinner fa-spin"></span>
                Gerar Relatório
              </button>
            </div>

            <div v-if="contract.orders.length" v-for="(order, index) in contract_orders_limited" :key="index">
              <div class="row">
                <div class="col-12 col-md-2 col-xl-2">
                  Nº pedido: {{ order.C5_NUM }}
                </div>
                <div class="col-12 col-md-2 col-xl-2">
                  Nº medição: {{ order.C5_MDNUMED }}
                </div>
                <div class="col-12 col-md-3 col-xl-3">
                  Quantidade:
                  {{
      new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(order.C6_QTDVEN)
    }}
                </div>
                <div class="col-12 col-md-2 col-xl-2">
                  Valor: {{ format_currency(order.C6_VALOR) }}
                </div>
                <div class="col-12 col-md-1 col-xl-1">
                  <span v-if="!order.C5_NOTA.trim()" class="badge bg-danger">Pendente</span>
                  <span v-else class="badge bg-success">Faturado</span>
                </div>


              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                  Produto: {{ order.C6_PRODUTO }} - {{ order.B1_DESC }}
                </div>
              </div>

              <hr />
            </div>
            <div class="row">
              <div class="text-center">
                <button :disabled="actual_order_page == 1" @click="alter_showing_orders(-5)" class="btn btn-primary">
                  <font-awesome-icon :icon="['fa', 'arrow-up']" /> Mostrar menos
                </button>
                ({{ actual_order_page }} de {{ total_orders_page }})
                <button :disabled="contract.orders.length <= number_of_orders_to_show" @click="alter_showing_orders(5)"
                  class="btn btn-primary">
                  <font-awesome-icon :icon="['fa', 'arrow-down']" /> Mostrar mais
                </button>
              </div>
            </div>
            <gauges-dashboard :selected_contract="contract2" :is_dependent="true"></gauges-dashboard>
            <div class="title mt-5 mb-5">
              <div class="row">
                <div class="col-12 col-md-12 mt-4">
                  <h3 style="text-align: center">Compras por Mês</h3>
                  <MonthPurchases :contract="contract"></MonthPurchases>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GaugesDashboard from "./../GaugesDashboard.vue";
import MonthlyAmountOrders from "./../Charts/MonthAmountOrders.vue";
import MonthPurchases from "./../Charts/MonthPurchases.vue";
import Detail from "./Detail.vue";
export default {
  components: {
    MonthlyAmountOrders,
    MonthPurchases,
    Detail,
    GaugesDashboard,
  },
  data() {
    return {
      loadingReport: false,
      contract: null,
      contract2: null,
      number_of_orders_to_show: 5,
      total_orders_page: 1,
      actual_order_page: 1,
    };
  },
  computed: {
    contract_orders_limited() {
      if (!this.contract) return;

      return this.contract.orders.slice(0, this.number_of_orders_to_show);
    },
  },
  methods: {
    contractReport() {
      let api =
        this.$store.getters.api +
        "contracts/export-detail?CN9_FILIAL=" +
        this.contract.CN9_FILIAL.split('-')[0].trim() +
        "&CN9_NUMERO=" +
        this.contract.CN9_NUMERO;

      let self = this

      this.loadingReport = true

      axios
        .get(api)
        .then((response) => {

          // create file link in browser's memory
          const href = self.$store.getters.api + 'contracts/export-detail-file?hash=' + response.data.hash;

          window.open(href, '_blank').focus();
          self.loadingReport = false

        })
        .catch((error) => {
          // this.$toast.error(
          //   "Erro na solicitação de Contratos:" + error.response.data.message
          // );
          self.loadingReport = false
        });
    },
    alter_showing_orders(number) {
      this.number_of_orders_to_show += number;
      if (number >= 0) {
        this.actual_order_page++;
      } else {
        this.actual_order_page--;
      }
    },
    getContract() {
      const self = this;
      let data = self.$route.params.params;

      var api = self.$store.getters.api + "contracts/detail?params=" + data;

      axios
        .get(api)
        .then((response) => {
          let contract = response.data.data.ZOOM[0];
          var contract2 = response.data.data.ZOOM[0];

          self.total_orders_page = Math.ceil(contract.orders.length / 5);
          self.contract = contract;
          self.contract2 = contract2;

          setTimeout(function () {
            self.$store.dispatch("setLoadingChartAmount", true);
          }, 5000);
        })
        .catch((error) => {
          this.$toast.error(
            "Erro na solicitação de Contratos:" + error.response.data.message
          );
        });
    },
    format_currency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
  mounted() {
    this.getContract();
  },
};
</script>