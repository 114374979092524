<template>
  <div class="container-fluid">
    <section id="publico" v-if="tipoCliente == 2">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                INFORMAÇÕES NECESSÁRIAS PARA ESTIMATIVA DE PREÇOS OU PARA COMPRA
                DIRETA
              </span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label class="bold"
                    >RAZÃO SOCIAL DO CLIENTE / ORGÃO SOLICITANTE</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.company_name"
                  />
                </div>
                <div class="col-md-3">
                  <label class="bold">CNPJ</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.cnpj"
                    v-maska="'##.###.###/####-##'"
                  />
                </div>
                <div class="col-md-3">
                  <label class="bold">DATA</label>
                  <input
                    disabled
                    type="date"
                    class="form-control"
                    v-model="licitation.date"
                  />
                </div>
                <div class="col-md-12 mt-2" style="z-index: 99999">
                  <label class="bold"
                    >ANEXAR ARQUIVO (.pdf .docx .xlsx .jpg .png)
                    (Opcional)</label
                  >
                  <br />
                  <input
                    type="file"
                    name="file"
                    id="file"
                    accept="application/pdf,.xlsx,.xls,.doc,.docx,image/*"
                  />
                  <font-awesome-icon
                    style="
                      margin-left: 2%;
                      font-weight: 100;
                      margin-right: -15px;
                      cursor: pointer;
                    "
                    @click="clearFile()"
                    :icon="['fa', 'trash']"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4">
                  <label class="form-check-label bold"
                    >A FINALIDADE DA COTAÇÃO É PARA:</label
                  >
                </div>
                <div class="col-md-3" style="margin-left: -10px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd1"
                    id="rd1"
                    value="COMPRA DIRETA"
                    v-model="licitation.purpose_of_quotation"
                  />
                  <label class="form-check-label">COMPRA DIRETA</label>
                </div>
                <div class="col-md-5" style="margin-left: -65px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd1"
                    id="rd1"
                    value="ESTIMATIVA PARA ABERTURA DA LICITAÇÃO"
                    v-model="licitation.purpose_of_quotation"
                  />
                  <label class="form-check-label"
                    >ESTIMATIVA PARA ABERTURA DA LICITAÇÃO</label
                  >
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-10">
                  <label class="bold"
                    >DESCRIÇÃO DO PRODUTO (Incluir concentração, Base Seca, etc,
                    se for o caso)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.product"
                  />
                </div>
                <!-- <div class="col-md-1" style="margin-top: 40px">
                  <font-awesome-icon
                    :icon="['fa', 'search']"
                    class="clickable"
                    @click="openModalProduto"
                    style="color: #305084; font-weight: bold"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Selecionar um produto da base de produtos"
                  />
                </div> -->
                <div class="col-md-2">
                  <label class="bold">QUANTIDADE:</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.quantity"
                  />
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-3">
                  <label class="form-check-label bold"
                    >UNIDADE DE FORNECIMENTO:</label
                  >
                </div>
                <div class="col-md-1" style="margin-left: 10px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="KG"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">KG</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="TON"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">TON</label>
                </div>
                <div class="col-md-1" style="margin-left: -5px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="L"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">L</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="UN"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">UN</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="M³"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">M³</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="OUTRA"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">OUTRA:</label>
                </div>
                <div
                  class="col-md-2"
                  style="margin-left: -25px; margin-top: -10px"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.specification"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2">
                  <label class="form-check-label bold">TRANSPORTE:</label>
                </div>

                <div class="col-md-2" style="margin-left: 0">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="rd3"
                    id="rd3"
                    value="CARRETA"
                    v-model="licitation.delivery_type_carreta"
                  />
                  <label class="form-check-label">CARRETA</label>
                </div>
                <div class="col-md-2" style="margin-left: -65px">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="rd3"
                    id="rd3"
                    value="TRUCK"
                    v-model="licitation.delivery_type_truck"
                  />
                  <label class="form-check-label">TRUCK</label>
                </div>
                <div class="col-md-2" style="margin-left: -80px">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="rd3"
                    id="rd3"
                    value="TOCO"
                    v-model="licitation.delivery_type_toco"
                  />
                  <label class="form-check-label">TOCO</label>
                </div>
              </div>
              <div class="row mt-4" style="margin-top: 35px">
                <div class="col-md-2">
                  <label class="form-check-label bold">CONTAINER:</label>
                </div>
                <div class="col-md-2" style="margin-left: -30px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd4"
                    id="rd4"
                    value="COM TRANSBORDO"
                    v-model="licitation.container"
                  />
                  <label class="form-check-label">COM TRANSBORDO</label>
                </div>
                <div class="col-md-2" style="margin-left: 10px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd4"
                    id="rd4"
                    value="EM COMODATO"
                    v-model="licitation.container"
                  />
                  <label class="form-check-label">EM COMODATO</label>
                </div>
                <div class="col-md-2" style="margin-left: -15px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd4"
                    id="rd4"
                    value="INCLUSO"
                    v-model="licitation.container"
                  />
                  <label class="form-check-label">INCLUSO</label>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2" style="">
                  <label class="form-check-label bold">BOMBONAS:</label>
                </div>
                <div class="col-md-1" style="margin-left: -30px">
                  <div class="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked=""
                      id="customCheckBox2"
                      required=""
                      v-model="licitation.select_bonbons"
                    />
                    <label class="form-check-label" for="customCheckBox2"
                      >KG/LITROS?</label
                    >
                  </div>
                </div>
                <div class="col-md-2 ml-4">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.kg_l"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2">
                  <label class="form-check-label bold">SACOS:</label>
                </div>
                <div class="col-md-1" style="margin-left: -85px">
                  <div class="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      checked=""
                      id="customCheckBox2"
                      required=""
                      v-model="licitation.select_bags"
                    />
                    <label class="form-check-label" for="customCheckBox2"
                      >KG?</label
                    >
                  </div>
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.bags"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >SE BOMBONAS E SACOS, O FORNECEDOR DEVE RETIRAR AS
                    EMBALAGENS VAZIAS?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    class="toggleStyle"
                    v-model="licitation.remove_packaging"
                    v-bind="simNao"
                  />
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-md-2">
                  <label class="bold">FRETE:</label>
                </div>
                <div class="col-md-2" style="margin-left: -65px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd5"
                    id="rd5"
                    value="CIF"
                    v-model="licitation.shipping_type"
                  />
                  <label class="form-check-label">CIF</label>
                </div>
                <div class="col-md-2" style="margin-left: -95px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd5"
                    id="rd5"
                    value="FOB"
                    v-model="licitation.shipping_type"
                  />
                  <label class="form-check-label">FOB</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                CONDIÇÃO DE ENTREGA (MARQUE AS OPÇÕES)
              </span>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >PARA DESCARGA DO PRODUTO, DEMANDARÁ AJUDANTE
                    (CHAPA)?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    v-model="licitation.shipping_helper"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >DEMANDARÁ CONTRATAÇÃO DE EMPILHADEIRA?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    v-model="licitation.shipping_forklift"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >A LICITAÇÃO EXIGIRÁ BOMBA DOSADORA E PAINEL EM
                    COMODATO?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    v-model="licitation.shipping_pump_and_panel"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >A LICITAÇÃO EXIGIRÁ TANQUE EM COMODATO?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    v-model="licitation.shipping_tank"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <label class="form-check-label bold"
                    >DEMAIS ESPECIFICAÇÃO DO TANQUE:</label
                  >
                </div>
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.shipping_especification_tank"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12"
          v-if="licitation.purpose_of_quotation == 'COMPRA DIRETA'"
        >
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                SE FOR COTAÇÃO PARA COMPRA DIRETA, TAMBÉM INDICAR
              </span>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-md-3">
                  <label class="form-check-label bold">PRAZO DE ENTREGA:</label>
                </div>
                <div class="col-md-3" style="margin-left: -55px">
                  <input
                    type="text"
                    v-maska="'##/##/####'"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.deadline"
                  />
                </div>
                <div class="col-md-3">
                  <label class="form-check-label bold">QUANTAS ENTREGAS:</label>
                </div>
                <div class="col-md-3" style="margin-left: -30px">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -15px"
                    v-model="licitation.number_of_deliveries"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <label class="form-check-label bold"
                    >QUANTIDADE DE PRODUTO POR ENTREGA:</label
                  >
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -55px"
                    v-model="licitation.amount_of_product"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4">
                  <label class="form-check-label bold"
                    >VIGÊNCIA DO FORNECIMENTO:</label
                  >
                </div>
                <div class="col-md-3">
                  <input
                    type="text"
                    v-maska="'##/##/####'"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -60px"
                    v-model="licitation.duration_of_supply"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <label class="form-check-label bold">LOCAL DA ENTREGA:</label>
                </div>
                <div
                  class="col-md-6 mr-3"
                  style="margin-top: -10px; margin-left: -55px"
                >
                  <input
                    type="text"
                    class="form-control"
                    v-model="licitation.delivery_place_text"
                  />
                </div>
                <div class="col-md-1" style="margin-left: -15px">
                  <label class="bold">BAIRRO:</label>
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.delivery_neighborhood"
                  />
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-1 ml-1">
                  <label class="bold">CIDADE:</label>
                </div>
                <div class="col-md-4">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px; margin-left: 0px"
                    v-model="licitation.city"
                  />
                </div>
                <div class="col-md-1">
                  <label class="bold">UF:</label>
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -45px"
                    v-model="licitation.uf"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-12 bold"
          v-if="licitation.purpose_of_quotation == 'COMPRA DIRETA'"
        >
          PARA FORMALIZAÇÃO DA COMPRA DIRETA, INFORMAR A LEGISLAÇÃO DE ORIGEM
          (LEI 8.666/93 ou 14.133/21)
        </div>
        <div class="col-lg-12">
          *Se vencedores da cotação, necessitaremos do envio de parecer
          jurídico, caso o valor da Compra Direta seja maior do que o definido
          em lei
        </div>
        <div class="col-lg-12 mt-4 mb-4">
          <button class="btn btn-primary float-right" @click="saveForm">
            Enviar
          </button>
        </div>
      </div>
    </section>
    <section id="privado" v-if="tipoCliente == 1">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span class="card-title">
              INFORMAÇÕES NECESSÁRIAS PARA ESTIMATIVA DE PREÇOS OU PARA COMPRA
              DIRETA
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label class="bold"
                  >RAZÃO SOCIAL DO CLIENTE / ORGÃO SOLICITANTE</label
                >
                <input
                  type="text"
                  class="form-control"
                  v-model="licitation.company_name"
                />
              </div>
              <div class="col-md-3">
                <label class="bold">CNPJ</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="licitation.cnpj"
                  v-maska="'##.###.###/####-##'"
                />
              </div>
              <div class="col-md-3">
                <label class="bold">DATA</label>
                <input
                  disabled
                  type="date"
                  class="form-control"
                  v-model="licitation.date"
                />
              </div>
              <div class="col-md-6 mt-2">
                <label class="bold"
                  >ANEXAR ARQUIVO (.pdf .docx .xlsx .jpg .png) (Opcional)</label
                >
                <br />
                <input
                  type="file"
                  name="file"
                  id="file"
                  accept="application/pdf,.xlsx,.xls,.doc,.docx,image/*"
                />
                <font-awesome-icon
                  style="
                    margin-left: 2%;
                    font-weight: 100;
                    margin-right: -15px;
                    cursor: pointer;
                  "
                  @click="clearFile()"
                  :icon="['fa', 'trash']"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-2">
                <label class="form-check-label bold">REVENDA:</label>
              </div>

              <div class="col-md-1" style="margin-left: -35px">
                <Toggle
                  v-model="licitation.reseller"
                  class="toggleStyle"
                  v-bind="simNao"
                />
              </div>
              <div class="col-md-1 ml-5">
                <label class="bold">PRODUTO:</label>
              </div>
              <div class="col-md-7 ml-3">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.product"
                />
              </div>
              <!-- <div class="col-md-1">
                <font-awesome-icon
                  :icon="['fa', 'search']"
                  class="clickable"
                  @click="openModalProduto"
                  style="color: #305084; font-weight: bold"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Selecionar um produto da base de produtos"
                />
              </div> -->
            </div>
            <div class="row mt-5">
              <div class="col-md-2">
                <label class="bold">QUANTIDADE:</label>
              </div>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.quantity"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-4">
                <label class="form-check-label bold"
                  >UNIDADE DE FORNECIMENTO:</label
                >
              </div>
              <div class="col-md-1" style="margin-left: -55px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="KG"
                  v-model="licitation.und"
                />
                <label class="form-check-label">KG</label>
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="TON"
                  v-model="licitation.und"
                />
                <label class="form-check-label">TON</label>
              </div>
              <div class="col-md-1" style="margin-left: -5px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="L"
                  v-model="licitation.und"
                />
                <label class="form-check-label">L</label>
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="UN"
                  v-model="licitation.und"
                />
                <label class="form-check-label">UN</label>
              </div>
              <div class="col-md-1" style="margin-left: -15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="M³"
                  v-model="licitation.und"
                />
                <label class="form-check-label">M³</label>
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="OUTRA"
                  v-model="licitation.und"
                />
                <label class="form-check-label">OUTRA:</label>
              </div>
              <div
                class="col-md-2"
                style="margin-left: -5px; margin-top: -10px"
              >
                <input
                  type="text"
                  class="form-control"
                  v-model="licitation.specification"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-2">
                <label class="form-check-label bold">TRANSPORTE:</label>
              </div>

              <div class="col-md-2" style="margin-left: 0">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="rd3"
                  id="rd3"
                  value="CARRETA"
                  v-model="licitation.delivery_type_carreta"
                />
                <label class="form-check-label">CARRETA</label>
              </div>
              <div class="col-md-2" style="margin-left: -65px">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="rd3"
                  id="rd3"
                  value="TRUCK"
                  v-model="licitation.delivery_type_truck"
                />
                <label class="form-check-label">TRUCK</label>
              </div>
              <div class="col-md-2" style="margin-left: -80px">
                <input
                  type="checkbox"
                  class="form-check-input"
                  name="rd3"
                  id="rd3"
                  value="TOCO"
                  v-model="licitation.delivery_type_toco"
                />
                <label class="form-check-label">TOCO</label>
              </div>
            </div>
            <div class="row mt-4" style="margin-top: 35px">
              <div class="col-md-2">
                <label class="form-check-label bold">CONTAINER:</label>
              </div>
              <div class="col-md-3" style="margin-left: -30px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd4"
                  id="rd4"
                  value="COM TRANSBORDO"
                  v-model="licitation.container"
                />
                <label class="form-check-label">COM TRANSBORDO</label>
              </div>
              <div class="col-md-2" style="margin-left: -65px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd4"
                  id="rd4"
                  value="EM COMODATO"
                  v-model="licitation.container"
                />
                <label class="form-check-label">EM COMODATO</label>
              </div>
              <div class="col-md-2" style="margin-left: -15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd4"
                  id="rd4"
                  value="INCLUSO"
                  v-model="licitation.container"
                />
                <label class="form-check-label">INCLUSO</label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2">
                <label class="form-check-label bold">BOMBONAS:</label>
              </div>
              <div class="col-md-1 mr-5" style="margin-left: -15px">
                <div class="form-check custom-checkbox mb-3 checkbox-info">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    checked=""
                    id="customCheckBox2"
                    required=""
                    v-model="licitation.select_bonbons"
                  />
                  <label class="form-check-label" for="customCheckBox2"
                    >KG/LITROS?</label
                  >
                </div>
              </div>
              <div class="col-md-2 ml-4">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.kg_l"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2">
                <label class="form-check-label bold">SACOS:</label>
              </div>
              <div class="col-md-1" style="margin-left: -45px">
                <div class="form-check custom-checkbox mb-3 checkbox-info">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    checked=""
                    id="customCheckBox2"
                    required=""
                    v-model="licitation.select_bags"
                  />
                  <label class="form-check-label" for="customCheckBox2"
                    >KG?</label
                  >
                </div>
              </div>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.bags"
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-10">
                <label class="form-check-label bold"
                  >SE BOMBONAS E SACOS, O FORNECEDOR DEVE RETIRAR AS EMBALAGENS
                  VAZIAS?</label
                >
              </div>
              <div class="col-md-2">
                <Toggle
                  class="toggleStyle"
                  v-model="licitation.remove_packaging"
                  v-bind="simNao"
                />
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-3">
                <label class="bold">LOCAL DE ENTREGA:</label>
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px; margin-left: -25px"
                  v-model="licitation.delivery_place"
                />
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <label class="bold">BAIRRO:</label>
              </div>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.delivery_neighborhood"
                />
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-1">
                <label class="bold">CIDADE:</label>
              </div>
              <div class="col-md-4">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px; margin-left: 0px"
                  v-model="licitation.city"
                />
              </div>
              <div class="col-md-1">
                <label class="bold">UF:</label>
              </div>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px; margin-left: -45px"
                  v-model="licitation.uf"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-2">
                <label class="bold">FRETE:</label>
              </div>
              <div class="col-md-2" style="margin-left: -65px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd6"
                  id="rd6"
                  value="CIF"
                  v-model="licitation.shipping_type"
                />
                <label class="form-check-label">CIF</label>
              </div>
              <div class="col-md-2" style="margin-left: -95px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd6"
                  id="rd6"
                  value="FOB"
                  v-model="licitation.shipping_type"
                />
                <label class="form-check-label">FOB</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-4 mb-4">
        <button class="btn btn-primary float-right" @click="saveForm">
          Enviar
        </button>
      </div>
    </section>

    <!-- Modal Produto -->

    <div id="modalProduto">
      <div class="modal-fade-background"></div>
      <div
        :class="['modal fade show']"
        id="modal-pending"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div id="ModalProduto">
              <div class="row">
                <div class="col-md-6 bold">Buscar Produtos</div>
                <div class="col-md-6">
                  <button
                    @click="closeModal()"
                    style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                    class="btn btn-danger float-right"
                  >
                    X
                  </button>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <label>Nome do produto</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="searchProductField"
                  />
                </div>
                <div class="col-md-12 centered mt-3">
                  <button class="btn btn-primary" @click="searchProduct(1)">
                    Buscar
                  </button>
                </div>
                <div class="col-md-12" v-if="products.length > 0">
                  <div class="row mb-4 bold">
                    <div class="col-md-2">Código</div>
                    <div class="col-md-6">Nome Produto</div>
                    <div class="col-md-2">Un. Medida</div>
                    <div class="col-md-2">Ação</div>
                  </div>
                  <div
                    class="row mt-3 mb-1"
                    v-for="product in products"
                    :key="product"
                    style="border-bottom: 1px solid #a1a1a1"
                  >
                    <div class="col-md-2">{{ product.B1_COD }}</div>
                    <div class="col-md-6">{{ product.B1_DESC }}</div>
                    <div class="col-md-2">{{ product.B1_UM }}</div>
                    <div class="col-md-2">
                      <button
                        class="btn btn-sm btn-primary"
                        style="margin-top: -10px; margin-bottom: 5px"
                        @click="selectProduct(product), closeModal()"
                      >
                        Selecionar
                      </button>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <nav aria-label="" class="mt-3">
                      <label>Página: {{ currentPage + 1 }}/</label>
                      <label v-if="lastPage > 0">{{ lastPage }}</label>
                      <label v-if="lastPage <= 0">{{ lastPage + 1 }}</label>
                      <ul class="pagination">
                        <i class="fa-solid fa-arrow-right-to-bracket"></i>
                        <li class="page-item">
                          <button
                            @click="searchProduct(firstPage)"
                            class="page-link"
                            href="#"
                          >
                            <span
                              class="fa fa-arrow-left"
                              style="color: grey"
                            ></span>
                          </button>
                        </li>
                        <li class="page-item">
                          <button
                            @click="searchProduct(currentPage)"
                            class="page-link"
                            href="#"
                          >
                            <span
                              class="fa fa-chevron-left"
                              style="color: grey"
                            ></span>
                          </button>
                        </li>

                        <li class="page-item" v-if="currentPage > firstPage">
                          <button
                            @click="searchProduct(currentPage - 1)"
                            class="page-link"
                            href="#"
                          >
                            {{ currentPage - 1 }}
                          </button>
                        </li>

                        <li class="page-item" v-if="currentPage >= firstPage">
                          <button
                            @click="searchProduct(currentPage)"
                            class="page-link"
                            href="#"
                          >
                            {{ currentPage }}
                          </button>
                        </li>

                        <li class="page-item active">
                          <button class="page-link" href="#">
                            {{ currentPage + 1 }}
                          </button>
                        </li>

                        <li class="page-item" v-if="currentPage + 1 < lastPage">
                          <button
                            @click="searchProduct(currentPage + 2)"
                            class="page-link"
                            href="#"
                          >
                            {{ currentPage + 2 }}
                          </button>
                        </li>
                        <li class="page-item" v-if="currentPage + 2 < lastPage">
                          <button
                            @click="searchProduct(currentPage + 3)"
                            class="page-link"
                            href="#"
                          >
                            {{ currentPage + 3 }}
                          </button>
                        </li>

                        <li class="page-item">
                          <button
                            @click="searchProduct(currentPage + 2)"
                            class="page-link"
                            href="#"
                          >
                            <span
                              class="fa fa-chevron-right"
                              style="color: grey"
                            ></span>
                          </button>
                        </li>
                        <li class="page-item">
                          <button
                            @click="searchProduct(lastPage)"
                            class="page-link"
                            href="#"
                          >
                            <span
                              class="fa fa-arrow-right"
                              style="color: grey"
                            ></span>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";
import Toggle from "@vueform/toggle";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
});

export default {
  components: {
    vSelect,
    Toggle,
  },
  data() {
    return {
      company: {},
      currentPage: 0,
      firstPage: 1,
      lastPage: 0,
      count: 0,
      licitation: {
        date: moment().format("YYYY-MM-DD"),
        select_bonbons: false,
        select_bags: false,
        delivery_type: "",
      },
      productSelected: {},
      tipoCliente: 1, // 1 = Publico, 2 = Privado
      searchProductField: "",
      simNao: {
        onLabel: "SIM",
        offLabel: "NÃO",
      },
      products: [],
      value: true,
      customers: [],
      stores: [],
      selectedCustomer: {},
      user: {
        name: "",
        email: "",
        password: "",
        customer_id: null,
        stores: [],
        type_id: "",
        access_contracts: false,
        access_invoices: false,
        access_requests: false,
        access_logistics: false,
      },
    };
  },
  computed: {
    customerList() {
      return this.customers;
    },
  },
  methods: {
    selectProduct(obj) {
      const self = this;

      self.productSelected = obj;
      self.licitation.product = obj.B1_DESC;
    },
    openModalProduto() {
      show_modal("modalProduto");
    },

    closeModal() {
      close_modal("modalProduto");
    },
    searchCustomers(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    getCustomer() {
      const self = this;
      const api = this.$store.state.api + "get-customer/";

      axios
        .post(api)
        .then((response) => {
          self.company = response.data.data.ZOOM[0];

          self.tipoCliente = self.company.A1_SETOR;
          self.licitation.company_name = self.company.A1_NOME;
          self.licitation.company_type = self.tipoCliente;
          self.licitation.cnpj = self.company.A1_CGC;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
    searchProduct(page) {
      const self = this;
      var api = this.$store.state.api + "search_products/";

      var pg = page;

      if (self.lastPage > 0) {
        if (pg > self.lastPage) {
          self.loading = false;
          return;
        }
        if (pg < self.firstPage) {
          self.loading = false;
          return;
        }
      }

      if (page < 1) {
        page = 1;
      }

      // api += "?page=" + page;

      axios
        .post(api, { page: page, search: self.searchProductField })
        .then((response) => {
          self.products = response.data.data.ZOOM;

          self.count = response.data.data.count;
          if (page != 0) {
            self.currentPage = page - 1;
          } else {
            self.currentPage = page;
          }
          self.lastPage = parseInt(response.data.data.count / 15);

          if (response.data.data.count % 15 > 0 && self.lastPage == 1) {
            self.lastPage++;
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
    saveForm() {
      const self = this;
      const api = this.$store.state.api + "licitations/save";

      self.licitation.delivery_type = "";

      if (self.licitation.delivery_type_toco) {
        self.licitation.delivery_type += "TOCO; ";
      }

      if (self.licitation.delivery_type_truck) {
        self.licitation.delivery_type += "TRUCK; ";
      }

      if (self.licitation.delivery_type_carreta) {
        self.licitation.delivery_type += "CARRETA; ";
      }
      axios
        .post(api, self.licitation)
        .then((response) => {
          self.uploadFile(response.data.id);
        })
        .catch((error) => {
          var msg = error.response.data;

          if (typeof msg === "object") {
            let html = "<ul>";

            $.each(msg, function (key, value) {
              html += "<li>" + value[0] + "</li>";
            });

            html += "</ul>";

            msg = html;
          }

          toaster.error(msg);

          self.loading = false;
        });
    },
    uploadFile(id) {
      const self = this;
      const api = this.$store.state.api + "licitations/upload-file";

      let file = document.getElementById("file");

      const fd = new FormData();

      fd.append("id", id);
      fd.append("file", file.files[0] ? file.files[0] : "");

      axios
        .post(api, fd)
        .then((response) => {
          self.$toast.success("Enviado com sucesso!");
          self.clearFile();
          self.$router.push("/admin/licitation-list");
        })
        .catch((error) => {
          self.$toast.error(error.response.data.message);
        });
    },
    clearFile() {
      $("#file").val("");
    },
  },
  mounted() {
    const self = this;
    self.getCustomer();
  },
};
</script>
<style scoped>
.card-title {
  font-size: 16px;
  margin-top: -15px;
  margin-bottom: -15px !important;
}
.card-header {
  background-color: #f1f1f1 !important;
  border: 1px solid #e1e1e1 !important;
}
.card-body {
  border: 1px solid #e1e1e1 !important;
}
.bg {
  background-color: blueviolet;
}
.bold {
  font-weight: bold;
  font-size: 16px;
  margin-top: 1px;
}
.toggleStyle {
  --toggle-width: 4rem !important;
  --toggle-height: 1.45rem !important;
  --toggle-font-size: 0.85rem !important;
  font-weight: bold;
}
#ModalProduto {
  padding: 15px;
}

.badge-primary {
  background-color: var(--primary);
  color: #fff;
}
.modal-dialog {
  overflow-y: initial !important;
}
.modal-content {
  max-height: 550px;
  overflow-y: auto !important;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
