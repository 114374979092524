<template>
    <div class="container-fluid title">
        <div class="row" v-if="!loading">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">Pedidos a Faturar</div>
                    <div class="card-body" v-for="unbilled in unbilleds" :key="unbilled">
                        <h5 class="card-title">Pedido: {{ unbilled.C5_NUM }}</h5>
                        <p class="card-text">{{ unbilled.B1_ESPECIE }}</p>
                        <p class="card-text">{{ unbilled.A1_ZAPLENT}}</p>
                        <p class="card-text">
                            {{
                                new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(unbilled.C6_QTDVEN)
                            }}
                            {{
                                unbilled.C6_XUNFAT.trim() != ""
                                    ? unbilled.C6_SEGUM
                                    : unbilled.C6_UM
                            }}
                        </p>
                        <p class="card-text">
                            Previsão de Entrega: {{ unbilled.C6_ENTREG }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-4" style="display:none" >
                <div class="card">
                    <div class="card-header">Pedidos Faturados</div>
                    <div class="card-body" v-for="billed in billeds" :key="billed">
                        <h5 class="card-title">Pedido: {{ billed.C5_NUM }}</h5>
                        <p class="card-text">{{ billed.B1_ESPECIE }}</p>
                        <p class="card-text">{{ billed.A1_ZAPLENT}}</p>
                        <p class="card-text">
                            {{
                                new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(billed.C6_QTDVEN)
                            }}
                            {{
                                billed.C6_XUNFAT.trim() != "" ? billed.C6_SEGUM : billed.C6_UM
                            }}
                        </p>
                        <p class="card-text">Previsão de Entrega: {{ billed.C6_ENTREG }}</p>
                        <p class="card-text">NF: {{ billed.C5_NOTA }}</p>
                        <!-- <a class="btn btn-kanban"
                          ><font-awesome-icon :icon="['fa', 'map-marked-alt']"
                        /></a> -->

                        <button
                            class="btn btn-kanban btn-info"
                            @click="danfe(billed.C5_NOTA, billed.C5_FILIAL)"
                            data-toggle="tooltip"
                            title="Nota Fiscal"
                        >
                            <font-awesome-icon :icon="['fa', 'download']"/>
                        </button>
                        <!-- <button class="btn btn-kanban btn-info" v-if="payment_method != 'D'">
                            <font-awesome-icon :icon="['fa', 'barcode']"/>
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">Pedidos Em Rota</div>
                    <div
                        class="card-body"
                        v-for="delivery in in_delivery"
                        :key="delivery"
                    >
                        <h5 class="card-title">Pedido: {{ delivery.DAI_PEDIDO }}</h5>
                        <p class="card-text">{{ delivery.B1_ESPECIE }}</p>
                        <p class="card-text">{{ delivery.A1_ZAPLENT}}</p>
                        <p class="card-text">
                            {{
                                new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(delivery.C6_QTDVEN)
                            }}
                            {{
                                delivery.C6_XUNFAT.trim() != ""
                                    ? delivery.C6_SEGUM
                                    : delivery.C6_UM
                            }}
                        </p>
                        <p class="card-text">
                            Previsão de Entrega: {{ delivery.C6_ENTREG }}
                        </p>
                        <p class="card-text">NF: {{ delivery.DAI_NFISCA }}</p>
                        <button
                            class="btn btn-kanban btn-info"
                            v-if="transportadoras.includes(delivery.A4_NOME.trim())"
                            @click="tracking(delivery)"
                            data-toggle="tooltip"
                            title="Rastreio de Veículo"
                        >
                            <font-awesome-icon :icon="['fa', 'map-marked-alt']"/>
                        </button>

                        <button
                            class="btn btn-kanban btn-info"
                            @click="danfe(delivery.DAI_NFISCA, delivery.F2_FILIAL)"
                            data-toggle="tooltip"
                            title="Nota Fiscal"
                        >
                            <font-awesome-icon :icon="['fa', 'download']"/>
                        </button>
                        <!-- <button class="btn btn-kanban btn-info" v-if="payment_method != 'D'">
                            <font-awesome-icon :icon="['fa', 'barcode']"/>
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-header">Pedidos Entregues</div>
                    <div class="card-body" v-for="delivery in delivered" :key="delivery">
                        <h5 class="card-title">Pedido: {{ delivery.DAI_PEDIDO }}</h5>
                        <p class="card-text">{{ delivery.B1_ESPECIE }}</p>
                        <p class="card-text">{{ delivery.A1_ZAPLENT}}</p>
                        <p class="card-text">
                            {{
                                new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(delivery.C6_QTDVEN)
                            }}
                            {{
                                delivery.C6_XUNFAT.trim() != ""
                                    ? delivery.C6_SEGUM
                                    : delivery.C6_UM
                            }}
                        </p>
                        <p class="card-text">Data da Entrega: {{ delivery.C6_ENTREG }}</p>
                        <p class="card-text">NF: {{ delivery.DAI_NFISCA }}</p>

                        <button
                            class="btn btn-kanban btn-info"
                            @click="danfe(delivery.DAI_NFISCA, delivery.F2_FILIAL)"
                            data-toggle="tooltip"
                            title="Nota Fiscal"
                        >
                            <font-awesome-icon :icon="['fa', 'download']"/>
                        </button>
                        <button
                            class="btn btn-kanban btn-info"
                            v-if="
                                transportadoras.includes(delivery.A4_NOME.trim()) &&
                                delivery.C5_ENTREAL != '  /  /  '
                            "
                            @click="canhoto(delivery)"
                            data-toggle="tooltip"
                            title="Canhoto de Entrega"
                        >
                            <font-awesome-icon :icon="['fa', 'file-alt']"/>
                        </button>
                        <!-- <button class="btn btn-kanban btn-info" v-if="payment_method != 'D'">
                            <font-awesome-icon :icon="['fa', 'barcode']"/>
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body text-center" v-if="loading">
            <span class="fa fa-spinner fa-spin fa-5x"></span>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            unbilleds: [],
            billeds: [],
            in_delivery: [],
            delivered: [],
            cTop: 5,
            payment_method: "D",
            transportadoras: [
                "BAUMINAS LOG E TRANSPORTE LTDA.",
                "BAUMINAS LOG E TRANSPORTES LTDA",
                "BAUMINAS LOG E TRANSPORTE S/A",
                "BAUMINAS LOG E TRANSPORTES S/A",
                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                "AMBIENTALY IND E COM DE PROD QUIMICOS LT"
            ],
            loading: true,
        };
    },
    methods: {
        getUnbilled() {
            const self = this;
            const api =
                this.$store.state.api +
                "orders/?page=1&status=unbilled&order_by=C6_ENTREG&order_type=DESC&cTop=" +
                self.cTop;

            axios
                .get(api)
                .then((response) => {
                    self.unbilleds = response.data.data.ZOOM;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getBilled() {
            const self = this;
            const api =
                this.$store.state.api +
                "orders/?page=1&nao_entregues=true&status=billed&order_by=C6_ENTREG&order_type=DESC&cTop=" +
                self.cTop;

            axios
                .get(api)
                .then((response) => {
                    self.billeds = response.data.data.ZOOM;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getInDelivery() {
            const self = this;
            const api =
                this.$store.state.api +
                "deliveries/?page=1&status=pending&order_by=C5_EMISSAO&order_type=DESC&cTop=" +
                self.cTop;

            axios
                .get(api)
                .then((response) => {
                    self.in_delivery = response.data.data.ZOOM;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getDelivered() {
            const self = this;
            const api =
                this.$store.state.api +
                "deliveries/?page=1&status=delivered&order_by=C5_EMISSAO&order_type=DESC&cTop=" +
                self.cTop;

            axios
                .get(api)
                .then((response) => {
                    self.delivered = response.data.data.ZOOM;
                    self.loading = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },

        danfe(nf, filial) {
            const self = this;

            var data = "";
            if (nf.trim() && filial.trim()) {
                data = btoa(
                    JSON.stringify({
                        nota_fiscal: nf.trim(),
                        filial: filial.trim(),
                    })
                );
                this.$router.push("/admin/invoice/danfe/" + data);
            } else {
                alert("DANFE não disponível para esse título");
            }
        },

        canhoto(data) {
        
            data = btoa(
                JSON.stringify({
                    F2_CHVNFE: data.F2_CHVNFE,
                })
            );

            this.$router.push("/admin/delivery/canhoto/" + data);
        
        },

        tracking(data) {
                                           
            data = btoa(
                JSON.stringify({
                    notafiscal: data.DAI_NFISCA,
                    placa: data.DA3_PLACA,
                    placaCav: data.DAK_VEIC2,
                    transportadora: data.A4_NOME,
                })
                );
                

            this.$router.push("/admin/delivery/tracking/" + data);
            
        },

        getPaymentMethod() {
            const self = this;
            const api = this.$store.state.api + "invoices/payment-method";

            axios
                .post(api)
                .then((response) => {
                    self.payment_method = response.data.ZOOM[0]["A1_XTIPDOC"];
                })
                .catch((error) => {
                    this.$toast.error("Erro na API");
                });
        },
    },
    mounted() {
        this.getPaymentMethod();
        this.getUnbilled();
        this.getBilled();
        this.getInDelivery();
        this.getDelivered();
    },
};
</script>
<style scoped>

.card-body {
    border-radius: 8px;
    padding: 15px;
    margin: 15px;
    border: 1px #eaeaea solid;
    background-color: #f9f9f9;
    font-size: 14px;
}

.card-header {
    font-weight: bold;
    font-size: 16px;
    padding: .875rem 0.875rem 0.875rem 
}

.card-title {    
    font-size: 16px;
}

.card {
    border: 1px #eaeaea solid !important;
    font-size: 14px;
}

p {
    margin-bottom: 0px !important;
    line-height: 1.5;
}

.btn-kanban {
    border: 2px solid #a6a6a6;
    height: 40px;
    width: 40px;
    margin-top: 15px;
    margin-right: 15px;
    padding: 6px;
}

.btn-kanban:hover {
    background: #a6a6a6;
    color: black !important;
}
</style>
