import { useToast } from "vue-toastification";

export function ValidateForm(id) {

    const form = document.getElementById(id);
    form.classList.add('was-validated');
    const inputs = form.getElementsByClassName('form-control');
    const verify = notifyForm(inputs)

    if (!verify) {
        toastError('Preencha todos os campos obrigatórios!');
        return false;
    }

    return true;
}

export function notifyForm(obj) {
    for (let i = 0; i < obj.length; i++) {
        if (obj[i].value === '' &&
            (obj[i].getAttribute('required') === '' || obj[i].getAttribute('required') === true)) {
            return false;
        }
    }

    return true;
}

export function toastError(message) {
    const toast = useToast();
    toast.error(message, {
        timeout: 2000
    });

    return { toast }
}

export function toastSuccess(message) {
    const toast = useToast();
    toast.success(message, {
        timeout: 2000
    });

    return { toast }
}