<template>
  <div class="container-fluid">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <h5>Visualizando o body da Rotina: {{ time_logs.routine }}</h5>
        </div>
        <br />
        <hr />
        <div class="col-12">
          <div class="form-group">
            <div class="col-12">
              <strong>Body:</strong> <br />
              {{ time_logs.body }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      time_logs: {
        body: "",
        routine: "",
      },
    };
  },
  computed: {},
  methods: {
    getBody() {
      let self = this;
      const api =
        self.$store.state.api + "time_logs/" + self.$route.params.params;

      axios
        .get(api)
        .then((response) => {
          self.time_logs = response.data.data[0];
        })
        .catch((error) => {
          // vm.$toast.error(error.response.data.message);
        });
    },
  },
  mounted() {
    this.getBody();
  },
};
</script>