const env = {
    url: 'https://api.bauminas-hml.innovareti.com.br/',
    api: 'https://api.bauminas-hml.innovareti.com.br/api/v1/',
    applicationName: 'BAUMINAS',
    applicationFooter: 'BAUMINAS',
    chartBarBackgroundColor: '#2a4a80'
	
}

export default env
