import {createStore} from 'vuex'
import env from './env'
import Router from './router.js'

const getDefaultState = () => {
    return {
        token: null,
        token_expire: null,
        maintenance: false,
        queryHistory: {},
        searchHistory: {},
        user: {
            photo: '',
            access: []
        },
        userCompanies: [],
        columns: [],
        url: env.url,
        api: env.api,
        chartsAmountMonthLoaded: false,
        order_report: {}
    }
}

const state = getDefaultState()


const mutations = {
    setToken(state, token, token_expire) {
        state.token = token
        state.token_expire = token_expire
    },
    setQueryHistory(state, queryHistory) {
        state.queryHistory = queryHistory
    },
    setLoadingChartAmount(state, chartsAmountMonthLoaded) {
        state.chartsAmountMonthLoaded = chartsAmountMonthLoaded
    },
    setSearchHistory(state, searchHistory) {
        state.searchHistory = searchHistory
    },
    setUser(state, status) {
        state.user = status.user
        state.columns = status.columns
        state.userCompanies = status.customerControl
    },
    setColumns(state, columns) {
        state.columns = columns
    },
    setMaintenance(state, user) {
        state.maintenance = user
    },
    setOrderReport(state, report) {
        state.order_report = report
    },
    reset(state) {
        state = getDefaultState()
    }
}

const actions = {
    setToken({commit}, token, token_expire) {
        localStorage.setItem('token', token)
        localStorage.setItem('token_expire', token_expire)
        axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token')
        commit('setToken', token, token_expire)
        this.dispatch('initialize')
        Router.push('/admin');
    },
    setQueryHistory({commit}, queryHistory) {
        commit('setQueryHistory', queryHistory)
    },
    setLoadingChartAmount({commit}, queryHistory) {
        commit('setLoadingChartAmount', queryHistory)
    },
    setSearchHistory({commit}, searchHistory) {
        commit('setSearchHistory', searchHistory)
    },
    setMaintenance({commit}, maintenance) {
        commit('setMaintenance', maintenance)
    },
    setOrderReport({commit}, report) {
        commit('setOrderReport', report)
    },
    setColumns({commit}, columns) {
        commit('setColumns', columns)
    },
    logout({commit}) {
        axios
            .post(this.getters.api + 'logout')
            .then(() => {
                localStorage.clear();
                commit('setToken', null)
                Router.push("/login");
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            })
            .catch(error => {
                // this.$message("Erro", "Erro ao realizar logout", "error");
            });
    },
    initialize({commit}) {
        const self = this

        const token = localStorage.getItem('token')
        const token_expire = localStorage.getItem('token_expire')

        if (!token) {
            return this.dispatch('reset')
        }

        if (!this.getters.token) {
            commit('setToken', token, token_expire)
        }

        axios.post(this.getters.api + 'me', {
                token: token
            })
            .then(response => {
                commit('setUser', response.data)
                commit('setMaintenance', response.data.user.maintenance);
                setTimeout(() => {
                    if (response.data.type_id != 4 && response.data.user.maintenance == true) {
                        Router.push("/in_maintenance");
                    }
                }, 1000);

                if (response.data.terms_of_use > 0 && response.data.user.terms_of_use == 0) {
                    Router.push("/terms_of_use");
                } else {
                    localStorage.setItem('token_expire', response.data.token_expires_at);
                }


            })
            .catch(error => {
                localStorage.clear();
                Router.push('/login');
            });
    },
    reset({commit}) {
        localStorage.clear()
        commit('reset')
    }
}

const getters = {
    api: state => state.api,
    user: state => state.user,
    token: state => state.token,
    token_expire: state => state.token_expires_at,
    queryHistory: state => state.queryHistory,
    searchHistory: state => state.searchHistory,
    userCompanies: state => state.userCompanies
}

const Store = createStore({
    state,
    getters,
    actions,
    mutations
})

// //Se não for ambiente de dev, derrubar o token toda vez que fechar a aba/navegador ou dar F5 na tela
// if (env.url === 'https://api.bauminas.innovareti.com.br/') {

//     window.addEventListener('beforeunload', localStorage.removeItem('token'))

// }

export default Store
