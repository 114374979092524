<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div :class="'card-header '" :style="styleHeader">
                    <!-- <h5 class="card-title">{{ crudName }}</h5> -->

                    <router-link v-if="enableAdd" :to="endPoint + 'add'" class="btn btn-success white float-right">
                        <font-awesome-icon :icon="['fa', 'plus']" />
                        {{ newText }}
                    </router-link>
                </div>
                <div class="card-body" v-if="loadingFilters">
                    <template v-if="filtersRadio && filtersRadio.length > 0">
                        <div class="form-check form-check-inline" v-for="(filterRadio, index) in filtersRadio"
                            :key="'filterRadio-' + index">
                            <input class="form-check-input" type="radio" :name="filterRadio.name" :id="filterRadio.id"
                                :checked="filterRadio.checked" @change="checkFilter(filterRadio)" :disabled="loading" />
                            <label class="form-check-label" for="">{{
                                filterRadio.label
                                }}</label>
                        </div>
                    </template>

                    <div style="margin-left: -23px" v-if="orderList && orderList.length > 0"
                        class="form-check form-check-inline col-8">
                        <label style="margin-left: 20px; margin-right: 5px">Ordenar Por:</label>
                        <select class="form-select col-4" @input="changeOrderField($event)" :disabled="loading">
                            <template v-for="(orderOption, index) in orderList" :key="'order-' + index">
                                <option :value="orderOption.column" v-if="orderOption.selected" selected>
                                    {{ orderOption.name }}
                                </option>
                                <option :value="orderOption.column" v-else>
                                    {{ orderOption.name }}
                                </option>
                            </template>
                        </select>
                        <div style="padding-left: 10px">
                            <label> Crescente</label>
                            <input class="form-check-input" style="margin-left: 5px" type="radio" name="order" id="ASC"
                                value="ASC" @change="changeOrder('ASC')" selected :disabled="loading" />
                        </div>
                        <div style="padding-left: 10px">
                            <label> Decrescente</label>
                            <input class="form-check-input" style="margin-left: 5px" type="radio" name="order" id="DESC"
                                value="DESC" @change="changeOrder('DESC')" :disabled="loading" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <form @submit.prevent>
                            <div class="row" v-if="filtersText && filtersText.length > 0">
                                <template v-for="(filterContract, index) in filtersContract"
                                    :key="'filtersContract-' + index">
                                    <div :class="filterContract.columnSize" v-if="contracts.length > 0">
                                        <label for="">{{ filterContract.label }}</label>
                                        <select class="form-select" @change="changeFilter(filterContract.name, $event)"
                                            :disabled="loading" :id="'contract' + index">
                                            <option v-for="contract in contracts" :key="contract" :value="contract">
                                                {{ contract }}
                                            </option>
                                        </select>
                                    </div>
                                </template>
                                <template v-for="(filterText, index) in filtersText" :key="'filtersText-' + index">
                                    <div :class="filterText.columnSize">
                                        <label for="">{{ filterText.label }}</label>
                                        <input type="text" :id="'text' + index" :class="filterText.class"
                                            @change="changeFilter(filterText.name, $event)" v-on:keyup.enter="
                                                changeFilter(filterText.name, $event), get_data()
                                                " :disabled="loading" />
                                    </div>
                                </template>
                                <template v-for="(filterProduct, index) in filtersProduct"
                                    :key="'filtersProduct-' + index">
                                    <div :class="filterProduct.columnSize" v-if="products.length > 0">
                                        <label for="">{{ filterProduct.label }}</label>
                                        <select class="form-select" @change="changeFilter(filterProduct.name, $event)"
                                            :disabled="loading" :id="'product' + index">
                                            <option v-for="prod in products" :key="prod.trim()" :value="prod.trim()">
                                                {{ prod.trim() }}
                                            </option>
                                        </select>
                                    </div>
                                </template>
                                <template v-for="(filterBranch, index) in filtersBranch"
                                    :key="'filtersBranch-' + index">
                                    <div :class="filterBranch.columnSize" v-if="branches.length > 0">
                                        <label for="">{{ filterBranch.label }}</label>
                                        <select class="form-select" @change="changeFilter(filterBranch.name, $event)"
                                            :disabled="loading" :id="'branch' + index">
                                            <option v-for="branch in branches" :key="branch" :value="branch.filial">
                                                {{ branch.nomefil }}
                                            </option>
                                        </select>
                                    </div>
                                </template>
                                <template v-for="(filterType, index) in filtersUsersType" :key="'filterType-' + index">
                                    <div :class="filterType.columnSize">
                                        <label for="">{{ filterType.label }}</label>
                                        <select class="form-select" @change="changeFilter(filterType.name, $event)">
                                            <option value=""></option>
                                            <option value="4">Administrador Master</option>
                                            <option value="1">Administrador</option>
                                            <option value="2">Cliente</option>
                                            <option value="3">Usuário Interno</option>
                                        </select>
                                    </div>
                                </template>
                                <template v-if="filtersDate && filtersDate.length > 0">
                                    <template v-for="(filterDate, index) in filtersDate" :key="'filtersDate-' + index">
                                        <div :class="filterDate.columnSize">
                                            <label for="">{{ filterDate.label }}</label>
                                            <input type="text" :id="'date' + index" v-maska="'##/##/####'"
                                                :class="filterDate.class"
                                                @change="changeFilter(filterDate.name, $event)" v-on:keyup.enter="
                                                    changeFilter(filterDate.name, $event), get_data()
                                                    " :disabled="loading" />
                                        </div>
                                    </template>
                                </template>
                                <template v-if="filtersSelectApi && filtersSelectApi.length > 0">
                                    <template v-for="(select, index) in selApi" :key="'filtersSelectApi-' + index">
                                        <div :class="select.element.columnSize">
                                            <label for="" v-if="vSelect">{{
                                                select.element.label
                                                }}</label>
                                            <v-select style="z-index: 999999999 !important" v-if="vSelect"
                                                :clearable="false" :reduce="(option) => option[select.element.propertyValue]
                                                    " :id="'select' + index" :label="[select.element.propertyName]" :options="select.data"
                                                @option:selected="
                                                    changeFilterSelect(
                                                        select.element.name,
                                                        $event,
                                                        select.element.propertyValue
                                                    )
                                                    ">
                                            </v-select>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <button v-if="filtersText.length > 0" class="btn btn-primary col-1 mt-3"
                                @click="buttonSearch(), get_data()" :disabled="loading">
                                Buscar
                            </button>
                            <button v-if="filtersText.length > 0" class="btn btn-dark col-2 ml-2 mt-3"
                                @click="clearFilters()" :disabled="loading">
                                Limpar Filtros
                            </button>
                        </form>
                    </div>
                </div>
                <div class="card-body text-center" v-if="loading">
                    <span class="fa fa-spinner fa-spin fa-5x"></span>
                    <div class="row" v-if="show_retry_button">
                        <div class="col-md-12 mt-4" style="font-size: 20px; font-weight: bold">
                            Ops! Tivemos um problema por aqui.
                        </div>
                        <div class="col-md-12 mt-2">
                            <button class="btn btn-primary" @click="get_data(1)">
                                Tentar Novamente
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <div class="row mb-3">
                        <div class="col-12 text-end">

                            <button v-if="enableSaveColumns && configColumnsLoaded" class="btn btn-info white mx-1"
                                @click="openModalColumns">
                                <font-awesome-icon :icon="['fa', 'cog']" />
                                Ajustar colunas

                            </button>
                            <button v-if="enableExtract" class="btn btn-primary white mx-1" @click="openModalExtract"
                                :disabled="extracting">
                                <i class="fa-solid fa-file-spreadsheet"></i>
                                <font-awesome-icon :icon="['fa', 'file-excel']" />
                                Gerar
                                Relatório
                            </button>
                        </div>
                        <div class="col-12">
                            <span class=" badge-pill badge-danger pull-right mt-2" v-if="other_columns.length > 0">Você
                                possui colunas
                                ocultas...</span>
                        </div>
                    </div>

                    <div class="table-responsive TableCSS Container Flipped">
                        <table class="table table-bordered table-responsive-sm Content" v-if="showTable">
                            <thead>
                                <tr>
                                    <th class="centered" style="vertical-align: middle"
                                        v-for="(column, index) in local_columns" :key="'column-' + index">
                                        <strong>
                                            {{ column.label }}
                                            <!-- <font-awesome-icon
                                            v-show="ordered_index == index && ordered_asc == true"
                                            :icon="['fa', 'chevron-up']"
                                        />
                                        <font-awesome-icon
                                            v-show="ordered_index == index && ordered_asc == false"
                                            :icon="['fa', 'chevron-down']"
                                        /> -->
                                        </strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="dataList && dataList.length > 0">
                                <tr v-for="(row, index) in dataList" :key="'row-' + index" class="centered">
                                    <td v-for="(column, indexCol) in local_columns" :key="'column-index-' + indexCol">
                                        <div v-if="column.type && column.type == 'button'">
                                            <div v-if="column.conditionShow(row)">
                                                <button :disabled="column.condition(row)"
                                                    v-show="column.conditionShow(row)" :class="column.class"
                                                    @click="buttonCallback(column, row)">
                                                    {{ column.label }}
                                                </button>
                                            </div>
                                            <div v-else>N/A</div>
                                        </div>

                                        <div v-if="column.type && column.type == 'buttonLabel'">
                                            <div v-if="column.conditionShow(row)">
                                                <button :disabled="column.condition(row)"
                                                    v-show="column.conditionShow(row)" :class="column.labelCallback(row) ==
                                                        'Entrega OK / Gerar Canhoto'
                                                        ? 'btn btn-success'
                                                        : column.labelCallback(row) ==
                                                            'Rastreamento Indisponível'
                                                            ? 'btn btn-black'
                                                            : 'btn btn-info'
                                                        " @click="buttonCallback(column, row)">
                                                    {{ column.labelCallback(row) }}
                                                </button>
                                            </div>
                                            <div v-else>N/A</div>
                                        </div>
                                        <div v-if="column.type && column.type == 'payment_method'">
                                            <div v-if="column.conditionShow(row)">

                                                <div :disabled="column.condition(row)"
                                                    v-show="column.conditionShow(row)" :class="column.labelCallback(row) ==
                                                        'Depósito Bancário'
                                                        ? '' : 'btn btn-info'

                                                        " @click="buttonCallback(column, row)">
                                                    {{ column.labelCallback(row) }}
                                                </div>
                                            </div>
                                            <div v-else>N/A</div>
                                        </div>
                                        <div v-else-if="column.type && column.type == 'fieldClickable'">
                                            <span v-html="get_field_value(column, row)"
                                                @click="buttonCallback(column, row)" class="clickable"></span>
                                        </div>
                                        <div v-else
                                            :style="{ width: column.label === 'Filial' || column.label === 'Produto' ? '150px !important' : '' }">
                                            <span v-if="column.whitespace" v-html="get_field_value(column, row)">
                                            </span>

                                            <span v-else v-html="get_field_value(column, row)">
                                            </span>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr class="centered">
                                    <td :colspan="local_columns.length">
                                        Nenhum registro encontrado.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-2 mt-2">
                            <span>Mostrar:</span>
                        </div>
                        <div class="col-md-2" style="margin-left: -100px">
                            <select id="show_registers" class="form-select" style="width: 85px"
                                @change="setCtop($event)">
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="25" selected>25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div class="col-md-2 mt-2" style="margin-left: -65px">
                            registros na tabela
                        </div>
                    </div>
                    <nav aria-label="" class="mt-3">
                        <label>Página: {{ currentPage + 1 }}/</label>
                        <label v-if="lastPage > 0">{{ lastPage }}</label>
                        <label v-if="lastPage <= 0">{{ lastPage + 1 }}</label>
                        <ul class="pagination">
                            <i class="fa-solid fa-arrow-right-to-bracket"></i>
                            <li class="page-item">
                                <button @click="get_data(firstPage)" class="page-link" href="#">
                                    <span class="fa fa-arrow-left" style="color: grey"></span>
                                </button>
                            </li>
                            <li class="page-item" v-if="currentPage > firstPage">
                                <button @click="get_data(currentPage)" class="page-link" href="#">
                                    <span class="fa fa-chevron-left" style="color: grey"></span>
                                </button>
                            </li>

                            <li class="page-item" v-if="currentPage > firstPage">
                                <button @click="get_data(currentPage - 1)" class="page-link" href="#">
                                    {{ currentPage - 1 }}
                                </button>
                            </li>

                            <li class="page-item" v-if="currentPage >= firstPage">
                                <button @click="get_data(currentPage)" class="page-link" href="#">
                                    {{ currentPage }}
                                </button>
                            </li>

                            <li class="page-item active">
                                <button class="page-link" href="#">
                                    {{ currentPage + 1 }}
                                </button>
                            </li>

                            <li class="page-item" v-show="currentPage + 1 < lastPage">
                                <button @click="get_data(currentPage + 2)" class="page-link" href="#">
                                    {{ currentPage + 2 }}
                                </button>
                            </li>
                            <li class="page-item" v-if="currentPage + 2 < lastPage">
                                <button @click="get_data(currentPage + 3)" class="page-link" href="#">
                                    {{ currentPage + 3 }}
                                </button>
                            </li>

                            <li class="page-item" v-if="currentPage + 1 < lastPage">
                                <button @click="get_data(currentPage + 2)" class="page-link" href="#">
                                    <span class="fa fa-chevron-right" style="color: grey"></span>
                                </button>
                            </li>
                            <li class="page-item">
                                <button @click="get_data(lastPage)" class="page-link" href="#">
                                    <span class="fa fa-arrow-right" style="color: grey"></span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <!-- Modal Produto -->

        <div id="modalColumns" v-if="configColumnsLoaded">
            <div class="modal-fade-background"></div>
            <div :class="['modal fade show']" id="modal-pending" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="modalColumns">
                            <div class="row">
                                <div class="col-md-6 bold">Ajustar Colunas</div>
                                <div class="col-md-6">
                                    <button @click="closeModalColumns()" style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    " class="btn btn-danger float-right">
                                        X
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    <div class="alert alert-primary" role="alert">
                                        Aqui você pode ocultar e organizar as colunas que serão
                                        exibidas na tabela abaixo, vale lembrar que estas
                                        configurações ficarão salvas em seu login.
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="showTable">
                                    <div class="centered bold fs-16">Exibir</div>
                                    <draggable class="dragArea list-group w-full "
                                        v-model="local_columns_" ghost-class="ghost" :move="checkMove"
                                        group="collumns"
                                        @change="handleAddToOtherColumns">

                                        <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center bordered"
                                            v-for="element in local_columns_" :key="element.label">
                                            <svg data-v-0469d702="" data-v-2fc82866-s="" data-v-2fb1486c-s="" class="svg-inline--fa fa-arrows-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="arrows-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M352.201 425.775l-79.196 79.196c-9.373 9.373-24.568 9.373-33.941 0l-79.196-79.196c-15.119-15.119-4.411-40.971 16.971-40.97h51.162L228 284H127.196v51.162c0 21.382-25.851 32.09-40.971 16.971L7.029 272.937c-9.373-9.373-9.373-24.569 0-33.941L86.225 159.8c15.119-15.119 40.971-4.411 40.971 16.971V228H228V127.196h-51.23c-21.382 0-32.09-25.851-16.971-40.971l79.196-79.196c9.373-9.373 24.568-9.373 33.941 0l79.196 79.196c15.119 15.119 4.411 40.971-16.971 40.971h-51.162V228h100.804v-51.162c0-21.382 25.851-32.09 40.97-16.971l79.196 79.196c9.373 9.373 9.373 24.569 0 33.941L425.773 352.2c-15.119 15.119-40.971 4.411-40.97-16.971V284H284v100.804h51.23c21.382 0 32.09 25.851 16.971 40.971z"></path></svg>
                                            {{ element.label }}
                                        </div>

                                    </draggable>
                                </div>
                                <div class="col-md-6" v-if="showTable">
                                    <div class="centered bold fs-16">Não Exibir</div>
                                    <draggable class="dragArea list-group w-full "
                                        v-model="other_columns_" ghost-class="ghost" :move="checkMove"
                                        group="collumns"
                                        @change="handleRemoveFromOtherColumns">
                                        <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center bordered"
                                            v-for="element in other_columns_" :key="element.label">
                                            <svg data-v-0469d702="" data-v-2fc82866-s="" data-v-2fb1486c-s="" class="svg-inline--fa fa-arrows-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="arrows-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M352.201 425.775l-79.196 79.196c-9.373 9.373-24.568 9.373-33.941 0l-79.196-79.196c-15.119-15.119-4.411-40.971 16.971-40.97h51.162L228 284H127.196v51.162c0 21.382-25.851 32.09-40.971 16.971L7.029 272.937c-9.373-9.373-9.373-24.569 0-33.941L86.225 159.8c15.119-15.119 40.971-4.411 40.971 16.971V228H228V127.196h-51.23c-21.382 0-32.09-25.851-16.971-40.971l79.196-79.196c9.373-9.373 24.568-9.373 33.941 0l79.196 79.196c15.119 15.119 4.411 40.971-16.971 40.971h-51.162V228h100.804v-51.162c0-21.382 25.851-32.09 40.97-16.971l79.196 79.196c9.373 9.373 9.373 24.569 0 33.941L425.773 352.2c-15.119 15.119-40.971 4.411-40.97-16.971V284H284v100.804h51.23c21.382 0 32.09 25.851 16.971 40.971z"></path></svg>
                                            {{ element.label }}
                                        </div>

                                    </draggable>
                                </div>

                            </div>
                            <div class="row mt-5">
                                <div class="col text-end">
                                    <button class="btn btn-info m-1" @click="confirmReset">
                                        Resetar
                                    </button>
                                    <button class="btn btn-danger m-1" @click="closeModalColumns">
                                        Fechar
                                    </button>
                                    <button class="btn btn-success m-1" @click="saveColumns()">
                                        Salvar Colunas
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
// import Draggable from "vue3-draggable";
import { VueDraggableNext } from 'vue-draggable-next'
import Toggle from "@vueform/toggle";

export default {
    data() {
        return {
            showTable: true,
            configColumnsLoaded: false,
            dragOptions: {
                group: "columns",
            },
            local_columns: [],
            other_columns: [],
            local_columns_: [],
            other_columns_: [],
            extract_data: {
                email: "",
            },
            simNao: {
                onLabel: "SIM",
                offLabel: "NÃO",
            },
            filters_new: [],
            products: [],
            contracts: [],
            branches: [],
            buttonSearchClicked: false,
            show_retry_button: false,
            selArray: [],
            selApi: {},
            selectedOrder: "",
            orderType: "DESC",
            data: [],
            ordered_index: "",
            ordered_by: "",
            extracting: false,
            ordered_asc: true,
            currentPage: 0,
            firstPage: 1,
            lastPage: 0,
            count: 0,
            loading: true,
            loadingFilters: true,
            requesting: false,
            vSelect: true,
            clearFiltersTmp: [],
            cTop: 25,
            filters_copy: [],
        };
    },
    components: { vSelect, draggable: VueDraggableNext, Toggle },
    computed: {
        orderList() {
            return this.orders;
        },
        dataList() {
            return this.data;
        },
        filtersRadio() {
            return this.filters_copy.filter(function (item) {
                return item.type == "radio";
            });
        },
        filtersText() {
            return this.filters_copy.filter(function (item) {
                return item.type == "text";
            });
        },
        filtersDate() {
            return this.filters_copy.filter(function (item) {
                return item.type == "date";
            });
        },
        filtersSelectApi() {
            return this.filters_copy.filter(function (item) {
                return item.type == "selectApi";
            });
        },
        filtersContract() {
            return this.filters_copy.filter(function (item) {
                return item.type == "contract";
            });
        },
        filtersProduct() {
            return this.filters_copy.filter(function (item) {
                return item.type == "product";
            });
        },
        filtersBranch() {
            return this.filters_copy.filter(function (item) {
                return item.type == "branch";
            });
        },
        filtersUsersType() {
            return this.filters_copy.filter(function (item) {
                return item.type == "user_type";
            });
        },
    },
    props: {
        enableExtract: Boolean,
        ref: String,
        filters: Array,
        crudName: String,
        styleHeader: String,
        newText: String,
        table: String,
        endPoint: String,
        enableAdd: Boolean,
        enableEdit: Boolean,
        enableDelete: Boolean,
        layoutNames: Array,
        relationships: Array,
        extras: Array,
        orders: Array,
        columns: Array,
        enableSaveColumns: {
            type: Boolean,
            default: false,
        },
        increments: {
            type: String,
            default: "",
        },
    },
    methods: {
        confirmReset() {
            this.$swal
                .fire({
                    title: "Tem certeza fazer isto?",
                    text: "As configurações de colunas retornarão ao padrão inicial.",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Cancelar",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.resetColumns(this.$router.currentRoute.value.path);
                    }
                });
        },
        resetColumns() {
            this.showTable = false
            console.log(this.$store.state.columns)
            const self = this;
            const api = this.$store.state.api + "users/delete_columns";

            self.loading = true;
            const endpoint = self.$router.currentRoute.value.path;

            var obj = {
                endpoint: endpoint,
            };
            axios
                .post(api, obj)
                .then((response) => {
                    self.loading = false;
                    self.$toast.success("A ordem das colunas resetada.");
                    // self.get_data(1);
                    window.location = window.location;

                    self.local_columns = self.local_columns_ = self.columns;
                    self.other_columns = [];
                    self.other_columns_ = [];

                    console.log(self.$store.state.columns)
                    delete self.$store.state.columns[endpoint];

                    console.log(self.$store.state.columns)

                })
                .then(() => {
                    self.getColumns()
                    self.closeModalColumns()
                    self.showTable = true
                })
                .catch((error) => {
                    self.$toast.error("Erro na API");
                    self.loading = false;
                    self.showTable = true
                });
        },
        saveColumns() {
            const self = this;
            const api = this.$store.state.api + "users/save_columns";
            this.showTable = false

            self.loading = true;

            var obj = {
                show: JSON.stringify(self.local_columns_),
                no_show: JSON.stringify(self.other_columns_),
                endpoint: self.$router.currentRoute.value.path,
            };
            self.closeModalColumns();

            axios
                .post(api, obj)
                .then((response) => {
                    self.loading = false;
                    self.$toast.success("A ordem das colunas foi salva.");
                    // self.get_data(1);
                    window.location = window.location;

                    setTimeout(function () {
                        self.local_columns = self.local_columns_;
                        self.other_columns = self.other_columns_;
                        // window.location = window.location;
                        self.showTable = true
                    }, 2000);

                })
                .catch((error) => {
                    self.$toast.error("Erro na API");
                    self.loading = false;
                    self.showTable = true
                });
        },
        getColumns() {
            const self = this;
            self.configColumnsLoaded = false;
            const endpoint = self.$router.currentRoute.value.path;
            self.local_columns_ = self.local_columns;
            self.other_columns_ = self.other_columns;
            self.loading = false;

            console.log('getcolumns: ' + this.$store.state.columns[endpoint])
            if (this.$store.state.columns[endpoint]) {

                var no_show = JSON.parse(this.$store.state.columns[endpoint].no_show);
                var show = JSON.parse(this.$store.state.columns[endpoint].show);

                console.log(no_show)
                console.log(show)

                no_show.forEach((element) => {
                    self.local_columns.forEach((local, index) => {
                        if (local.label == element.label) {
                            self.other_columns.push(local);
                            self.local_columns.splice(index, 1);
                        }
                    });
                });
                var new_order = [];
                show.forEach((element, index) => {
                    self.local_columns.forEach((local, index) => {
                        console.log('entrou no terceiro if')
                        if (local.label == element.label) {
                            new_order.push(local);
                        }
                    });
                });

                self.local_columns = self.local_columns_ = new_order;
                self.other_columns_ = self.other_columns;
            }
            self.configColumnsLoaded = true;
        },
        checkMove(event) {
            console.log('checkMove', event.draggedContext)
            console.log('Future index: ' + event.draggedContext.futureIndex)
        },
        handleAddToOtherColumns(event) {
            const { moved, added } = event

            if (moved) console.log('moved', moved)
            if (added) console.log('added', added, added.element)


            // debugger
            // this.other_columns_.push(event.moved.element);
            // this.local_columns_.splice(event.moved.oldIndex, 1);
        },
        handleRemoveFromOtherColumns(event) {
            const { moved, added } = event

            if (moved) console.log('moved', moved)
            if (added) console.log('added', added, added.element)
            // debugger
            // this.local_columns_.push(event.moved.element);
            // this.other_columns_.splice(event.moved.oldIndex, 1);
        },
        openModalColumns() {
            const self = this;

            show_modal("modalColumns");
        },
        closeModalColumns() {
            close_modal("modalColumns");
        },
        openModalExtract() {
            this.$swal
                .fire({
                    title: "Atenção!",
                    text: " Este relatório pode levar alguns minutos para ficar pronto, assim que o processo for encerrado será enviado o arquivo ao seu e-mail de cadastro, deseja continuar?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Cancelar",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.extract();
                    }
                });
        },
        setCtop(value) {
            const self = this;
            this.cTop = value.target.value;

            setTimeout(function () {
                $("#show_registers").val(self.cTop).change();
            }, 2000);
            this.get_data(1);
        },
        buttonSearch() {
            this.buttonSearchClicked = true;
        },
        changeFilterSelect(field, value, column) {
            this.filters_copy.forEach(function (filterText) {
                if (filterText.name == field) {
                    filterText.value = value[column];
                }
            });
            window.localStorage.setItem('filters_' + this.crudName, JSON.stringify(this.filters_copy))
        },
        clearFilters() {

            window.localStorage.removeItem('filters');

            const self = this;
            self.vSelect = false;
            var i = 0;
            this.filtersText.forEach((el) => {
                $("#text" + i).val("");
                i++;
            });

            var j = 0;
            this.filtersDate.forEach((el) => {
                $("#date" + j).val("");
                j++;
            });

            var j = 0;
            this.filtersProduct.forEach((el) => {
                $("#product" + j).val("");
                j++;
            });

            var j = 0;
            this.filtersBranch.forEach((el) => {
                setTimeout(function () {
                    $("#branch0").val("");
                }, 3000);
                j++;
            });

            var j = 0;
            this.filtersContract.forEach((el) => {
                $("#contract" + j).val("");
                j++;
            });

            var k = 0;
            this.selArray.forEach((el) => {
                el = "";
            });

            this.filtersRadio.forEach((el) => {
                if (el.type != 'hidden') {
                    el.checked = false;
                }
            });

            var i = 0;
            this.filters_copy.forEach((el) => {
                if (el.type == "radio" && i == 0) {
                    el.checked = true;
                }

                if (el.type != "radio") {
                    el.value = "";
                }

                i++;
            });
            self.$store.dispatch("setQueryHistory", {
                route: null,
                query: null,
            });
            self.$store.dispatch("setSearchHistory", { have: false, filters: {} });
            self.filters_copy = JSON.parse(JSON.stringify(self.filters));
            this.get_data();
        },
        changeFilter(field, value) {
            this.filters_copy.forEach(function (filterText) {
                if (filterText.name == field) {
                    filterText.value = value.target.value;
                }
            });
            window.localStorage.setItem('filters_' + this.crudName, JSON.stringify(this.filters_copy))
        },
        changeOrderField(field) {
            this.selectedOrder = field.target.value;
        },
        changeOrder(value) {
            this.orderType = value;
        },
        buttonCallback(column, row) {
            this.$emit(column.callbackFunction, row);
        },
        checkFilter(checkedFilter) {
            this.filtersRadio.forEach(function (filter) {
                filter.checked = false;
            });

            checkedFilter.checked = true;

            this.get_data(1);
        },
        async get_data(page = 1) {
            const self = this;

            await new Promise((r) => setTimeout(r, 500));

            self.show_retry_button = false;

            setTimeout(function () {
                self.show_retry_button = true;
            }, 30000);

            if (
                self.$store.state.queryHistory.query !== null &&
                self.$route.name === self.$store.state.queryHistory.route
            ) {
            } else {
                self.$store.dispatch("setSearchHistory", {
                    have: false,
                    filters: {},
                });
            }

            if (self.requesting == false) {
                self.data = [];

                self.loading = true;
                self.requesting = true;
                var pg = page;

                if (self.lastPage > 0) {
                    if (pg > self.lastPage) {
                        self.loading = false;
                        return;
                    }
                    if (pg < self.firstPage) {
                        self.loading = false;
                        return;
                    }
                }

                var haveFilters = false;

                var query = "";

                if (self.$store.state.searchHistory.have) {
                    //Aqui devolve aos filtros o valor de cada um recuperado da store

                    setTimeout(function () {
                        // setar um timeout para dar tempo de carregar os elementos
                        var history_filters = JSON.parse(
                            JSON.stringify(self.$store.state.searchHistory.filters)
                        );

                        console.log("historico de filtros", history_filters);

                        //Text
                        var t = 0;
                        history_filters
                            .filter(function (item) {
                                return item.type == "text";
                            })
                            .forEach((element) => {
                                $("#text" + t).val(element.value);
                                t++;
                            });

                        //date
                        var d = 0;
                        history_filters
                            .filter(function (item) {
                                return item.type == "date";
                            })
                            .forEach((element) => {
                                $("#date" + d).val(element.value);
                                d++;
                            });

                        //product
                        var p = 0;
                        history_filters
                            .filter(function (item) {
                                return item.type == "product";
                            })
                            .forEach((element) => {
                                $("#product" + p).val(element.value);
                                p++;
                            });

                        //select
                        setTimeout(function () {
                            var s = 0;
                            history_filters
                                .filter(function (item) {
                                    return item.type == "selectApi";
                                })
                                .forEach((element) => {
                                    if (element.value != "" && element.value != undefined) {
                                        self.filtersSelectApi[s].value = element.value;
                                    }
                                    s++;
                                });
                        }, 5000);
                    }, 1000);
                }

                //monta os primeiros filtros ao abrir a tela
                self.filters_copy.forEach((filter) => {
                    if (filter.type == "radio" && filter.checked) {
                        query += "&" + filter.name + "=" + filter.value;

                        console.log("query", query);
                    } else if (
                        filter.type == "date" &&
                        filter.value != "" &&
                        filter.value != undefined
                    ) {
                        var vle = moment(filter.value, "DD/MM/YYYY").format("YYYY-MM-DD");

                        if (
                            filter.name == "created_at" &&
                            [
                                "users/",
                                "customers/",
                                "time_logs/",
                                "licitations/",
                                "orders-not-integrateds/",
                            ].includes(self.endPoint)
                        ) {
                            vle = vle + " 00:00:00," + vle + " 23:59:59";
                        }

                        haveFilters = true;

                        query += "&" + filter.name + "=" + vle;
                    } else if (
                        filter.type != "radio" &&
                        filter.value != "" &&
                        filter.value != undefined
                    ) {
                        haveFilters = true;

                        query += "&" + filter.name + "=" + filter.value;
                    }
                });

                if (
                    self.selectedOrder != "" &&
                    [
                        "users/",
                        "customers/",
                        "time_logs/",
                        "licitations/",
                        "orders-not-integrateds/",
                    ].includes(self.endPoint)
                ) {
                    if (self.orderType == "ASC") {
                        query += "&orderByAsc=" + self.selectedOrder;
                    } else {
                        query += "&orderByDesc=" + self.selectedOrder;
                    }
                }
                if (["log_access/"].includes(self.endPoint)) {
                    query += "&show_routes=1";
                }

                if (
                    self.selectedOrder != "" &&
                    !["log_access/"].includes(self.endPoint)
                ) {
                    query +=
                        "&order_by=" + self.selectedOrder + "&order_type=" + self.orderType;
                }
                if (["log_access/"].includes(self.endPoint)) {
                    query += "&orderByDesc=id";
                }

                self.relationships.forEach((relationship) => {
                    query += "&with[]=" + relationship;
                });

                self.extras.forEach((extra) => {
                    query += "&" + extra;
                });

                if (haveFilters) {
                    self.$store.dispatch("setQueryHistory", {
                        route: self.$route.name,
                        query: query,
                    });

                    self.$store.dispatch("setSearchHistory", {
                        have: true,
                        filters: self.filters_copy,
                    });
                }

                if (!haveFilters && self.buttonSearchClicked) {
                    self.$store.dispatch("setQueryHistory", {
                        route: null,
                        query: null,
                    });

                    self.$store.dispatch("setSearchHistory", {
                        have: false,
                        filters: {},
                    });
                }

                if (!haveFilters && self.selectedOrder == "") {
                    self.buttonSearchClicked = false;
                }

                if (
                    self.$store.state.queryHistory.query !== null &&
                    self.$route.name === self.$store.state.queryHistory.route
                ) {
                    query = self.$store.state.queryHistory.query;
                } else {
                    self.$store.dispatch("setQueryHistory", {
                        route: null,
                        query: null,
                    });

                    self.$store.dispatch("setSearchHistory", {
                        have: false,
                        filters: {},
                    });
                }

                if (
                    [
                        "users/",
                        "customers/",
                        "time_logs/",
                        "licitations/",
                        "orders-not-integrateds/",
                        "log_access/",
                    ].includes(self.endPoint)
                ) {
                    if (haveFilters) {
                        var api =
                            self.$store.state.api +
                            self.endPoint +
                            "?page=" +
                            page +
                            "&" +
                            query +
                            "&limit=" +
                            100 +
                            self.increments;
                    } else {
                        var api =
                            self.$store.state.api +
                            self.endPoint +
                            "?page=" +
                            page +
                            "&" +
                            query +
                            "&limit=" +
                            self.cTop +
                            self.increments;
                    }
                } else {
                    var api =
                        self.$store.state.api +
                        self.endPoint +
                        "?page=" +
                        page +
                        "&" +
                        query +
                        "&cTop=" +
                        self.cTop +
                        self.increments;
                }

                if (page < 1) {
                    page = 1;
                }

                axios
                    .get(api)
                    .then((response) => {
                        setTimeout(function () {
                            $("#show_registers").val(self.cTop).change();
                        }, 1000);
                        if (
                            [
                                "users/",
                                "customers/",
                                "time_logs/",
                                "licitations/",
                                "orders-not-integrateds/",
                                "log_access/",
                            ].includes(self.endPoint)
                        ) {
                            self.data = Object.values(response.data.data);
                            self.count = response.data.count;
                            self.currentPage = response.data.page;
                            self.lastPage = response.data.pages;
                        } else {
                            self.data = response.data.data.ZOOM;
                            self.count = response.data.data.count;
                            if (page != 0) {
                                self.currentPage = page - 1;
                            } else {
                                self.currentPage = page;
                            }
                            self.lastPage = Math.ceil(response.data.data.count / self.cTop);

                            if (response.data.data.count < self.cTop) {
                                self.lastPage = 1;
                            } else if (
                                response.data.data.count % self.cTop > 0 &&
                                self.lastPage == 1
                            ) {
                                self.lastPage++;
                            }
                        }
                    })
                    .then(() => {
                        self.getColumns();
                        self.requesting = false;
                        self.vSelect = true;
                        if (self.buttonSearchClicked) {
                            self.$toast.success("Filtro aplicado");
                        }
                    })
                    .then(() => {
                        $("#show_registers").val(self.cTop);
                    })
                    .then(() => {
                        self.settingTopScroll();
                    })
                    .catch((error) => {
                        self.$toast.error(error.response.data.message);
                        self.loading = false;
                        self.requesting = false;
                        $("#show_registers").val(self.cTop);
                    });
            }
        },
        extract() {
            const self = this;

            self.loading = true;
            self.extracting = true;

            // filtros
            console.log(self.colu)

            let query = [];
            let haveFilters = false;

            if (self.$store.state.searchHistory.have) {
                var history_filters = JSON.parse(
                    JSON.stringify(self.$store.state.searchHistory.filters)
                );

                //Text
                var t = 0;
                history_filters
                    .filter(function (item) {
                        return item.type == "text";
                    })
                    .forEach((element) => {
                        $("#text" + t).val(element.value);
                        t++;
                    });

                //date
                var d = 0;
                history_filters
                    .filter(function (item) {
                        return item.type == "date";
                    })
                    .forEach((element) => {
                        $("#date" + d).val(element.value);
                        d++;
                    });

                //product
                var p = 0;
                history_filters
                    .filter(function (item) {
                        return item.type == "product";
                    })
                    .forEach((element) => {
                        $("#product" + p).val(element.value);
                        p++;
                    });

                //select
                setTimeout(function () {
                    var s = 0;
                    history_filters
                        .filter(function (item) {
                            return item.type == "selectApi";
                        })
                        .forEach((element) => {
                            if (element.value != "" && element.value != undefined) {
                                self.filtersSelectApi[s].value = element.value;
                            }
                            s++;
                        });
                }, 5000);
            }

            self.filters_copy.forEach((filter) => {
                if (filter.type == "radio" && filter.checked) {
                    query.push({
                        key: filter.name,
                        value: filter.value,
                    });
                } else if (
                    filter.type == "date" &&
                    filter.value != "" &&
                    filter.value != undefined
                ) {
                    var vle = moment(filter.value, "DD/MM/YYYY").format("YYYY-MM-DD");

                    if (
                        filter.name == "created_at" &&
                        [
                            "users/",
                            "customers/",
                            "time_logs/",
                            "licitations/",
                            "orders-not-integrateds/",
                        ].includes(self.endPoint)
                    ) {
                        vle = vle + " 00:00:00," + vle + " 23:59:59";
                    }

                    haveFilters = true;

                    query.push({ key: filter.name, value: vle });
                } else if (
                    filter.type != "radio" &&
                    filter.value != "" &&
                    filter.value != undefined
                ) {
                    haveFilters = true;

                    query.push({
                        key: filter.name,
                        value: filter.value,
                    });
                }
            });

            // filtros fim

            var api = self.$store.state.api + self.endPoint + "extract";

            let columns = [];

            self.local_columns.forEach(item => {
                if(item.reportColumn){
                    columns.push(item.reportColumn)
                }
            })

            self.extract_data.query = query;
            self.extract_data.order_by = self.selectedOrder;
            self.extract_data.order_type = self.orderType;
            self.extract_data.email = this.$store.getters.user.email;
            self.extract_data.top = self.cTop;
            self.extract_data.columns = columns

            axios
                .post(api, self.extract_data)
                .then((response) => {
                    self.$swal(
                        "Sucesso",
                        "Assim que o relatório estiver pronto, você o receberá no seu e-mail.",
                        "success"
                    );
                })
                .catch((error) => {
                    this.$toast.error("Erro na API");
                    self.loading = false;
                    self.extracting = false;
                })
                .then(() => {
                    self.loading = false;
                    self.extracting = false;
                });
        },
        order_by(field, index) {
            const self = this;

            if (self.ordered_by == field) {
                self.ordered_asc = !self.ordered_asc;
            } else {
                self.ordered_asc = true;
            }

            self.data = self.data.sort(function (a, b) {
                if (a[field] > b[field]) {
                    if (self.ordered_asc) return 1;
                    else return -1;
                }
                if (a[field] < b[field]) {
                    if (self.ordered_asc) return -1;
                    else return 1;
                }

                return 0;
            });

            self.ordered_index = index;
            self.ordered_by = field;
        },
        get_field_value(column, row) {
            if (column.field) {
                let vle = row;
                let splited = column.field.split(".");
                for (let i = 0; i < splited.length; i++) {
                    vle = vle[splited[i]];
                    if (!vle) {
                        break;
                    }
                }
                // if (vle == undefined) {
                //   return "N/A";
                // } else if (vle != undefined) {
                //   if (vle.trim() == "") {
                //     return "N/A";
                //   }
                // }
                return vle;
            } else if (column.callback) {
                return column.callback(row);
            }
        },
        getEntity() {
            const self = this;

            var selects = this.filtersSelectApi;

            selects.forEach((element) => {
                const api =
                    this.$store.state.api + element.model + "?status=1&paginated=false";

                axios
                    .get(api)
                    .then((response) => {
                        var returned = {};
                        returned.data = [
                            {
                                [element.propertyName]: "",
                            },
                        ];
                        returned.data = returned.data.concat(response.data.data);
                        returned.element = element;
                        self.selApi[element.model] = returned;
                    })
                    .catch((error) => {
                        this.$toast.error("Erro na API");
                    });
            });
        },
        async getProducts() {
            const self = this;
            const api = this.$store.state.api + "customers/get-products/";

            await axios
                .post(api, { path: self.$route.path })
                .then((response) => {
                    const newArray = [""].concat(response.data.data);

                    self.products = newArray;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        async getBranches() {
            const self = this;
            const api = this.$store.state.api + "branches/get-branches/";

            await axios
                .post(api, { path: self.$route.path })
                .then((response) => {
                    const newArray = [""].concat(response.data);

                    self.branches = newArray;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        async getContracts() {
            const self = this;
            const api = this.$store.state.api + "contracts/active-contract-list";

            await axios
                .get(api, { path: self.$route.path })
                .then((response) => {
                    const newArray = [""].concat(response.data.data.ZOOM);

                    self.contracts = newArray;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },

        settingTopScroll() {
            setTimeout(function () {
                $(window).on("scroll load", function () {
                    if ($(".TableCSS table").length > 0) {
                        var tabela_top = $(".TableCSS table").offset().top;
                        var window_top = $(this).scrollTop();

                        $(".TableCSS thead").css({
                            top:
                                tabela_top - window_top <= 0
                                    ? window_top - tabela_top + 120 + "px"
                                    : "0",
                            "z-index": "99999999",
                            background: "white",
                            padding: "10px 0",
                        });
                    }
                });
            }, 500);
        },
    },
    async mounted() {
        let self = this;

        self.local_columns = self.columns;
        self.orders.forEach(function (order) {
            if (order.selected) {
                self.selectedOrder = order.column;
            }
        });
        
        // await this.getProducts();
        // await this.getBranches();
        // await this.getContracts();
        this.getEntity();
        this.get_data();

        self.filters_copy = JSON.parse(JSON.stringify(self.filters));

        if (window.localStorage.getItem('filters_' + self.crudName)) {
            self.filters_copy = JSON.parse(window.localStorage.getItem('filters_' + self.crudName));
        }
    },
};
</script>

<style>
.vs__clear {
    display: none;
}
</style>

<style scoped>
.form-control {
    border: 2px solid #e0ebf5 !important;
}

.form-select {
    border: 2px solid #e0ebf5 !important;
}

.btn-success:disabled {
    color: #212529;
    background-color: #68cf2966 !important;
    border-color: #68cf2966 !important;
}

.btn-black:disabled {
    color: #212529;
    background-color: #aea9a966 !important;
    border-color: #cbcbcb66 !important;
}

* {
    position: relative;
}

thead .TableCSS {
    background-color: #e0ebf5 !important;
    /* z-index: 99999999 !important; */
}

.badge-primary {
    background-color: var(--primary);
    color: #fff !important;
    font-weight: bold !important;
}

.btn-success:disabled {
    color: #212529;
    background-color: #68cf2966 !important;
    border-color: #68cf2966 !important;
}

.btn-black:disabled {
    color: #212529;
    background-color: #aea9a966 !important;
    border-color: #cbcbcb66 !important;
}

* {
    position: relative;
}

thead .TableCSS {
    background-color: #e0ebf5 !important;
    /* z-index: 99999999 !important; */
}

.badge-primary {
    background-color: var(--primary);
    color: #fff !important;
    font-weight: bold !important;
}

.Container {
    width: 100%;
    overflow-y: auto;
    border-radius: 5px;
    margin: 0 auto;
}

.Content {
    /* width: 300px; */
    text-align: center;
}

.Flipped,
.Flipped .Content {
    transform: rotateX(180deg);
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: gainsboro;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#modalExtract {
    padding: 15px;
}

#modalColumns {
    padding: 15px;
    z-index: 1000;
    max-height: 600px;
    overflow-y: auto;
}

.bordered {
    text-align: center;
    border: dotted 1px rgb(160, 160, 160);
    margin: 5px;
    padding: 5px;
    font-size: 14px;    
    cursor: pointer;
}

.drop-area {
    min-height: 50px;
    /* Ajuste o valor conforme necessário */
    border: 2px dashed #aaa;
    margin: 10px;
    padding: 10px;
    background-color: #ededed;
}

.bold {
    font-weight: bold;
}

.md-scrollable {
    max-height: 450px;
    overflow: auto;
}

.list-group {
    min-height: 50px;
    padding-bottom: 45px;
}
</style>
