<template>
  <div>
    <div class="row" style="overflow: hidden">
      <div class="col-lg-12">
        <div class="card">
          <div :class="'card-header '">
            <h5 class="card-title">Relatórios</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <button
                  class="btn btn-success white float-right"
                  @click="openModalNovo"
                >
                  Novo Relatorio
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Título</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Qtd Downloads</th>
                      <th scope="col">Ações</th>
                    </tr>
                  </thead>
                  <tbody v-if="reports.length > 0">
                    <tr v-for="report in reports" :key="report.id">
                      <td>{{ report.id }}</td>
                      <td>{{ report.title }}</td>
                      <td>{{ report.description }}</td>
                      <td>{{ report.downloads }}</td>
                      <td align="center">
                        <a
                          :href="
                            $store.state.api + 'reports/download/' + report.id
                          "
                          target="_blank"
                          class="btn btn-primary"
                          >Baixar</a
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" align="center">
                        Nenhum relatório disponível.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Produto -->

    <div id="modalNovo">
      <div class="modal-fade-background"></div>
      <div
        :class="['modal fade show']"
        id="modal-pending"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div id="ModalNovo">
              <div class="row">
                <div class="col-md-6 bold">Gerar novo relatório</div>
                <div class="col-md-6">
                  <button
                    @click="closeModalNovo()"
                    style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                    class="btn btn-danger float-right"
                  >
                    X
                  </button>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label>Data Inicial</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="generate.date_start"
                  />
                </div>
                <div class="col-md-6">
                  <label>Data Final</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="generate.date_end"
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <label>Escolha o tipo do relatório</label>
                  <select class="form-control" v-model="generate.type">
                    <option value="1">Pedidos</option>
                    <option value="2">Cotação</option>
                  </select>
                </div>

                <div class="col-md-12 mt-3">
                  <button
                    class="btn btn-success white float-right"
                    @click="generateReport"
                  >
                    Gerar Relatório
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
});
export default {
  data() {
    return {
      reports: [],
      generate: {},
    };
  },
  computed: {},
  components: {},
  methods: {
    openModalNovo() {
      show_modal("modalNovo");
    },
    closeModalNovo() {
      close_modal("modalNovo");
    },
    generateReport() {
      const self = this;
      const api = this.$store.state.api + "reports/generate";

      axios
        .post(api, self.generate)
        .then((response) => {
          self.getReports();
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
    getReports() {
      const self = this;
      const api = this.$store.state.api + "reports/?orderByDesc=id";

      axios
        .get(api)
        .then((response) => {
          self.reports = response.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>
<style scoped>
.card {
  margin: 45px;
}
#ModalNovo {
  padding: 15px;
}
</style>