<template>
    <div class="container-fluid">
        
        <div class="mr-3 ml-3">
            <div class="row justify-content-center">
                <div class="col-md-3 mt-3" v-if="$store.getters.user.access_contracts == 1">
                    <router-link to="/admin/contract-list">
                        <div class="widget-stat card bg-success">
                            <div class="card-body p-3">
                                <div class="media">
                                    <span class="me-1">
                                        <img src="../assets/images/icons/contratos_w.png" width="35" />
                                    </span>
                                    <div class="media-body text-white text-center">
                                        <p class="mt-3" style="font-size: 14px !important">
                                            Contratos
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-md-3 mt-3" v-if="$store.getters.user.access_requests == 1">
                    <router-link to="/admin/order-list">
                        <div class="widget-stat card bg-info2">
                            <div class="card-body p-3">
                                <div class="media">
                                    <span class="me-1">
                                        <i class="flaticon-044-file"></i>
                                    </span>
                                    <div class="media-body text-white text-center">
                                        <p class="mt-3" style="font-size: 14px !important">
                                            Pedidos
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-md-3 mt-3" v-if="$store.getters.user.access_invoices == 1">
                    <router-link to="/admin/invoice-list">
                        <div class="widget-stat card bg-danger">
                            <div class="card-body p-3">
                                <div class="media">
                                    <span class="me-1">
                                        <img src="../assets/images/icons/financeiro_w.png" width="35" />
                                    </span>
                                    <div class="media-body text-white text-center">
                                        <p class="mt-3" style="font-size: 14px !important">
                                            FINANCEIRO
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="col-md-3 mt-3" v-if="$store.getters.user.access_logistics == 1">
                    <router-link to="/admin/delivery-list">
                        <div class="widget-stat card bg-orange">
                            <div class="card-body p-3">
                                <div class="media">
                                    <span class="me-2">
                                        <img src="../assets/images/icons/entregas_w.png" width="35" />
                                    </span>
                                    <div class="media-body text-white text-center">
                                        <p class="mt-3" style="font-size: 14px !important">
                                            Entregas
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    
        <div class="mr-3 ml-3">
            <dashboard></dashboard>
        </div>
    </div>
</template>

<script>
    import Dashboard from "./Dashboard.vue";

    export default {
        components: {
            Dashboard,
        },
        data() {
            return {};
        },
        methods: {
            brNumber(number) {
                let value_converted = parseFloat(number);
                let value_fixed = value_converted.toFixed(2);
                let value_replaced = value_fixed.replaceAll(".", ",");

                return "R$ " + value_replaced;
            },
        },
        mounted() {
        },
    };
</script>

<style scoped>
    .bg-info2 {
        background-color: #51a6f5 !important;
    }

    .widget-stat .card .bg-success {
        height: 10em;
    }
</style>