<template class="bg">
  <router-view></router-view>
</template>

<script>
</script>
<style >
body{
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url('../../assets/images/bg-login.jpg'); /* Caminho da sua imagem */
    background-size: cover; /* Cobre todo o fundo */
    background-position: center; /* Centraliza a imagem */
    background-repeat: no-repeat; /* Evita repetição da imagem */
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>