<template>
  <div class="dashboard-chart">
    <!-- <Chart :id="id" :type="type" :data="chartData" :options="options" :dataRef="chartRef"></Chart> -->
    <div class="col-12" v-if="loading">
      <div class="card-body text-center">
        <span class="fa fa-spinner fa-spin fa-5x"></span>
      </div>
    </div>
    <vue3-chart-js
      v-if="!loading"
      :id="id"
      ref="chartRef"
      :type="type"
      :data="data"
      :options="options"
    >
    </vue3-chart-js>
  </div>
</template>

<script>
// import Chart from "../Layouts/Chart.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";

export default {
  computed: {
    chartData() {
      return this.data;
    },
  },
  data() {
    return {
      chartRef: {},
      loading: false,
      id: "chart",
      type: "line",
      data: {
        labels: ["Quantidade"],
        datasets: [
          {
            label: ["Quantidade"],
            backgroundColor: ["red"],
            data: [],
            borderColor: "red",
          },
        ],
      },
      options: {
        beginAtZero: true,
        plugins: {
          legend: {
            display: true,
          },
        },
      },
    };
  },
  props: ["contract"],
  mounted() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      let self = this;
      self.loading = true;

      let filters = "";
      if (this.contract) {
        filters =
          "CN9_FILIAL=" +
          this.contract.CN9_FILIAL +
          "&CN9_NUMERO=" +
          this.contract.CN9_NUMERO +
          "&CN9_DTINIC=" +
          this.contract.CN9_DTINIC;
      }

      let api = self.$store.getters.api + "contracts/chartVolume?" + filters;
      axios
        .get(api)
        .then((response) => {
          var data = response.data.data;
          self.data.labels = data.labels;
          self.data.datasets[0].data = data.data;
          self.data.datasets[0].datalabels = {
            color: "#0d0d0d",
            font: {
              weight: "bold",
              size: 9,
            },
            align: "end",
            backgroundColor: "#a2a2a2",
            formatter: function (value, context) {
              if (value != 0) {
                return (
                  "R$ " +
                  value.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                );
              } else {
                return "";
              }
            },
          };
        })
        .then(() => {
          // self.$refs.chartRef.update(250)
        })
        .then(() => {
          self.loading = false;
        });
    },
  },
  components: {
    Vue3ChartJs,
  },
};
</script>

