<template>
    <div class="container-fluid">
        <button @click="$router.go(-1)" class="btn btn-primary mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'arrow-left']"/>
            Voltar
        </button>
        <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'retweet']"/>
            Atualizar
        </button>
        <div>
            <div class="detail-container" v-if="loaded">
                <div class="row" v-if="data.length > 0">
                    <div class="col-md-12">
                        <iframe
                            width="100%"
                            height="1000"
                            :src="data"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
            data: {},
        };
    },
    methods: {
        getDetail() {
            const self = this;
            self.loaded = false;
            if (!self.$store.state.user.customer_id) {
                setTimeout(() => {
                    this.getDetail();
                }, 300)
                return;
            }
            let data = {
                id: self.$route.params.id,
                customer_id: self.$store.state.user.customer_id
            };

            var api = self.$store.getters.api + "winner_docs/view";

            axios
                .post(api, data, {responseType: 'blob'})
                .then((response) => {
                    const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
                    self.data = URL.createObjectURL(pdfBlob);
                })
                .catch((error) => {
                    self.data = {};
                    this.$toast.error(
                        "Documento não disponível"
                    );
                })
                .finally(() => {
                    self.loaded = true;
                })
        },
    },

    mounted() {
        const self = this;
        self.getDetail();
    },
};
</script>
