<template>
    <div class="pt-3">
        <div class="container mt-5">
            <div class="card p-5">
                <img class="login-img" src="@/assets/images/logo.png" alt/>
                <div class="text-center">
                    <h5 class="mt-4">Politicas de privacidade e Termos de Uso</h5>
                </div>
                <div class="col text-center" v-if="spinner">
                    <span class="fa fa-spinner fa-spin fa-5x"></span>
                </div>
                <div class="card-body" v-html="terms_of_use" v-else>
                </div>
                <div class="row">
                    <div class="col text-end my-4">
                        <button class="btn btn-danger mr-1" @click="cancel">
                            NÂO CONCORDO
                        </button>
                        <button class="btn btn-primary" @click="save">
                            CONCORDO
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {toastError, ValidateForm} from "@/components/Composables/functions";
import {cpf, cnpj} from 'cpf-cnpj-validator';

export default {
    data() {
        return {
            terms_of_use: "",
            spinner: false
        };
    },
    methods: {
        save() {
            const self = this;
            let api = this.$store.state.api + "confirm_terms/" + this.$store.state.user.id;
            this.spinner = true;

            axios
                .get(api)
                .then((response) => {
                    this.$store.dispatch("initialize");
                    this.$toast.success(response.data);
                    setTimeout(() => {
                        this.$router.push("/admin");
                    }, 2000)
                })
                .catch((error) => {
                    if (error.response.data.message) {
                        self.$toast.error(error.response.data.message);
                    } else if (typeof error.response.data === "object") {
                        for (let item in error.response.data) {
                            self.$toast.error(error.response.data[item][0]);
                        }
                    }
                })
                .finally(() => {
                    this.spinner = false
                });
        },
        cancel() {
            this.$swal
                .fire({
                    title: "Atenção!",
                    text: "Você tem certeza? Ao não concordar com os termos do Portal, você será deslogado!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim",
                    cancelButtonText: "Cancelar",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch("logout");
                        this.$router.push("/login");
                    }
                });
        },
        getTerms() {

            const self = this;
            const api = this.$store.state.api + "get-terms";

            axios
                .get(api)
                .then((response) => {
                    self.terms_of_use = response.data[0].terms
                })
        }
    },
    mounted() {
        this.getTerms()
    }
};
</script>
<style scoped>

label {
    margin-top: 10px;
}

body {
    background: rgba(0, 0, 0, 0);
    background-color: #fff;
    background-size: 100%;
    height: 620px;
}
</style>
