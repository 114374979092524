<template>
  <div class="gauge-container">
    <div class="text-center">
      <b>Porcentagem de valor consumido</b>
    </div>

    <div class="gauge-draw-container">
      <div
        class="gauge"
        :style="
          '--rotation: ' +
          graph_rotation +
          'deg; --color:#1b96d4; --background:#e9ecef;'
        "
      >
        <div class="percentage"></div>
        <div class="mask"></div>
        <span class="value">{{ value_percentage + "%" }}</span>

        <!-- <div v-if="expiring && selected_contract" class="expiring alert alert-danger" role="alert">
                    Este contrato pode esgotar em volume antes que chegue sua data de fim em {{ selected_contract.CN9_DTFIM }}
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selected_contract"],
  computed: {
    expiring() {
      if (!this.selected_contract) return;

      let value_percentage = this.calculate_value_percentage();
      return (
        value_percentage < 100 &&
        this.value_percentage - this.date_percentage > 10
      );
    },
    value_percentage() {
      if (!this.selected_contract) return;

      // let percentage = this.selected_contract.CN9_VLATU * 100 / this.selected_contract.CN9_VLINI
      let percentage =
        ((this.selected_contract.CNB_QTDMED *
          this.selected_contract.CNB_VLUNIT) /
          (this.selected_contract.CNB_VLUNIT *
            this.selected_contract.CNB_QUANT)) *
        100;
      let percentage_fixed = percentage.toFixed(2);

      return percentage_fixed;
    },
    graph_rotation() {
      if (!this.selected_contract) return;

      return (this.value_percentage * 180) / 100;
    },
  },
};
</script>