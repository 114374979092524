<template>
  <div class="container-fluid">
    <section id="publico" v-if="tipoCliente == 2">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                INFORMAÇÕES NECESSÁRIAS PARA ESTIMATIVA DE PREÇOS OU PARA COMPRA
                DIRETA
              </span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-7">
                  <label class="bold"
                    >RAZÃO SOCIAL DO CLIENTE / ORGÃO SOLICITANTE</label
                  >
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="licitation.company_name"
                  />
                </div>
                <div class="col-md-3">
                  <label class="bold">CNPJ</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="licitation.cnpj"
                    v-maska="'##.###.###/####-##'"
                  />
                </div>
                <div class="col-md-2">
                  <label class="bold">DATA</label>
                  <input
                    disabled
                    type="date"
                    class="form-control"
                    v-model="licitation.date"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4">
                  <label class="form-check-label bold"
                    >A FINALIDADE DA COTAÇÃO É PARA:</label
                  >
                </div>
                <div class="col-md-3" style="margin-left: -10px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd1"
                    id="rd1"
                    value="COMPRA DIRETA"
                    v-model="licitation.purpose_of_quotation"
                  />
                  <label class="form-check-label">COMPRA DIRETA</label>
                </div>
                <div class="col-md-5" style="margin-left: -65px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd1"
                    id="rd1"
                    value="ESTIMATIVA PARA ABERTURA DA LICITAÇÃO"
                    v-model="licitation.purpose_of_quotation"
                  />
                  <label class="form-check-label"
                    >ESTIMATIVA PARA ABERTURA DA LICITAÇÃO</label
                  >
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-8">
                  <label class="bold"
                    >DESCRIÇÃO DO PRODUTO (Incluir concentração, Base Seca, etc,
                    se for o caso)</label
                  >
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="productSelected"
                  />
                </div>
                <div class="col-md-2">
                  <label class="bold">QUANTIDADE</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="licitation.quantity"
                  />
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-md-4">
                  <label class="form-check-label bold"
                    >UNIDADE DE FORNECIMENTO:</label
                  >
                </div>
                <div class="col-md-1" style="margin-left: -50px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="KG"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">KG</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="TON"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">TON</label>
                </div>
                <div class="col-md-1" style="margin-left: -5px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="L"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">L</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="UN"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">UN</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="M³"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">M³</label>
                </div>
                <div class="col-md-1" style="margin-left: -25px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd2"
                    id="rd2"
                    value="OUTRA"
                    v-model="licitation.supply_unit"
                  />
                  <label class="form-check-label">OUTRA:</label>
                </div>
                <div
                  class="col-md-2"
                  style="margin-left: -25px; margin-top: -10px"
                >
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="licitation.specification"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2">
                  <label class="form-check-label bold">TRANSPORTE:</label>
                </div>
                
                <div class="col-md-2" style="margin-left: 0">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd3"
                    id="rd3"
                    value="CARRETA"
                    v-model="licitation.delivery_type"
                  />
                  <label class="form-check-label">CARRETA</label>
                </div>
                <div class="col-md-2" style="margin-left: -65px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd3"
                    id="rd3"
                    value="TRUCK"
                    v-model="licitation.delivery_type"
                  />
                  <label class="form-check-label">TRUCK</label>
                </div>
                <div class="col-md-2" style="margin-left: -80px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd3"
                    id="rd3"
                    value="TOCO"
                    v-model="licitation.delivery_type"
                  />
                  <label class="form-check-label">TOCO</label>
                </div>
              </div>
              <div class="row mt-4" style="margin-top: 35px">
                <div class="col-md-2">
                  <label class="form-check-label bold">CONTAINER:</label>
                </div>
                <div class="col-md-2" style="margin-left: -30px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd4"
                    id="rd4"
                    value="COM TRANSBORDO"
                    v-model="licitation.container"
                  />
                  <label class="form-check-label">COM TRANSBORDO</label>
                </div>
                <div class="col-md-2" style="margin-left: 0">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd4"
                    id="rd4"
                    value="EM COMODATO"
                    v-model="licitation.container"
                  />
                  <label class="form-check-label">EM COMODATO</label>
                </div>
                <div class="col-md-2" style="margin-left: -15px">
                  <input
                    type="radio"
                    class="form-check-input"
                    name="rd4"
                    id="rd4"
                    value="INCLUSO"
                    v-model="licitation.container"
                    disabled
                  />
                  <label class="form-check-label">INCLUSO</label>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-2">
                  <label class="form-check-label bold">BOMBONAS:</label>
                </div>
                <div class="col-md-1" style="margin-left: -45px">
                  <div class="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      disabled
                      type="checkbox"
                      class="form-check-input"
                      checked=""
                      id="customCheckBox2"
                      required=""
                      v-model="licitation.select_bonbons"
                    />
                    <label class="form-check-label" for="customCheckBox2"
                      >KG/LITROS?</label
                    >
                  </div>
                </div>
                <div class="col-md-2 ml-4">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.kg_l"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-2">
                  <label class="form-check-label bold">SACOS:</label>
                </div>
                <div class="col-md-1" style="margin-left: -45px">
                  <div class="form-check custom-checkbox mb-3 checkbox-info">
                    <input
                      disabled
                      type="checkbox"
                      class="form-check-input"
                      checked=""
                      id="customCheckBox2"
                      required=""
                      v-model="licitation.select_bags"
                    />
                    <label class="form-check-label" for="customCheckBox2"
                      >KG?</label
                    >
                  </div>
                </div>
                <div class="col-md-2">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.bags"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >SE BOMBONAS E SACOS, O FORNECEDOR DEVE RETIRAR AS
                    EMBALAGENS VAZIAS?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    disabled
                    class="toggleStyle"
                    v-model="licitation.remove_packaging"
                    v-bind="simNao"
                  />
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-md-2">
                  <label class="bold">FRETE</label>
                </div>
                <div class="col-md-2" style="margin-left: -65px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd5"
                    id="rd5"
                    value="CIF"
                    v-model="licitation.shipping_type"
                  />
                  <label class="form-check-label">CIF</label>
                </div>
                <div class="col-md-2" style="margin-left: -95px">
                  <input
                    disabled
                    type="radio"
                    class="form-check-input"
                    name="rd5"
                    id="rd5"
                    value="FOB"
                    v-model="licitation.shipping_type"
                  />
                  <label class="form-check-label">FOB</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                CONDIÇÃO DE ENTREGA (MARQUE AS OPÇÕES)
              </span>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >PARA DESCARGA DO PRODUTO, DEMANDARÁ AJUDANTE
                    (CHAPA)?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    disabled
                    v-model="licitation.shipping_helper"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >DEMANDARÁ CONTRATAÇÃO DE EMPILHADEIRA?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    disabled
                    v-model="licitation.shipping_forklift"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >A LICITAÇÃO EXIGIRÁ BOMBA DOSADORA E PAINEL EM
                    COMODATO?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    disabled
                    v-model="licitation.shipping_pump_and_panel"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-10">
                  <label class="form-check-label bold"
                    >A LICITAÇÃO EXIGIRÁ TANQUE EM COMODATO?</label
                  >
                </div>
                <div class="col-md-2">
                  <Toggle
                    disabled
                    v-model="licitation.shipping_tank"
                    class="toggleStyle"
                    v-bind="simNao"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <label class="form-check-label bold"
                    >DEMAIS ESPECIFICAÇÃO DO TANQUE:</label
                  >
                </div>
                <div class="col-md-7">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="licitation.shipping_especification_tank"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                SE FOR COTAÇÃO PARA COMPRA DIRETA, TAMBÉM INDICAR
              </span>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-md-3">
                  <label class="form-check-label bold">PRAZO DE ENTREGA</label>
                </div>
                <div class="col-md-3">
                  <input
                    disabled
                    type="date"
                    class="form-control"
                    style="margin-top: -10px"
                    v-model="licitation.deadline"
                  />
                </div>
                <div class="col-md-3">
                  <label class="form-check-label bold">QUANTAS ENTREGAS</label>
                </div>
                <div class="col-md-3">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -15px"
                    v-model="licitation.number_of_deliveries"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5">
                  <label class="form-check-label bold"
                    >QUANTIDADE DE PRODUTO POR ENTREGA:</label
                  >
                </div>
                <div class="col-md-2">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -55px"
                    v-model="licitation.amount_of_product"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4">
                  <label class="form-check-label bold"
                    >VIGÊNCIA DO FORNECIMENTO:</label
                  >
                </div>
                <div class="col-md-2">
                  <input
                    disabled
                    type="date"
                    class="form-control"
                    style="margin-top: -10px; margin-left: -60px"
                    v-model="licitation.duration_of_supply"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-3">
                  <label class="form-check-label bold">LOCAL DA ENTREGA:</label>
                </div>
                <div class="col-md-9">
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    v-model="licitation.delivery_place_text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <span class="card-title">
                ARQUIVOS ANEXADOS
              </span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="alert alert-info" role="alert">
                    <strong>Info:</strong> Clique sobre o nome do arquivo, para realizar o download.
                  </div>
                </div>
                <div class="col-md-12">
                  <font-awesome-icon :icon="['fa', 'file']" class="mr-2" /> <a :href="this.$store.state.api + 'licitation/file-download/' + licitation.file_path" target="_blank">{{ licitation.file }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 bold">
          PARA FORMALIZAÇÃO DA COMPRA DIRETA, INFORMAR A LEGISLAÇÃO DE ORIGEM
          (LEI 8.666/93 ou 14.133/21)
        </div>
        <div class="col-lg-12">
          *Se vencedores da cotação, necessitaremos do envio de parecer
          jurídico, caso o valor da Compra Direta seja maior do que o definido
          em lei
        </div>
      </div>
    </section>
    <section id="privado" v-if="tipoCliente == 1">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span class="card-title">
              INFORMAÇÕES NECESSÁRIAS PARA ESTIMATIVA DE PREÇOS OU PARA COMPRA
              DIRETA
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label class="bold"
                  >RAZÃO SOCIAL DO CLIENTE / ORGÃO SOLICITANTE</label
                >
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="licitation.company_name"
                />
              </div>
              <div class="col-md-3">
                <label class="bold">CNPJ</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="licitation.cnpj"
                  v-maska="'##.###.###/####-##'"
                />
              </div>
              <div class="col-md-3">
                <label class="bold">DATA</label>
                <input
                  disabled
                  type="date"
                  class="form-control"
                  v-model="licitation.date"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-2">
                <label class="form-check-label bold">REVENDA</label>
              </div>

              <div class="col-md-1" style="margin-left: -35px">
                <Toggle
                  disabled
                  v-model="licitation.reseller"
                  class="toggleStyle"
                  v-bind="simNao"
                />
              </div>
              <div class="col-md-1 ml-5">
                <label class="bold">PRODUTO</label>
              </div>
              <div class="col-md-7 ml-3">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="productSelected"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-2">
                <label class="bold">QUANTIDADE</label>
              </div>
              <div class="col-md-2">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.quantity"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-4">
                <label class="form-check-label bold"
                  >UNIDADE DE FORNECIMENTO:</label
                >
              </div>
              <div class="col-md-1" style="margin-left: -55px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="KG"
                  v-model="licitation.und"
                />
                <label class="form-check-label">KG</label>
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="TON"
                  v-model="licitation.und"
                />
                <label class="form-check-label">TON</label>
              </div>
              <div class="col-md-1" style="margin-left: -5px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="L"
                  v-model="licitation.und"
                />
                <label class="form-check-label">L</label>
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="UN"
                  v-model="licitation.und"
                />
                <label class="form-check-label">UN</label>
              </div>
              <div class="col-md-1" style="margin-left: -15px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="M³"
                  v-model="licitation.und"
                />
                <label class="form-check-label">M³</label>
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd2"
                  id="rd2"
                  value="OUTRA"
                  v-model="licitation.und"
                />
                <label class="form-check-label">OUTRA:</label>
              </div>
              <div
                class="col-md-2"
                style="margin-left: -5px; margin-top: -10px"
              >
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="licitation.specification"
                />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-md-2">
                <label class="form-check-label bold">TRANSPORTE:</label>
              </div>
              
              <div class="col-md-2" style="margin-left: 0">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd8"
                  id="rd8"
                  value="CARRETA"
                  v-model="licitation.delivery_type"
                />
                <label class="form-check-label">CARRETA</label>
              </div>
              <div class="col-md-2" style="margin-left: -65px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd8"
                  id="rd8"
                  value="TRUCK"
                  v-model="licitation.delivery_type"
                />
                <label class="form-check-label">TRUCK</label>
              </div>
              <div class="col-md-2" style="margin-left: -80px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd8"
                  id="rd8"
                  value="TOCO"
                  v-model="licitation.delivery_type"
                />
                <label class="form-check-label">TOCO</label>
              </div>
            </div>
            <div class="row mt-4" style="margin-top: 35px">
              <div class="col-md-2">
                <label class="form-check-label bold">CONTAINER:</label>
              </div>
              <div class="col-md-3" style="margin-left: -30px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd4"
                  id="rd4"
                  value="COM TRANSBORDO"
                  v-model="licitation.container"
                  disabled
                />
                <label class="form-check-label">COM TRANSBORDO</label>
              </div>
              <div class="col-md-2" style="margin-left: -65px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd4"
                  id="rd4"
                  value="EM COMODATO"
                  v-model="licitation.container"
                  disabled
                />
                <label class="form-check-label">EM COMODATO</label>
              </div>
              <div class="col-md-2" style="margin-left: -15px">
                <input
                  type="radio"
                  class="form-check-input"
                  name="rd4"
                  id="rd4"
                  value="INCLUSO"
                  v-model="licitation.container"
                  disabled
                />
                <label class="form-check-label">INCLUSO</label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2">
                <label class="form-check-label bold">BOMBONAS:</label>
              </div>
              <div class="col-md-1 mr-5" style="margin-left: -15px">
                <div class="form-check custom-checkbox mb-3 checkbox-info">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    checked=""
                    id="customCheckBox2"
                    required=""
                    v-model="licitation.select_bonbons"
                    disabled
                  />
                  <label class="form-check-label" for="customCheckBox2"
                    >KG/LITROS?</label
                  >
                </div>
              </div>
              <div class="col-md-2 ml-4">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.kg_l"
                  disabled
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-2">
                <label class="form-check-label bold">SACOS:</label>
              </div>
              <div class="col-md-1" style="margin-left: -45px">
                <div class="form-check custom-checkbox mb-3 checkbox-info">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    checked=""
                    id="customCheckBox2"
                    required=""
                    v-model="licitation.select_bags"
                    disabled
                  />
                  <label class="form-check-label" for="customCheckBox2"
                    >KG?</label
                  >
                </div>
              </div>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.bags"
                  disabled
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-10">
                <label class="form-check-label bold"
                  >SE BOMBONAS E SACOS, O FORNECEDOR DEVE RETIRAR AS EMBALAGENS
                  VAZIAS?</label
                >
              </div>
              <div class="col-md-2">
                <Toggle
                  class="toggleStyle"
                  v-model="licitation.remove_packaging"
                  v-bind="simNao"
                  disabled
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-3">
                <label class="bold">LOCAL DE ENTREGA</label>
              </div>
              <div class="col-md-6">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  style="margin-top: -10px; margin-left: -25px"
                  v-model="licitation.delivery_place"
                />
              </div>
              <div class="col-md-1" style="margin-left: -25px">
                <label class="bold">BAIRRO</label>
              </div>
              <div class="col-md-2">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  style="margin-top: -10px"
                  v-model="licitation.delivery_neighborhood"
                />
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-md-1">
                <label class="bold">CIDADE</label>
              </div>
              <div class="col-md-4">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  style="margin-top: -10px; margin-left: 0px"
                  v-model="licitation.city"
                />
              </div>
              <div class="col-md-1">
                <label class="bold">UF</label>
              </div>
              <div class="col-md-2">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  style="margin-top: -10px; margin-left: -45px"
                  v-model="licitation.uf"
                />
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-2">
                <label class="bold">FRETE</label>
              </div>
              <div class="col-md-2" style="margin-left: -65px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd6"
                  id="rd6"
                  value="CIF"
                  v-model="licitation.shipping_type"
                />
                <label class="form-check-label">CIF</label>
              </div>
              <div class="col-md-2" style="margin-left: -95px">
                <input
                  disabled
                  type="radio"
                  class="form-check-input"
                  name="rd6"
                  id="rd6"
                  value="FOB"
                  v-model="licitation.shipping_type"
                />
                <label class="form-check-label">FOB</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <span class="card-title">
              ARQUIVOS ANEXADOS
            </span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="alert alert-info" role="alert">
                  <strong>Info:</strong> Clique sobre o nome do arquivo, para realizar o download.
                </div>
              </div>
              <div class="col-md-12">
                <font-awesome-icon :icon="['fa', 'file']" class="mr-2" /> <a :href="this.$store.state.api + 'licitation/file-download/' + licitation.file_path" target="_blank">{{ licitation.file }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";
import Toggle from "@vueform/toggle";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
});

export default {
  components: {
    vSelect,
    Toggle,
  },
  data() {
    return {
      company: {},
      currentPage: 0,
      firstPage: 1,
      lastPage: 0,
      count: 0,
      licitation: {},
      productSelected: "",
      tipoCliente: 1, // 1 = Publico, 2 = Privado
      searchProductField: "",
      simNao: {
        onLabel: "SIM",
        offLabel: "NÃO",
      },
      products: [],
      value: true,
      customers: [],
      stores: [],
      selectedCustomer: {},
      user: {
        name: "",
        email: "",
        password: "",
        customer_id: null,
        stores: [],
        type_id: "",
        access_contracts: false,
        access_invoices: false,
        access_requests: false,
        access_logistics: false,
      },
    };
  },
  computed: {
    customerList() {
      return this.customers;
    },
  },
  methods: {
    getEntity(id) {
      const self = this;
      const api = this.$store.state.api + "licitations/" + id;

      axios
        .get(api)
        .then((response) => {
          self.licitation = response.data.data[0];

          self.productSelected = self.licitation.product_name;

          self.licitation.remove_packaging =
            self.licitation.remove_packaging == 1 ? true : false;
          self.licitation.shipping_helper =
            self.licitation.shipping_helper == 1 ? true : false;
          self.licitation.shipping_forklift =
            self.licitation.shipping_forklift == 1 ? true : false;
          self.licitation.shipping_pump_and_panel =
            self.licitation.shipping_pump_and_panel == 1 ? true : false;
          self.licitation.shipping_tank =
            self.licitation.shipping_tank == 1 ? true : false;
          self.licitation.reseller =
            self.licitation.reseller == 1 ? true : false;

          self.tipoCliente = self.licitation.company_type;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
  },
  mounted() {
    const self = this;
    // self.getCustomer();
    var id = self.$route.params.params;
    self.getEntity(id);
  },
};
</script>
<style scoped>
.form-check-input:disabled ~ .form-check-label {
  color: #7e7e7e !important;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 100 !important;
}

.card-title {
  font-size: 16px;
  margin-top: -15px;
  margin-bottom: -15px !important;
}
.card-header {
  background-color: #f1f1f1 !important;
  border: 1px solid #e1e1e1 !important;
}
.card-body {
  border: 1px solid #e1e1e1 !important;
}
.bg {
  background-color: blueviolet;
}
.bold {
  font-weight: bold;
  font-size: 16px;
  margin-top: 1px;
}
.toggleStyle {
  --toggle-width: 4rem !important;
  --toggle-height: 1.45rem !important;
  --toggle-font-size: 0.85rem !important;
  --toggle-handle-on-disabled: #0e49af !important;
  --toggle-bg-on-disabled: #10b981 !important;
  --toggle-text-on-disabled: #fff !important;
  font-weight: bold;
}
#ModalProduto {
  padding: 15px;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
