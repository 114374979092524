<template>
  <div class="container-fluid">
    
    <button @click="$router.back()" class="btn btn-primary mt-3 mb-3">
      <font-awesome-icon :icon="['fa', 'arrow-left']" /> Voltar
    </button>
    <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
      <font-awesome-icon :icon="['fa', 'retweet']" /> Atualizar
    </button>
    <div>
      <div class="detail-container">
        <div class="row" v-if="loaded">
          <div class="col-md-12 mb-3" style="font-size: 16px">
            <strong>Posição Atual:</strong> {{ map[0].Landmark }}
          </div>
          <div class="col-md-12 mb-3" style="font-size: 16px">
            <strong>Última Atualização:</strong> {{ positionTime }}
          </div>
          <div class="col-md-12 mb-3" style="font-size: 16px">
            <strong>Placa do Veículo:</strong> {{ map[0].VehicleName }}
          </div>
          <!-- <div class="col-md-12 mb-3" style="font-size: 16px">
            <strong>Velocidade:</strong> {{ map[0].Velocity }} km/h
          </div> -->
        </div>
        <div class="row">
          <div class="col-md-12">
         
             <GMapMap
              v-if="loaded"
              :zoomControl=false 
              :mapTypeControl=false
              :center="center"              
              map-type-id="terrain"
              :zoom=9
              style="width: 1000px; height: 600px"
            >
              <GMapCluster>
                <GMapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  :clickable="true"
                  :draggable="true"
                  @click="center = m.position"
                />
              </GMapCluster>
            </GMapMap> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      map: "",
      loaded: false,
      center: { lat: 0, lng: 0 },
      markers: [
        {
          position: {
            lat: 0,
            lng: 0,
          },
        }, // Along list of clusters
      ],
    };
  },
  computed: {
    positionTime() {
      return moment(this.map[0].PositionTime).format("DD/MM/YYYY H:mm:ss");
    },
  },
  methods: {
    getDetail() {
      const self = this;
      self.loaded = false;
      let data = self.$route.params.params;

      var api = self.$store.getters.api + "delivery-map/";

      axios
        .post(api, { info: data })
        .then((response) => {
          self.map = response.data;

          self.center.lat = self.map[0].Latitude;
          self.center.lng = self.map[0].Longitude;

          self.markers[0].position.lat = self.map[0].Latitude;
          self.markers[0].position.lng = self.map[0].Longitude;
      
        })
        .then(()=>{
          self.loaded = true;
        })
        .catch((error) => {
          this.$toast.error(
            "Erro ao retornar as informações de posição do veículo."
          );
        });
    },
  },

  mounted() {
    const self = this;

    window.setTimeout(function(){

      self.getDetail();
    }, 1000)
  },
};
</script>