<template>
    <div id="divSegura">
        <section v-if="!loading">
            <div class="row mt-4">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <button
                                class="btn btn-primary"
                                @click="step--, scrollToTop()"
                                v-if="step > 1"
                            >
                                <font-awesome-icon :icon="['fa', 'arrow-left']" class="mr-1"/>
                                Etapa Anterior
                            </button>
                        </div>
                        <div class="col-md-4">
                            <ul class="steps">
                                <li
                                    :class="
                                    step == 1
                                      ? 'step step-active'
                                      : step > 1
                                      ? 'step step-success'
                                      : 'step'
                                  "
                                >
                                    <div class="step-content">
                                        <span class="step-circle">1</span>
                                        <!-- <span class="step-text">Step 1</span> -->
                                    </div>
                                </li>
                                <li
                                    :class="
                                        step == 2
                                          ? 'step step-active'
                                          : step > 2
                                          ? 'step step-success'
                                          : 'step'
                                      "
                                >
                                    <div class="step-content">
                                        <span class="step-circle">2</span>
                                        <!-- <span class="step-text">Step 2</span> -->
                                    </div>
                                </li>
                                <li
                                    :class="
                                        step == 3
                                          ? 'step step-active'
                                          : step > 3
                                          ? 'step step-success'
                                          : 'step'
                                      "
                                >
                                    <div class="step-content">
                                        <span class="step-circle">3</span>
                                        <!-- <span class="step-text">Step 3</span> -->
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row ml-4 mr-4 mt-5" v-if="step == 1">
                        <div class="col-md-12" v-if="!new_order.id">
                            <label><span class="required">* </span>Produto</label>
                            <input
                                type="text"
                                id="product"
                                class="form-control clickable"
                                v-model="new_order.product"
                                @click="openModalProduto"
                            />
                        </div>
                        <div class="col-md-12" v-else>
                            <label><span class="required">* </span>Produto</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.product_name"
                                disabled
                            />
                        </div>

                        <div class="col-md-4 mt-2" v-if="!new_order.id">
                            <label><span class="required">* </span>Nº da Negociação</label>
                            <input
                                v-if="contracts.length > 0"
                                type="text"
                                id="product"
                                class="form-control clickable"
                                v-model="formattedNumberContract"
                                placeholder="Clique para selecionar uma negociação"
                                @click="openModalContrato"
                            />
                            <input
                                v-else
                                type="text"
                                id="product"
                                class="form-control clickable"
                                value="Carregando dados, aguarde..."
                                disabled
                            />
                            <!-- <v-select
                              id="contract"
                              v-model="new_order.contract"
                              :filterable="false"
                              label="CN9_NUMERO"
                              :options="contracts"
                              @option:selected="selectContract"
                            >
                            </v-select> -->
                        </div>
                        <div class="col-md-4 mt-2" v-else>
                            <label><span class="required">* </span>Nº da Negociação</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.internal_contract"
                                disabled
                            />
                        </div>
                        <!-- <div class="col-md-5 mt-2">
                          <label>Contrato Interno</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="new_order.internal_contract"
                            disabled
                          />
                        </div> -->
                        <div class="col-md-2 mt-2">
                            <label>Valor Unit.</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="valorUnitF"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Und.</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.und"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label>Condição de Pagamento</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.payment_condition"
                                disabled
                            />
                        </div>
                        <div class="col-md-12 mt-2">
                            <label>Fábrica de Origem</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.manufacturing_origin"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label>Vencimento do Contrato</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.contract_expiration"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label v-if="!new_order.und">Saldo do Contrato</label>
                            <label v-if="new_order.und"
                            >Saldo do Contrato {{ unity_measure }}</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.contract_balance"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row ml-4 mr-4 mt-5" v-if="step == 2">
                        <div class="col-md-8">
                            <label><span class="required">* </span>Endereço de Entrega</label>
                            <input
                                type="text"
                                class="form-control clickable"
                                v-model="new_order.delivery_address"
                                @click="openModalLojas"
                                placeholder="Clique para selecionar um endereço"
                            />
                        </div>
                        <div class="col-md-4">
                            <label><span class="required">* </span>Bairro</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_neighborhood"
                                readonly
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label><span class="required">* </span>Cidade</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_city"
                                readonly
                            />
                        </div>
                        <div class="col-md-1 mt-2">
                            <label><span class="required">* </span>Estado</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_state"
                                readonly
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label><span class="required">* </span>Tipo do Frete</label>
                            <select
                                class="form-select"
                                v-model="new_order.delivery_type"
                                disabled
                            >
                                <option value="C">CIF</option>
                                <option value="F">FOB</option>
                                <option value="T">Por conta terceiros</option>
                                <option value="R">Por conta remetente</option>
                                <option value="D">Por conta destinatário</option>
                                <option value="S">Sem frete</option>
                            </select>
                        </div>
                        <div class="col-md-3 mt-2">
                            <label><span class="required">* </span>Local de Entrega</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.zaplent"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label><span class="required">* </span>Data da Solicitação</label>
                            <input
                                type="date"
                                class="form-control"
                                v-model="new_order.date"
                                disabled
                            />
                        </div>
                        <div class="col-md-3 mt-2">
                            <label><span class="required">* </span>Quantidade</label>
                            <!-- <input
                              class="form-control productPrice"
                              v-model="new_order.quantity"
                              @change="changeValue"
                              v-on:keyup="changeValue"
                            /> -->
                            <CurrencyInput
                                v-model="new_order.quantity"
                                @change="changeValue"
                                v-on:keyup="changeValue"
                                :options="{
                  currency: 'BRL',
                  autoDecimalDigits: true,
                  precision: 0,
                  hideNegligibleDecimalDigitsOnFocus: false,
                  hideGroupingSeparatorOnFocus: false,
                  currencyDisplay: 'hidden',
                }"
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Valor</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="orderValue"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Und.</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.und"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Pedido do Cliente</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.client_order"
                            />
                        </div>
                        <div class="col-md-3 mt-2">
                            <label>Item.Ped.Com</label>
                            <input
                                type="text"
                                class="form-control"
                                v-maska="'######'"
                                v-model="new_order.item_ped_com"
                            />
                        </div>
                    </div>

                    <div class="row ml-4 mr-4" v-if="step == 2">
                        <!-- <div class="col-md-4 mt-3">
                          <div class="row">
                            <div class="col-md-12">
                              <label
                                ><span class="required">* </span>Dias de Recebimento</label
                              >
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c1"
                                  required=""
                                  v-model="new_order.all_days"
                                />
                                <label class="form-check-label" for="c1">Todos</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c2"
                                  required=""
                                  v-model="new_order.monday"
                                />
                                <label class="form-check-label" for="c2"
                                  >Segunda-feira</label
                                >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c3"
                                  required=""
                                  v-model="new_order.tuesday"
                                />
                                <label class="form-check-label" for="c3">Terça-feira</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c4"
                                  required=""
                                  v-model="new_order.wednesday"
                                />
                                <label class="form-check-label" for="c4"
                                  >Quarta-feira</label
                                >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c5"
                                  required=""
                                  v-model="new_order.thursday"
                                />
                                <label class="form-check-label" for="c5"
                                  >Quinta-feira</label
                                >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c6"
                                  required=""
                                  v-model="new_order.friday"
                                />
                                <label class="form-check-label" for="c6">Sexta-feira</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c7"
                                  required=""
                                  v-model="new_order.saturday"
                                />
                                <label class="form-check-label" for="c7">Sábado</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c8"
                                  required=""
                                  v-model="new_order.sunday"
                                />
                                <label class="form-check-label" for="c8">Domingo</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c9"
                                  required=""
                                  v-model="new_order.holiday"
                                />
                                <label class="form-check-label" for="c9">Feriados</label>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-md-4 mt-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <label><span class="required">*</span> Data de entrega</label>
                                </div>
                                <div class="col-md-12">
                                    <!-- <label style="font-weight: normal; font-style: italic">Mínimo de 3 dias úteis</label> -->
                                    <input
                                        type="date"
                                        class="form-control"
                                        :min="new_order.date"
                                        v-model="new_order.delivery_date"
                                        v-on:input="verifyDeliveryDate"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="row">
                                <div class="col-md-6">
                                    <label><span class="required">*</span> Horário de:</label>
                                    <input
                                        type="time"
                                        class="form-control"
                                        v-model="new_order.hour_receive_start"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label><span class="required">*</span> Horário até:</label>
                                    <input
                                        type="time"
                                        class="form-control"
                                        v-model="new_order.hour_receive_end"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3">
                            <label>Observações do Pedido</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.observation"
                            />
                            Caracteres restantes: <strong>{{ counter_observation }}</strong>
                        </div>
                    </div>

                    <div class="row ml-4 mr-4 mt-5" v-if="step == 3">
                        <div
                            class="col-md-12 centered mt-1 mb-5"
                            style="font-weight: bold; font-size: 20px; color: #28a745"
                        >
                            REVISAR DADOS DO PEDIDO
                        </div>
                        <div class="col-md-12">
                            <label><span class="required">* </span>Produto</label>
                            <input
                                type="text"
                                id="product"
                                class="form-control clickable"
                                v-model="new_order.product"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label><span class="required">* </span>Nº da Negociação</label>
                            <input
                                type="text"
                                id="product"
                                class="form-control clickable"
                                v-model="formattedNumberContract"
                                @click="openModalContrato"
                                disabled
                            />
                            <!-- <v-select
                              id="contract"
                              v-model="new_order.contract"
                              :filterable="false"
                              label="CN9_NUMERO"
                              :options="contracts"
                              disabled
                            >
                            </v-select> -->
                        </div>
                        <!-- <div class="col-md-5 mt-2">
                          <label>Contrato Interno</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="new_order.internal_contract"
                            disabled
                          />
                        </div> -->
                        <div class="col-md-2 mt-2">
                            <label>Valor Unit.</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="valorUnitF"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Und.</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.und"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label>Condição de Pagamento</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.payment_condition"
                                disabled
                            />
                        </div>
                        <div class="col-md-12 mt-2">
                            <label>Fábrica de Origem</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.manufacturing_origin"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label>Vencimento do Contrato</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.contract_expiration"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label v-if="!new_order.und">Saldo do Contrato</label>
                            <label v-if="new_order.und"
                            >Saldo do Contrato {{ unity_measure }}</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.contract_balance"
                                disabled
                            />
                        </div>
                    </div>
                    <div class="row ml-4 mr-4 mt-5" v-if="step == 3">
                        <div class="col-md-8">
                            <label><span class="required">* </span>Endereço de Entrega</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_address"
                                disabled
                            />
                        </div>
                        <div class="col-md-4">
                            <label><span class="required">* </span>Bairro</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_neighborhood"
                                disabled
                            />
                        </div>
                        <div class="col-md-4 mt-2">
                            <label><span class="required">* </span>Cidade</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_city"
                                readonly
                            />
                        </div>
                        <div class="col-md-1 mt-2">
                            <label><span class="required">* </span>Estado</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.delivery_state"
                                readonly
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label><span class="required">* </span>Tipo do Frete</label>
                            <select
                                class="form-select"
                                v-model="new_order.delivery_type"
                                disabled
                            >
                                <option value="C">CIF</option>
                                <option value="F">FOB</option>
                                <option value="T">Por conta terceiros</option>
                                <option value="R">Por conta remetente</option>
                                <option value="D">Por conta destinatário</option>
                                <option value="S">Sem frete</option>
                            </select>
                        </div>
                        <div class="col-md-3 mt-2">
                            <label><span class="required">* </span>Local de Entrega</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.zaplent"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label><span class="required">* </span>Data da Solicitação</label>
                            <input
                                type="date"
                                class="form-control"
                                v-model="new_order.date"
                                disabled
                            />
                        </div>
                        <div class="col-md-3 mt-2">
                            <label><span class="required">* </span>Quantidade</label>
                            <input
                                type="number"
                                class="form-control"
                                v-model="new_order.quantity"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Valor</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="orderValue"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Und.</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.und"
                                disabled
                            />
                        </div>
                        <div class="col-md-2 mt-2">
                            <label>Pedido do Cliente</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="new_order.client_order"
                                disabled
                            />
                        </div>

                        <div class="col-md-3 mt-2">
                            <label>Item.Ped.Com</label>
                            <input
                                disabled
                                type="text"
                                class="form-control"
                                v-model="new_order.item_ped_com"
                            />
                        </div>
                    </div>

                    <div class="row ml-4 mr-4" v-if="step == 3">
                        <!-- <div class="col-md-4 mt-3">
                          <div class="row">
                            <div class="col-md-12">
                              <label
                                ><span class="required">* </span>Dias de Recebimento</label
                              >
                            </div>
                            <div class="col-md-12 mt-2">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c1"
                                  required=""
                                  v-model="new_order.all_days"
                                  disabled
                                />
                                <label class="form-check-label" for="c1">Todos</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c2"
                                  required=""
                                  v-model="new_order.monday"
                                  disabled
                                />
                                <label class="form-check-label" for="c2"
                                  >Segunda-feira</label
                                >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c3"
                                  required=""
                                  v-model="new_order.tuesday"
                                  disabled
                                />
                                <label class="form-check-label" for="c3">Terça-feira</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c4"
                                  required=""
                                  v-model="new_order.wednesday"
                                  disabled
                                />
                                <label class="form-check-label" for="c4"
                                  >Quarta-feira</label
                                >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c5"
                                  required=""
                                  v-model="new_order.thursday"
                                  disabled
                                />
                                <label class="form-check-label" for="c5"
                                  >Quinta-feira</label
                                >
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c6"
                                  required=""
                                  v-model="new_order.friday"
                                  disabled
                                />
                                <label class="form-check-label" for="c6">Sexta-feira</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c7"
                                  required=""
                                  v-model="new_order.saturday"
                                  disabled
                                />
                                <label class="form-check-label" for="c7">Sábado</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c8"
                                  required=""
                                  v-model="new_order.sunday"
                                  disabled
                                />
                                <label class="form-check-label" for="c8">Domingo</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-check custom-checkbox mb-3 checkbox-info">
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  checked=""
                                  id="c9"
                                  required=""
                                  v-model="new_order.holiday"
                                  disabled
                                />
                                <label class="form-check-label" for="c9">Feriados</label>
                              </div>
                            </div>
                          </div>
                        </div> -->
                        <div class="col-md-4 mt-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <label><span class="required">*</span> Data de entrega</label>
                                </div>
                                <div class="col-md-12">
                                    <!-- <label style="font-weight: normal; font-style: italic">Mínimo de 3 dias úteis</label> -->
                                    <input
                                        type="date"
                                        class="form-control"
                                        v-model="new_order.delivery_date"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="row">
                                <div class="col-md-6">
                                    <label><span class="required">*</span> Horário de:</label>
                                    <input
                                        type="time"
                                        class="form-control"
                                        v-model="new_order.hour_receive_start"
                                        disabled
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label><span class="required">*</span> Horário até:</label>
                                    <input
                                        type="time"
                                        class="form-control"
                                        v-model="new_order.hour_receive_end"
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3">
                            <label>Observações do Pedido</label>
                            <textarea
                                class="form-control"
                                cols="40"
                                rows="10"
                                v-model="new_order.observation"
                                disabled
                            ></textarea>
                        </div>
                    </div>

                    <div class="row ml-4 mr-4 mt-5 d-flex flex-row-reverse">
                        <div class="col-md-2 d-flex flex-row-reverse" v-if="step == 3">
                            <button class="btn btn-primary" @click="openModalPedido()">
                                <font-awesome-icon :icon="['fa', 'save']" class="mr-1"/>
                                Salvar
                                Pedido
                            </button>
                        </div>
                        <div class="col-md-2 d-flex flex-row-reverse" v-if="step < 3">
                            <button
                                class="btn btn-primary"
                                @click="checkForm(), scrollToTop()"
                            >
                                Próxima Etapa
                                <font-awesome-icon :icon="['fa', 'arrow-right']" class="ml-1"/>
                            </button>
                        </div>
                        <div class="col-md-2 d-flex flex-row-reverse" v-if="step > 1">
                            <button class="btn btn-primary" @click="step--, scrollToTop()">
                                <font-awesome-icon :icon="['fa', 'arrow-left']" class="mr-1"/>
                                Etapa Anterior
                            </button>
                        </div>
                        <div class="col-md-2 d-flex flex-row-reverse" v-if="step >= 2">
                            <button class="btn btn-warning" @click="saveForm(1)">
                                <font-awesome-icon :icon="['fa', 'save']" class="mr-1"/>
                                Salvar
                                como Rascunho
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section v-else>
            <div class="mt-5 text-center">
                <span class="fa fa-spinner fa-spin fa-5x"></span>
            </div>
        </section>

        <!-- Modal Produto -->

        <div id="modalProduto">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="ModalProduto">
                            <div class="row">
                                <div class="col-md-6 bold">Buscar Produtos</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModal()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <!-- <div class="col-md-12">
                                  <label>Nome do produto</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="searchProductField"
                                  />
                                </div>
                                <div class="col-md-12 centered mt-3">
                                  <button class="btn btn-primary" @click="searchProduct(1)">
                                    Buscar
                                  </button>
                                </div> -->
                                <div class="col-md-12" v-if="products.length > 0">
                                    <div class="row mb-4 bold">
                                        <!-- <div class="col-md-2">Código</div> -->
                                        <div class="col-md-10">Nome Produto</div>
                                        <!-- <div class="col-md-2">Un. Medida</div> -->
                                        <div class="col-md-2">Ação</div>
                                    </div>
                                    <div
                                        class="row mt-3 mb-1"
                                        v-for="product in products"
                                        :key="product"
                                        style="border-bottom: 1px solid #a1a1a1"
                                    >
                                        <!-- <div class="col-md-2">{{ product.CNB_PRODUT }}</div> -->
                                        <div class="col-md-10">{{ product.B1_COD }}</div>
                                        <!-- <div class="col-md-2">{{ product.B1_UM }}</div> -->
                                        <div class="col-md-2">
                                            <button
                                                class="btn btn-sm btn-primary"
                                                style="margin-top: -10px; margin-bottom: 5px"
                                                @click="selectProduct(product), closeModal()"
                                            >
                                                Selecionar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Confirmar Pedido -->

        <div id="modalConfirmarPedido">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div id="ModalPedido">
                            <div class="row">
                                <div class="col-md-6 bold">Atenção!</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModalPedido()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="alert alert-danger centered" role="alert">
                                    <div class="col-md-12">
                                        Ao clicar em <strong>CONFIRMAR</strong> você afirma que
                                        concorda com as informações aqui apresentadas.
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    Lembrando que a <strong>QUANTIDADE</strong> solicitada e o
                                    <strong>VALOR TOTAL</strong> poderão variar de acordo com a
                                    capacidade do caminhão disponível para a entrega.
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-md-12">
                                    Caso possua alguma particularidade para este pedido, acione o
                                    Atendimento ao Cliente antes de confirmá-lo.
                                </div>
                            </div>
                            <div class="row d-flex flex-row-reverse mt-5">
                                <div class="col-md-2 d-flex flex-row-reverse">
                                    <button
                                        class="btn btn-success"
                                        @click="saveForm(), closeModalPedido()"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                                <div class="col-md-2 d-flex flex-row-reverse">
                                    <button class="btn btn-danger" @click="closeModalPedido">
                                        Fechar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Contrato -->

        <div id="modalContrato" v-if="contracts.length > 0">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content">
                        <div id="ModalProduto">
                            <div class="row">
                                <div class="col-md-6 bold">Selecionar Negociação</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModalContrato()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-md-12 mb-5 mt-3" v-if="contracts.length > 0">
                                    <table width="100%">
                                        <tr>
                                            <th width="3.11%">Produto</th>
                                            <th width="7.11%">Vl. Unit.</th>
                                            <th width="7.11%">Vigência</th>
                                            <th width="13.11%">Saldo</th>
                                            <th width="5%">Frete</th>
                                            <th width="13.11%">CNPJ de Fat.</th>
                                            <th width="13.22%">Municipio de Fat.</th>
                                            <th width="27.22%">Contrato do Cliente</th>
                                            <th width="8%">Ação</th>
                                        </tr>
                                        <br/>
                                        <tr
                                            v-for="contract in contracts"
                                            :key="contract"
                                            style="
                        border-bottom: 1px solid #a1a1a1;
                        margin-top: 250px;
                      "
                                        >
                                            <td width="16.22%">{{ contract.CNB_DESCRI.trim() }}</td>
                                            <td width="7.11%">{{ formatVlUnit(contract) }}</td>
                                            <td width="9.11%">{{ contract.CN9_DTFIM }}</td>
                                            <td width="8.11%">{{ formatBalance(contract) }}</td>
                                            <td width="5%">{{ contract.CN9_ZTPFRE }}</td>
                                            <td width="11.11%">{{ contract.A1_CGC.trim() }}</td>
                                            <td width="13.22%">{{ contract.A1_MUN.trim() }}</td>
                                            <td width="19.11%">{{ contract.CN9_ZCONCL.trim() }}</td>
                                            <td width="5%">
                                                <button
                                                    class="btn btn-sm btn-primary"
                                                    style="margin-top: 5px; margin-bottom: 5px"
                                                    @click="
                            selectContract(contract), closeModalContrato()
                          "
                                                >
                                                    Selecionar
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <!-- <div class="col-md-12" v-if="contracts.length > 0">
                                  <div class="row mt-4">
                                    <nav aria-label="" class="mt-3">
                                      <label>Página: {{ currentPage + 1 }}/</label>
                                      <label v-if="lastPage > 0">{{ lastPage }}</label>
                                      <label v-if="lastPage <= 0">{{ lastPage + 1 }}</label>
                                      <ul class="pagination">
                                        <i class="fa-solid fa-arrow-right-to-bracket"></i>
                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(firstPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-arrow-left"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>
                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(currentPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-chevron-left"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>

                                        <li class="page-item" v-if="currentPage > firstPage">
                                          <button
                                            @click="searchProduct(currentPage - 1)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage - 1 }}
                                          </button>
                                        </li>

                                        <li class="page-item" v-if="currentPage >= firstPage">
                                          <button
                                            @click="searchProduct(currentPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage }}
                                          </button>
                                        </li>

                                        <li class="page-item active">
                                          <button class="page-link" href="#">
                                            {{ currentPage + 1 }}
                                          </button>
                                        </li>

                                        <li class="page-item" v-if="currentPage + 1 < lastPage">
                                          <button
                                            @click="searchProduct(currentPage + 2)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage + 2 }}
                                          </button>
                                        </li>
                                        <li class="page-item" v-if="currentPage + 2 < lastPage">
                                          <button
                                            @click="searchProduct(currentPage + 3)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage + 3 }}
                                          </button>
                                        </li>

                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(currentPage + 2)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-chevron-right"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>
                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(lastPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-arrow-right"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Lojas -->

        <div id="modalLojas" v-if="stores.length > 0">
            <div class="modal-fade-background"></div>
            <div
                :class="['modal fade show']"
                id="modal-pending"
                tabindex="-1"
                role="dialog"
            >
                <div class="modal-dialog modal-dialog-centered modal-xl">
                    <div class="modal-content">
                        <div id="ModalProduto">
                            <div class="row">
                                <div class="col-md-6 bold">Selecionar Endereço</div>
                                <div class="col-md-6">
                                    <button
                                        @click="closeModalLojas()"
                                        style="
                      padding: 15px;
                      width: 30px;
                      margin-right: 10px;
                      padding: 0px;
                    "
                                        class="btn btn-danger float-right"
                                    >
                                        X
                                    </button>
                                </div>
                            </div>

                            <div class="row mt-4 mb-4">
                                <div class="col-md-4">
                                    <label>Filtrar por Endereço</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="filters.A1_END"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <label>Filtrar por Local de Entrega</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        v-model="filters.A1_ZAPLENT"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-primary mt-4" @click="getStores()">
                                        Buscar
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12" v-if="stores.length > 0">
                                    <table width="100%">
                                        <tr>
                                            <th width="3%">Loja</th>
                                            <th width="12%">Endereço</th>
                                            <th width="12%">Bairro</th>
                                            <th width="10%">Municipio</th>
                                            <th width="3%">Estado</th>
                                            <th width="11%">Local de Entrega</th>
                                            <th width="3%">Ações</th>
                                        </tr>
                                        <tr
                                            v-for="store in storeList"
                                            :key="store"
                                            style="border-bottom: 1px solid #a1a1a1; margin-top: 25px"
                                        >
                                            <td width="3%">{{ store.A1_LOJA.trim() }}</td>
                                            <td width="15%">{{ store.A1_END.trim() }}</td>
                                            <td width="15%">{{ store.A1_BAIRRO.trim() }}</td>
                                            <td width="10%">{{ store.A1_MUN.trim() }}</td>
                                            <td width="3%">{{ store.A1_EST.trim() }}</td>
                                            <td width="3%">{{ store.A1_ZAPLENT.trim() }}</td>
                                            <td width="5%">
                                                <button
                                                    class="btn btn-sm btn-primary"
                                                    style="margin-top: 5px; margin-bottom: 5px"
                                                    @click="selectStore(store), closeModalLojas()"
                                                >
                                                    Selecionar
                                                </button>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <!-- <div class="col-md-12" v-if="contracts.length > 0">
                                  <div class="row mt-4">
                                    <nav aria-label="" class="mt-3">
                                      <label>Página: {{ currentPage + 1 }}/</label>
                                      <label v-if="lastPage > 0">{{ lastPage }}</label>
                                      <label v-if="lastPage <= 0">{{ lastPage + 1 }}</label>
                                      <ul class="pagination">
                                        <i class="fa-solid fa-arrow-right-to-bracket"></i>
                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(firstPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-arrow-left"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>
                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(currentPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-chevron-left"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>

                                        <li class="page-item" v-if="currentPage > firstPage">
                                          <button
                                            @click="searchProduct(currentPage - 1)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage - 1 }}
                                          </button>
                                        </li>

                                        <li class="page-item" v-if="currentPage >= firstPage">
                                          <button
                                            @click="searchProduct(currentPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage }}
                                          </button>
                                        </li>

                                        <li class="page-item active">
                                          <button class="page-link" href="#">
                                            {{ currentPage + 1 }}
                                          </button>
                                        </li>

                                        <li class="page-item" v-if="currentPage + 1 < lastPage">
                                          <button
                                            @click="searchProduct(currentPage + 2)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage + 2 }}
                                          </button>
                                        </li>
                                        <li class="page-item" v-if="currentPage + 2 < lastPage">
                                          <button
                                            @click="searchProduct(currentPage + 3)"
                                            class="page-link"
                                            href="#"
                                          >
                                            {{ currentPage + 3 }}
                                          </button>
                                        </li>

                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(currentPage + 2)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-chevron-right"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>
                                        <li class="page-item">
                                          <button
                                            @click="searchProduct(lastPage)"
                                            class="page-link"
                                            href="#"
                                          >
                                            <span
                                              class="fa fa-arrow-right"
                                              style="color: grey"
                                            ></span>
                                          </button>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";
import Toggle from "@vueform/toggle";
import {createToaster} from "@meforma/vue-toaster";
import CurrencyInput from "../Utils/CurrencyInput.vue";

const toaster = createToaster({
    position: "top-right",
});

export default {
    beforeRouteLeave(to, from, next) {
        // Verificar se há alguma ação não salva ou condição não cumprida
        if (this.acaoNaoSalva) {
            const confirmMessage =
                "Você tem alterações não salvas. Tem certeza que deseja sair?";
            if (confirm(confirmMessage)) {
                // Continua para a próxima rota
                next();
            } else {
                // Permanece na rota atual
                next(false);
            }
        } else {
            // Não há ações não salvas, continua para a próxima rota
            next();
        }
    },
    components: {
        vSelect,
        Toggle,
        CurrencyInput,
    },
    data() {
        return {
            loading: false,
            acaoNaoSalva: true,
            confirmationRequired: true,
            branches: [],
            value: 1234,
            counter_observation: 120,
            risk: "",
            contracts: [],
            stores: [],
            step: 1,
            company: {},
            currentPage: 0,
            firstPage: 1,
            lastPage: 0,
            count: 0,
            new_order: {
                date: moment().format("YYYY-MM-DD"),
                unit_value: 0,
                all_days: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                holiday: false,
                internal_contract: "",
            },
            productSelected: {},
            tipoCliente: 1, // 1 = Publico, 2 = Privado
            searchProductField: "",
            simNao: {
                onLabel: "SIM",
                offLabel: "NÃO",
            },
            products: [],
            value: true,
            customers: [],
            stores: [],
            filters: {},
            selectedCustomer: {},
            user: {
                name: "",
                email: "",
                password: "",
                customer_id: null,
                stores: [],
                type_id: "",
                access_contracts: false,
                access_invoices: false,
                access_requests: false,
                access_logistics: false,
            },
        };
    },
    computed: {
        storeList(){

            if(this.new_order && this.new_order.contract && this.new_order.contract.CN9_ZLJSEN == '2'){
                return this.stores.filter( store => store.A1_LOJA == this.new_order.contract.CNA_LOJACL)
            } 
            
            return this.stores
            
        },
        customerList() {
            return this.customers;
        },
        orderValue() {
            return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 5,
            }).format(this.new_order.value);
        },
        valorUnitF() {
            return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 5,
            }).format(this.new_order.unit_value);
        },
        unity_measure() {
            console.log(this.new_order.und);
            switch (this.new_order.und.trim()) {
                case "T":
                    return "em Toneladas";
                case "L":
                    return "em Litros";
                case "KG":
                    return "em Quilos";
                default:
                    return "em " + this.new_order.und;
            }
        },
        formattedNumberContract() {
            if (this.new_order.internal_contract != "") {
                return this.new_order.internal_contract.replace(/^0+/, "");
            }
        },
    },
    watch: {
        "new_order.observation": function (novoValor) {
            this.counter_observation = 120 - Number(novoValor.length);
            if (novoValor.length > 120) {
                this.new_order.observation = novoValor.substring(0, 120);
            }
        },
        "new_order.client_order": function (novoValor) {
            if (novoValor.length > 15) {
                this.new_order.client_order = novoValor.substring(0, 15);
            }
        },
    },
    methods: {
        validaTruck(self){

            //1=Carreta, 2=Truck, 3= Carreta e Truck,  4=Fracionado ou Vazio
            if(self.new_order.contract.CN9_ZTPVEI.trim() != '' && Number(self.new_order.quantity) > 0){
                
                //1=Carreta, a quantidade deverá ser >17.000
                if( self.new_order.contract.CN9_ZTPVEI.trim() == '1' && Number(self.new_order.quantity) < 17000){
                    self.new_order.quantity = 0;                    
                    this.$toast.error(
                        "A quantidade informada precisa ser maior que 17.000, devido ao veículo parametrizado no contrato."
                    );
                    return false;
                }

                //2=Truck, a quantidade deverá ser >=10.000 e <=17.000
                if( self.new_order.contract.CN9_ZTPVEI.trim() == '2' && ( Number(self.new_order.quantity) < 10000 || Number(self.new_order.quantity) > 17000 )  ){
                    self.new_order.quantity = 0;                    
                    this.$toast.error(
                        "A quantidade informada precisa estar entre 10.000 e 17.000, devido ao veículo parametrizado no contrato."
                    );
                    return false;
                }
                //3=Carreta ou Truck, a quantidade deverá ser >=10.000
                if( self.new_order.contract.CN9_ZTPVEI.trim() == '3' &&  Number(self.new_order.quantity) < 10000  ){
                    self.new_order.quantity = 0;                    
                    this.$toast.error(
                        "A quantidade informada precisa ser maior que 10.000, devido ao veículo parametrizado no contrato."
                    );
                    return false;
                }

            }
            return true;
        },
        verifyDeliveryDate() {
            const self = this;
            var dataInformada = new Date(this.new_order.delivery_date);
            var dataEspecificaStr = this.new_order.contract_expiration;
            var partesData = dataEspecificaStr.split("/");
            var dia = parseInt(partesData[0], 10);
            var mes = parseInt(partesData[1], 10) - 1;
            var ano = 2000 + parseInt(partesData[2], 10);

            var dataEspecifica = new Date(ano, mes, dia);

            if (dataInformada > dataEspecifica) {
                this.$swal
                    .fire({
                        title: "Data de Entrega Inválida!",
                        text:
                            "A data de entrega não pode ser superior ao vencimento do contrato: " +
                            this.new_order.contract_expiration,
                        icon: "error",
                        confirmButtonText: "OK",
                    })
                    .then((result) => {
                        self.new_order.delivery_date = "";
                    });
            }
        },
        selectStore(store) {
            const self = this;

            self.new_order.delivery_neighborhood = store.A1_BAIRRO.trim();
            self.new_order.store = store.A1_LOJA.trim();
            self.new_order.delivery_city = store.A1_MUN.trim();
            self.new_order.delivery_state = store.A1_EST.trim();
            self.new_order.delivery_address = store.A1_END.trim();
            self.new_order.zaplent = store.A1_ZAPLENT.trim();
        },
        scrollToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        changeValue() {
            const self = this;

            var CNB_ZUNFAT = self.new_order.contract.CNB_ZUNFAT;
            var CNB_SLDMED = self.new_order.contract.CNB_SLDMED;
            var CNB_ZCONV = self.new_order.contract.CNB_ZCONV;
            var CNB_ZTPCON = self.new_order.contract.CNB_ZTPCON;

            if (CNB_ZUNFAT.trim() != "") {
                if (CNB_ZTPCON == 1) {
                    var TOT = CNB_SLDMED * CNB_ZCONV;
                } else {
                    var TOT = CNB_SLDMED / CNB_ZCONV;
                }

                TOT = TOT.toString();

                if (Number(self.new_order.quantity) > Number(TOT.replace(".", ""))) {
                    self.new_order.quantity = 0;                    
                    this.$toast.error(
                        "A quantidade informada, é superior ao saldo do contrato"
                    );
                }


            } else {
                CNB_SLDMED = CNB_SLDMED.toString();

                if (
                    Number(self.new_order.quantity) > Number(CNB_SLDMED.replace(".", ""))
                ) {
                    self.new_order.quantity = 0;
                    this.$toast.error(
                        "A quantidade informada, é superior ao saldo do contrato"
                    );
                }
            }

            self.new_order.value =
                Number(self.new_order.quantity) * Number(self.new_order.unit_value);
        },
        formatVlUnit(obj) {
            var CNB_ZUNFAT = obj.CNB_ZUNFAT;
            var CNB_XVL2UN = obj.CNB_XVL2UN;
            var CNB_VLUNIT = obj.CNB_VLUNIT;
            var CNB_ZSEGUN = obj.CNB_ZSEGUN;

            if (CNB_ZUNFAT.trim() == "2") {
                var tot = CNB_XVL2UN;
            } else {
                var tot = CNB_VLUNIT;
            }

            return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 5,
            }).format(tot);
        },
        formatBalance(obj) {
            //Formata o saldo do contrato confirme regra de segunda unidade de medida
            var CNB_ZUNFAT = obj.CNB_ZUNFAT;
            var CNB_SLDMED = obj.CNB_SLDMED;
            var CNB_ZCONV = obj.CNB_ZCONV;
            var CNB_ZTPCON = obj.CNB_ZTPCON;

            if (CNB_ZUNFAT.trim() != "") {
                if (CNB_ZTPCON == 1) {
                    var TOT = CNB_SLDMED * CNB_ZCONV;
                } else {
                    var TOT = CNB_SLDMED / CNB_ZCONV;
                }
                console.log(TOT);
                return new Intl.NumberFormat("pt-BR", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 5,
                }).format(TOT);
            } else {
                return new Intl.NumberFormat("pt-BR", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 5,
                }).format(CNB_SLDMED);
            }
        },
        selectContract(contract) {
            const self = this;

            self.new_order.contract = contract;

            self.new_order.internal_contract = self.new_order.contract.CN9_NUMERO;

            self.checkCustomer(self.new_order.internal_contract);

            //Formata valor unitário conforme regra de segunda unidade de medida
            var CNB_ZUNFAT = self.new_order.contract.CNB_ZUNFAT;
            var CNB_XVL2UN = self.new_order.contract.CNB_XVL2UN;
            var CNB_VLUNIT = self.new_order.contract.CNB_VLUNIT;
            var CNB_ZSEGUN = self.new_order.contract.CNB_ZSEGUN;

            if (CNB_ZUNFAT.trim() == "2") {
                self.new_order.unit_value = CNB_XVL2UN;
            } else {
                self.new_order.unit_value = CNB_VLUNIT;
            }

            self.new_order.payment_condition = self.new_order.contract.E4_DESCRI;
            self.new_order.contract_expiration = self.new_order.contract.CN9_DTFIM;

            //Formata o saldo do contrato conforme regra de segunda unidade de medida
            var CNB_SLDMED = self.new_order.contract.CNB_SLDMED;
            var CNB_ZCONV = self.new_order.contract.CNB_ZCONV;
            var CNB_ZTPCON = self.new_order.contract.CNB_ZTPCON;

            if (CNB_ZUNFAT.trim() == "2") {
                if (CNB_ZTPCON == 1) {
                    var TOT = CNB_SLDMED * CNB_ZCONV;
                } else {
                    var TOT = CNB_SLDMED / CNB_ZCONV;
                }
                console.log(TOT);
                self.new_order.contract_balance = new Intl.NumberFormat("pt-BR", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 5,
                }).format(TOT);
            } else {
                self.new_order.contract_balance = new Intl.NumberFormat("pt-BR", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 5,
                }).format(CNB_SLDMED);
            }

            let filial = self.new_order.contract.CN9_FILIAL.split("-")[0].trim();

            let objFilial = self.branches.filter((item) => {
                return item.Filial == filial;
            });

            let nomeFilial = self.new_order.contract.CN9_FILIAL;
            if (objFilial) {
                nomeFilial += objFilial.Nome_Filial;
            }

            self.new_order.manufacturing_origin = self.new_order.contract.CN9_FILIAL;
            self.new_order.quantity = "";
            self.new_order.value = self.new_order.unit_value;

            self.new_order.all_days = false;
            self.new_order.monday = false;
            self.new_order.tuesday = false;
            self.new_order.wednesday = false;
            self.new_order.thursday = false;
            self.new_order.friday = false;
            self.new_order.saturday = false;
            self.new_order.sunday = false;
            self.new_order.holiday = false;
            self.new_order.delivery_type = self.new_order.contract.CN9_ZTPFRE;

            //Formata unidade de medida conforme regra de segunda unidade de medida
            var CNB_ZUNFAT = self.new_order.contract.CNB_ZUNFAT;
            var CNB_ZSEGUN = self.new_order.contract.CNB_ZSEGUN;
            var CNB_UM = self.new_order.contract.CNB_UM;
            if (CNB_ZUNFAT.trim() == "2") {
                self.new_order.und = CNB_ZSEGUN;
            } else {
                self.new_order.und = CNB_UM;
            }
        },
        selectProduct(obj) {
            const self = this;

            self.new_order = {};
            self.new_order.date = moment().format("YYYY-MM-DD");
            self.productSelected = obj;
            self.new_order.product = obj.B1_COD;
            self.new_order.product_obj = obj;
            self.new_order.unit_value = 0;
            self.getContracts(obj.B1_COD.trim());
            self.new_order.contract = "";
            self.new_order.internal_contract = "";
        },
        openModalProduto() {
            show_modal("modalProduto");
        },
        openModalPedido() {
            show_modal("modalConfirmarPedido");
        },
        openModalContrato() {
            show_modal("modalContrato");
        },
        closeModal() {
            close_modal("modalProduto");
        },
        closeModalContrato() {
            close_modal("modalContrato");
        },
        closeModalPedido() {
            close_modal("modalConfirmarPedido");
        },
        openModalLojas() {
            show_modal("modalLojas");
        },
        closeModalLojas() {
            close_modal("modalLojas");
        },
        searchCustomers(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        getCustomer() {
            const self = this;
            const api = this.$store.state.api + "get-customer/";

            axios
                .post(api)
                .then((response) => {
                    self.company = response.data.data.ZOOM[0];
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        getStores() {
            const self = this;
            const api = this.$store.state.api + "get-stores/";

            axios
                .post(api, self.filters)
                .then((response) => {
                    self.stores = response.data.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        getBranches() {
            const self = this;
            const api = this.$store.state.api + "branches/?paginated=false";

            axios
                .get(api, self.filters)
                .then((response) => {
                    self.branches = response.data.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        checkCustomer() {
            const self = this;
            const api = this.$store.state.api + "get-customer-risk/";

            axios
                .post(api, {order: self.new_order})
                .then((response) => {
                    self.risk = response.data.data;

                    if (self.risk != "") {
                        var msg = "";
                        if (self.risk == "risco") {
                            msg =
                                "Por gentileza entrar em contato com o Atendimento ao Cliente.";
                        } else if (self.risk == "validade") {
                            msg =
                                "Produto com certificado expirado, gentileza entrar em contato com o Atendimento ao Cliente.";
                        }
                        this.$swal
                            .fire({
                                title: "Não é possível realizar seu pedido!",
                                text: msg,
                                icon: "error",
                                confirmButtonText: "OK",
                            })
                            .then((result) => {
                                self.$router.push("/admin");
                            });
                    }
                    console.log(self.risk);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        getContracts(product) {
            const self = this;
            const api = this.$store.state.api + "contracts/filterByProduct";

            axios
                .post(api, {product_code: product})
                .then((response) => {
                    self.contracts = response.data.data.ZOOM;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },
        searchProduct(page) {
            const self = this;
            const api = this.$store.state.api + "contracts/products-for-order/";

            axios
                .get(api, {path: "/admin/contract-list"})
                .then((response) => {
                    console.log("response", response.data.data.length);
                    if (response.data.data.length != 0) {
                        const newArray = response.data.data.ZOOM;
                        self.products = newArray;
                    } else {
                        self.products = [];
                    }

                    if (self.products.length == 0) {
                        this.$swal
                            .fire({
                                title: "Não existe nenhum contrato ativo no momento.",
                                text: "Não existe nenhum contrato ativo para esta empresa/loja no momento, favor contatar o atendimento.",
                                icon: "error",
                                confirmButtonText: "OK",
                            })
                            .then((result) => {
                                self.$router.push("/admin");
                            });
                    }
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                });
        },

        saveForm(obj) {
            const self = this;
            let api = this.$store.state.api + "orders/save-form";

            if (obj == 1) {
                self.new_order.status = 1;
            } else {
                self.new_order.status = 2;
            }

            var CNB_ZUNFAT = self.new_order.contract.CNB_ZUNFAT;
            var CNB_SLDMED = self.new_order.contract.CNB_SLDMED;
            var CNB_ZCONV = self.new_order.contract.CNB_ZCONV;
            var CNB_ZTPCON = self.new_order.contract.CNB_ZTPCON;

            if (CNB_ZUNFAT.trim() != "") {
                if (CNB_ZTPCON == 1) {
                    var TOT = CNB_SLDMED * CNB_ZCONV;
                } else {
                    var TOT = CNB_SLDMED / CNB_ZCONV;
                }

                TOT = TOT.toString();

                if (Number(self.new_order.quantity) > Number(TOT.replace(".", ""))) {
                    self.new_order.quantity = 0;
                    this.$toast.error(
                        "A quantidade informada, é superior ao saldo do contrato"
                    );

                    return;
                }

                if(!self.validaTruck(self)){
                    return;
                }
            } else {
                CNB_SLDMED = CNB_SLDMED.toString();

                if (
                    Number(self.new_order.quantity) > Number(CNB_SLDMED.replace(".", ""))
                ) {
                    self.new_order.quantity = 0;
                    this.$toast.error(
                        "A quantidade informada, é superior ao saldo do contrato"
                    );

                    return;
                }

                if(!self.validaTruck(self)){
                    return;
                }
            }

            axios
                .post(api, self.new_order)
                .then((response) => {
                    self.acaoNaoSalva = false;
                })
                .then(() => {
                    self.$router.push("/admin/order-list-ni");
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);

                    self.loading = false;
                });
        },
        checkForm() {
            const self = this;
            const api = this.$store.state.api + "orders/check-form";

            var CNB_ZUNFAT = self.new_order.contract.CNB_ZUNFAT;
            var CNB_SLDMED = self.new_order.contract.CNB_SLDMED;
            var CNB_ZCONV = self.new_order.contract.CNB_ZCONV;
            var CNB_ZTPCON = self.new_order.contract.CNB_ZTPCON;

            if (CNB_ZUNFAT.trim() != "") {
                if (CNB_ZTPCON == 1) {
                    var TOT = CNB_SLDMED * CNB_ZCONV;
                } else {
                    var TOT = CNB_SLDMED / CNB_ZCONV;
                }

                TOT = TOT.toString();

                if (Number(self.new_order.quantity) > Number(TOT.replace(".", ""))) {
                    self.new_order.quantity = 0;
                    this.$toast.error(
                        "A quantidade informada, é superior ao saldo do contrato"
                    );

                    return;
                }
                if(!self.validaTruck(self)){
                    return;
                }
            } else {
                CNB_SLDMED = CNB_SLDMED.toString();

                if (
                    Number(self.new_order.quantity) > Number(CNB_SLDMED.replace(".", ""))
                ) {
                    self.new_order.quantity = 0;
                    this.$toast.error(
                        "A quantidade informada, é superior ao saldo do contrato"
                    );

                    return;
                }
                if(!self.validaTruck(self)){
                    return;
                }
            }

            axios
                .post(api, {order: self.new_order, step: self.step})
                .then((response) => {
                    console.log(response);
                    if (response.data == "NOK") {
                        this.$swal
                            .fire({
                                title:
                                    "Você já tem um pedido em andamento com estas características, tem certeza que deseja prosseguir?",
                                text: "Essa operação poderá gerar um pedido duplicado.",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Sim",
                                cancelButtonText: "Cancelar",
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    self.step++;
                                }
                            });
                    } else {
                        self.step++;
                    }
                })
                .catch((error) => {
                    var msg = error.response.data;

                    if (typeof msg === "object") {
                        let html = "<ul>";

                        $.each(msg, function (key, value) {
                            html += "<li>" + value[0] + "</li>";
                        });

                        html += "</ul>";

                        msg = html;
                    }

                    toaster.error(msg);

                    self.loading = false;
                });
        },
        getEntity(id) {
            const self = this;
            const api = this.$store.state.api + "orders-not-integrateds/" + id;

            self.loading = true;

            axios
                .get(api)
                .then((response) => {
                    self.new_order = response.data.data[0];
                    self.new_order.all_days = self.new_order.all_days == 1 ? true : false;
                    self.new_order.monday = self.new_order.monday == 1 ? true : false;
                    self.new_order.tuesday = self.new_order.tuesday == 1 ? true : false;
                    self.new_order.wednesday =
                        self.new_order.wednesday == 1 ? true : false;
                    self.new_order.thursday = self.new_order.thursday == 1 ? true : false;
                    self.new_order.friday = self.new_order.friday == 1 ? true : false;
                    self.new_order.saturday = self.new_order.saturday == 1 ? true : false;
                    self.new_order.sunday = self.new_order.sunday == 1 ? true : false;
                    self.new_order.holiday = self.new_order.holiday == 1 ? true : false;
                    if (self.new_order.observation == null) {
                        self.new_order.observation = "";
                    }
                    if (self.new_order.client_order == null) {
                        self.new_order.client_order = "";
                    }
                    self.getContract(self.new_order.internal_contract);
                    self.getProduct(self.new_order.product_code);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getContract(internal_contract) {
            const self = this;
            const api = this.$store.state.api + "contracts/get-contract-by-number";

            axios
                .post(api, {contract: internal_contract})
                .then((response) => {
                    self.new_order.contract = response.data.data.ZOOM[0];
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
        getProduct(product) {
            const self = this;
            const api = this.$store.state.api + "contracts/get-product-by-number";

            axios
                .post(api, {product: product})
                .then((response) => {
                    self.new_order.product = response.data.data.ZOOM[0].B1_COD;
                    self.new_order.product_obj = response.data.data.ZOOM[0];
                    self.loading = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message);
                    self.loading = false;
                });
        },
    },
    mounted() {
        const self = this;
        self.getCustomer();
        self.getStores();
        self.searchProduct(0);
        self.getBranches();

        self.acaoNaoSalva = true;

        let id = self.$route.params.id;

        if (id) {
            self.getEntity(id);
        }
    },
    beforeDestroy() {
    },
};
</script>
<style scoped>
label {
    font-weight: bold;
}

.card-title {
    font-size: 16px;
    margin-top: -15px;
    margin-bottom: -15px !important;
}

.card-header {
    background-color: #f1f1f1 !important;
    border: 1px solid #e1e1e1 !important;
}

.card-body {
    border: 1px solid #e1e1e1 !important;
}

.bg {
    background-color: blueviolet;
}

.bold {
    font-weight: bold;
    font-size: 16px;
    margin-top: 1px;
}

.toggleStyle {
    --toggle-width: 4rem !important;
    --toggle-height: 1.45rem !important;
    --toggle-font-size: 0.85rem !important;
    font-weight: bold;
}

#ModalProduto {
    padding: 15px;
}

#ModalPedido {
    padding: 15px;
}

.badge-primary {
    background-color: var(--primary);
    color: #fff;
}

.modal-dialog {
    overflow-y: initial !important;
}

.modal-content {
    max-height: 550px;
    overflow-y: auto !important;
}

.step-circle::before {
    /* content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -2px; */
    width: calc(5rem + 1rem - 2.5rem);
    /* height: 2px;
    transform: translate(-100%, -50%);
    color: #adb5bd;
    background-color: currentColor; */
}

.step-circle {
    /* position: relative;
      display: flex;
      justify-content: center;
      align-items: center; */
    width: 2.5rem;
    height: 2.5rem;
    /* color: #adb5bd;
      border: 2px solid #adb5bd;
      border-radius: 100%;
      background-color: #fff; */
}

.form-control:disabled,
.form-control[readonly] {
    background: rgba(147, 147, 147, 0.17);
    opacity: 1;
}

.form-check-input:disabled ~ .form-check-label {
    color: #7e7e7e !important;
}

.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
    opacity: 100 !important;
}

.required {
    color: red;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
