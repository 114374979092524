<template>
  <div class="container-fluid">
    <base-table
      :ref="table"
      :filters="filters"
      :crudName="crudName"
      :newText="newText"
      :table="table"
      :endPoint="endPoint"
      :enableAdd="false"
      :enableEdit="true"
      :enableDelete="true"
      :enableSaveColumns="true"
      :enableExtract="true"
      :layoutNames="layoutNames"
      :relationships="relationships"
      :extras="extras"
      :orders="orders"
      :columns="columns"
      :styleHeader="'border-bottom: 5px solid #68CF29'"
      @contrato="contrato"
    ></base-table>
  </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
export default {
  data() {
    return {
      loading: false,
      branches: [],
      selectedContract: null,
      extras: [],
      orders: [
        {
          name: "",
          column: "",
        },
        {
          name: "Nº da Negociação",
          column: "CN9_NUMERO",
          selected: true,
        },
        {
          name: "Produto",
          column: "CNB_DESCRI",
        },
        {
          name: "Data Inicial",
          column: "CN9_DTINIC",
        },
        {
          name: "Data Final",
          column: "CN9_DTFIM",
        },
      ],
      filters: [
        {
          id: "CN9_NUMERO",
          name: "CN9_NUMERO",
          label: "Nº da Negociação",
          value: "",
          type: "contract",
          class: "form-control",
          columnSize: "col-3",
        },
        {
          name: "CN9_ZCONCL",
          label: "N° Contrato do cliente",
          value: "",
          type: "text",
          applied: false,
          class: "form-control ",
          columnSize: "col-3",
        },
        {
          id: "CNB_DESCRI",
          name: "CNB_DESCRI",
          product_column: "CNB_PARPRO",
          label: "Produto",
          value: "",
          type: "product",
          class: "form-control",
          columnSize: "col-3",
        },
        {
          id: "CN9_DTINIC",
          name: "CN9_DTINIC",
          label: "Data Inicio",
          value: "",
          type: "date",
          class: "form-control",
          columnSize: "col-3",
        },
        {
          id: "CN9_DTFIM",
          name: "CN9_DTFIM",
          label: "Data Fim",
          value: "",
          type: "date",
          class: "form-control",
          columnSize: "col-3",
        },
        {
          id: "CN9_FILIAL",
          name: "CN9_FILIAL",
          label: "Filial",
          value: "",
          type: "branch",
          columnSize: "col-6",
        },
      ],
      crudName: "Contratos",
      newText: "",
      table: "contract-list",
      endPoint: "contracts/",
      relationships: [],
      columns: [],
    };
  },
  components: {
    BaseTable,
  },
  methods: {
    setColumns(){
      this.columns.push(
        {
          label: "Detalhe",
          type: "buttonLabel",
          callbackFunction: "contrato",
          class: "btn btn-info btn-sm",
          canHide: false,
          condition: function (row) {
            return false;
          },
          labelCallback: function (row) {
            return " + ";
          },
          conditionShow: function (row) {
            return true;
          },
          reportColumn: null,
        },
        {
          //Foi feito uma gambiarra para ajustar o nome do produto, pois quando existe caracter especial, o Protheus não responde corretamente, portanto o campo CNB_DESCRI tbm está sendo passado como um AS para CNB_PARPRO
          label: "Produto",
          field: "CNB_PARPRO",
          reportColumn: { COLUNA: 'PRODUTO', CAMPO: 'B1_DESC'},
        },
        {
          label: "Valor unitário",
          callback: function (row) {
            var CNB_ZUNFAT = row.CNB_ZUNFAT;

            var CNB_XVL2UN = row.CNB_XVL2UN;
            var CNB_VLUNIT = row.CNB_VLUNIT;

            if (CNB_ZUNFAT.trim() != "") {
              return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              }).format(CNB_XVL2UN);
            } else {
              return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 5,
                maximumFractionDigits: 5,
              }).format(CNB_VLUNIT);
            }            
          },
          reportColumn: { COLUNA: 'VALOR UNITARIO', CAMPO: 'VAZIO'},
        },
        {
          label: "Contrato do cliente",
          field: "CN9_ZCONCL",
          whitespace: true,
          reportColumn: { COLUNA: 'CONTRATO DO CLIENTE', CAMPO: 'CN9_ZCONCL'},
        },
        {
          label: "Data inicial",
          field: "CN9_DTINIC",
          reportColumn: { COLUNA: 'DATA INICIAL', CAMPO: 'CN9_DTINIC'},
        },
        {
          label: "Data final",
          field: "CN9_DTFIM",
          reportColumn: { COLUNA: 'DATA FINAL', CAMPO: 'CN9_DTFIM'},
        },
        {
          label: "Dias para vencimento",
          callback: function (row) {
            let diff_in_days = moment(row.CN9_DTFIM, "DD/MM/YYYY").diff(
              moment().format("YYYY-MM-DD"),
              "days"
            );
            return Math.floor(diff_in_days);
          },
          reportColumn: { COLUNA: 'DIAS PARA VENCIMENTO', CAMPO: 'VAZIO'},
        },
        {
          label: "% de prazo",
          callback: function (row) {
            if (row) {
              let start_date_moment = moment(row.CN9_DTINIC, "DD/MM/YYYY");
              let end_date_moment = moment(row.CN9_DTFIM, "DD/MM/YYYY");

              let total_days = start_date_moment.diff(end_date_moment, "days");

              var currentDate = moment();

              let completed_days = start_date_moment.diff(currentDate, "days");

              let completed_days_percentage =
                (completed_days * 100) / total_days;
              completed_days_percentage = completed_days_percentage.toFixed(2);

              let return_percentage =
                completed_days_percentage > 100
                  ? 100
                  : completed_days_percentage;

              return return_percentage.toString().replace(".", ",");
            } else {
              return "";
            }
          },
          reportColumn: { COLUNA: '% DE PRAZO', CAMPO: 'VAZIO'},
          
        },
        {
          label: "Volume inicial",
          callback: function (row) {
            var CNB_ZQTDSE = row.CNB_ZQTDSE;
            var CNB_ZUNFAT = row.CNB_ZUNFAT;
            var CNB_QUANT = row.CNB_QUANT;

            if (CNB_ZUNFAT.trim() != "") {
              return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(CNB_ZQTDSE);
            } else {
              return new Intl.NumberFormat("pt-BR", {
                style: "decimal",
                currency: "BRL",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(CNB_QUANT);
            }
          },
          reportColumn: { COLUNA: 'VOLUME INICIAL', CAMPO: 'VAZIO'},
        },       
        {
          label: "Volume consumido",
          callback: function (row) {
            if (row) {
              var CNB_ZUNFAT = row.CNB_ZUNFAT;

              var CNB_ZCONV = row.CNB_ZCONV;
              var CNB_QTDMED = row.CNB_QTDMED;
              var CNB_ZTPCON = row.CNB_ZTPCON;
              var CNB_QUANT = row.CNB_QUANT;

              if (CNB_ZUNFAT.trim() != "") {
                if (CNB_ZTPCON == 1) {
                  return new Intl.NumberFormat("pt-BR", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(CNB_QTDMED * CNB_ZCONV);
                } else {
                  return new Intl.NumberFormat("pt-BR", {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(CNB_QTDMED);
                }
              } else {
                return new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(CNB_QTDMED);
              }
            } else {
              return "";
            }
          },
          reportColumn: { COLUNA: 'VOLUME CONSUMIDO', CAMPO: 'VAZIO'},
        },
        // CNB_QTDMED
        {
          label: "Saldo de volume",
          callback: function (row) {
            if (row) {
              var CNB_ZUNFAT = row.CNB_ZUNFAT;
              var CNB_SLDMED = row.CNB_SLDMED;
              var CNB_ZQTDSE = row.CNB_ZQTDSE;
              var CNB_ZCONV = row.CNB_ZCONV;
              var CNB_QTDMED = row.CNB_QTDMED;
              var CNB_ZTPCON = row.CNB_ZTPCON;
              var CNB_QUANT = row.CNB_QUANT;

              if (CNB_ZUNFAT.trim() != "") {
                if (CNB_ZTPCON == 1 && CNB_ZCONV.trim != 0) {
                  var TOT = CNB_SLDMED * CNB_ZCONV;
                } else {
                  var TOT = CNB_SLDMED;
                }
                return new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(TOT);
              } else {
                return new Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(CNB_SLDMED);
              }
            } else {
              return "";
            }
          },
          reportColumn: { COLUNA: 'SALDO DE VOLUME', CAMPO: 'VAZIO'},
        },
        {
          label: "Unidade de medida",
          callback: function (row) {
            var CNB_ZUNFAT = row.CNB_ZUNFAT;
            var CNB_ZSEGUN = row.CNB_ZSEGUN;
            var CNB_UM = row.CNB_UM;
            if (CNB_ZUNFAT.trim() != "") {
              return CNB_ZSEGUN;
            } else {
              return CNB_UM;
            }
          },
          reportColumn: { COLUNA: 'UNIDADE DE MEDIDA', CAMPO: 'VAZIO'},
        },
        {
          label: "% consumido",
          callback: function (row) {
            if (row) {
              var CNB_QTDMED = row.CNB_QTDMED;
              var CNB_QUANT = row.CNB_QUANT;

              if (CNB_QTDMED == 0 || CNB_QUANT == 0) {
                return "0,00";
              }

              let percentage = (CNB_QTDMED * 100) / CNB_QUANT;
              let percentage_fixed = percentage.toFixed(2);

              return percentage_fixed.replace(".", ",");
            } else {
              return "";
            }
          },
          reportColumn: { COLUNA: '% CONSUMIDO', CAMPO: 'VAZIO'},
        },
        {
          label: "Filial",
          callback: function (row) {
            if (row) {
              var string = row.CN9_FILIAL;
              string = string.substring(string.indexOf(" - ") + 2);
              return string;
            } else {
              return "";
            }
          },
          reportColumn: { COLUNA: 'FILIAL', CAMPO: 'M0_FILIAL'},
        },
        {
          label: "Nº da Negociação",
          field: "CN9_NUMERO",
          reportColumn: { COLUNA: 'NUM DA NEGOCIACAO', CAMPO: 'CN9_NUMERO'},
        }
      );
    },
    contrato(data) {
      data = btoa(
        JSON.stringify({
          numero: data.CN9_NUMERO,
          filial: data.CN9_FILIAL.slice(0, 7),
        })
      );
      this.$router.push("/admin/contract/" + data);
    },
    encode(string) {
      return btoa(string);
    },
  },
  mounted() {
    this.setColumns();
  },
};
</script>