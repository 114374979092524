<template>
    <div>
        <div class="row" style="overflow: hidden">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="col text-center" v-if="spinner">
                                    <span class="fa fa-spinner fa-spin fa-5x"></span>
                                </div>
                                <div v-else>
                                    <h5>Filiais</h5>
                                    <hr>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item" v-for="(titles, name, index) in groupedWinners"
                                            :key="name">
                                            <h2 class="accordion-header" :id="'headingOne' + index">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapse' + index" aria-expanded="true"
                                                    :aria-controls="'collapse' + index">
                                                    {{ name }}
                                                </button>
                                            </h2>
                                            <div :id="'collapse' + index" class="accordion-collapse collapse "
                                                :aria-labelledby="'headingOne' + index"
                                                data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <ul class="list-group">
                                                        <li class="list-group-item" v-for="title in titles"
                                                            :key="title.id">
                                                            {{ title.title }}
                                                            <router-link :to="'winner-docs-view/' + title.id">
                                                                <button
                                                                    class="btn btn-primary float-right">Baixar</button>
                                                            </router-link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
import axios from "axios";

const toaster = createToaster({
    position: "top-right",
});

export default {
    data() {
        return {
            winners: [],
            spinner: true,
        };
    },
    computed: {
        groupedWinners() {
           
            const grouped = this.winners.reduce((acc, winner) => {
                if (!acc[winner.name]) {
                    acc[winner.name] = [];
                }
                acc[winner.name].push({ id: winner.id, title: winner.title });
                return acc;
            }, {});

           
            const sortedGrouped = Object.keys(grouped)
                .sort((a, b) => a.localeCompare(b))
                .reduce((acc, key) => {
                    acc[key] = grouped[key];
                    return acc;
                }, {});

            return sortedGrouped;
        },
    },
    methods: {
        async getWinners() {
            const self = this;
            const api = this.$store.state.api + "winner-docs/customer-documents";
            if (!self.$store.state.user.customer_id) {
                setTimeout(() => {
                    this.getWinners();
                }, 300);
                return;
            }

            axios
                .get(api)
                .then((response) => {
                    self.winners = response.data;
                })
                .catch((e) => {
                    self.winners = [];
                    console.error(e);
                })
                .finally(() => {
                    self.spinner = false;
                });
        },
    },
    mounted() {
        this.getWinners();
    },
};
</script>

<style scoped>
.card {
    margin: 45px;
}

#ModalNovo {
    padding: 15px;
}
</style>
