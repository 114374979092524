<template>
  <!-- style="background-color: rgb(229, 242, 250);" -->
  <div class="dashboard-chart">
    <vue3-chart-js
      :id="id"
      ref="chartRef"
      :type="type"
      :data="data"
      :options="options"
      :height="height ? height : 'auto'"
      :width="width ? width : 'auto'"
    >
    </vue3-chart-js>
  </div>
</template>

<script>
// import Chart from "../Layouts/Chart.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";


export default {
  data() {
    return {
      id: "chart",
    };
  },
  props: ["type", "height", "width", "data", "options"],
  mounted() {},
  methods: {},
  components: {
    Vue3ChartJs,
  },
};
</script>

