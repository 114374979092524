<template>
  <div class="container-fluid">
    <base-table
      :ref="table"
      :filters="filters"
      :crudName="crudName"
      :newText="newText"
      :table="table"
      :endPoint="endPoint"
      :enableAdd="false"
      :enableEdit="false"
      @edit="edit"
      :enableDelete="false"
      :relationships="relationships"
      :extras="extras"
      :columns="columns"
      :enableExtract="false"
      :orders="orders"
    ></base-table>
  </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
export default {
  data() {
    return {
      crudName: "Clientes",
      filters: [
        {
          id: "id",
          name: "id",
          label: "ID",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
        {
          id: "id",
          name: "name",
          label: "Nome da Empresa",
          value: "",
          type: "text",
          class: "form-control",
          columnSize: "col-4",
        },
      ],
      newText: "Novo Cliente",
      table: "customer-list",
      endPoint: "customers/",
      relationships: [],
      extras: ["A1_MSBLQL=2"],
      orders: [],
      columns: [
        {
          label: "ID",
          field: "id",
        },
        {
          label: "Nome",
          field: "name",
        },
        {
          label: "Código",
          field: "code",
        },
        {
          label: "Exibe Boleto?",
          callback: function (row) {
            if (row.exibir_boleto == "1") {
              return "<span class='badge badge-success'>Sim</span>";
            }
            if (row.exibir_boleto == "0") {
              return "<span class='badge badge-danger'>Não</span>";
            }
          },
        },
        {
          label: "Editar",
          type: "button",
          callbackFunction: "edit",
          class: "btn btn-info btn-sm",
          condition: function (row) {
            return false;
          },
          conditionShow: function (row) {
            return true;
          },
        },
      ],
    };
  },
  components: {
    BaseTable,
  },
  methods: {
    edit(row) {
      this.$router.push("/admin/customer/" + row.id);
    },
  },
};
</script>