<template>
    <div class="container-fluid">
        <button @click="$router.go(-1)" class="btn btn-primary mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'arrow-left']"/>
            Voltar
        </button>
        <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
            <font-awesome-icon :icon="['fa', 'retweet']"/>
            Atualizar
        </button>
        <div>
            <div class="detail-container" v-if="loaded">
                <div class="row">
                    <div class="col-md-12">
                        <iframe
                            width="100%"
                            height="1000"
                            :src="
                'data:application/pdf;base64,' + encodeURI(data.base64)
              "
                        ></iframe>

                        {{encodeURI(data.base64)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loaded: false,
            data: {},
        };
    },
    methods: {
        getDetail() {
            const self = this;
            self.loaded = false;
            console.log('params: ', self.$route.params.params)
            let data = JSON.parse(atob(self.$route.params.params));

            if(data.banco != '237'){

                var api = self.$store.getters.api + "invoices/ticket";
                console.log(data);
    
                axios
                    .post(api, data)
                    .then((response) => {
                        self.data = response.data[0];
                        setTimeout(() => {
                            console.log(data.base64)
                        }, 500)
                        self.loaded = true;
                    })
                    .catch(() => {
                        this.$toast.error(
                            "Documento não disponível no momento, favor tente novamente."
                        );
                    });
            } else {
                var api = self.$store.getters.api + "invoices/boleto?params=" + self.$route.params.params;                
    
                axios
                    .get(api, data)
                    .then((response) => {
                        self.data = response.data[0];
                        setTimeout(() => {
                            console.log(data.base64)
                        }, 500)
                        self.loaded = true;
                    })
                    .catch(() => {
                        this.$toast.error(
                            "Documento não disponível no momento, favor tente novamente."
                        );
                    });
            }
        },
    },

    mounted() {
        const self = this;

        self.getDetail();
    },
};
</script>
