import { createApp } from 'vue'
import App from '@/App.vue'

const app = createApp(App)

import 'bootstrap'
import axios from "./axios";
import moment from 'moment'
import jquery from 'jquery'
import Maska from 'maska'
import Toast from "vue-toastification"
import { useToast } from 'vue-toastification'
import "vue-toastification/dist/index.css"
import 'vue-select/dist/vue-select.css';

import Router from './router.js'
import Store from './store.js'

// import { vSelect } from 'vue-select';
import VueGoogleMaps from 'vue-google-maps-community-fork'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

app.config.globalProperties.$toast = useToast()

window.axios = axios
window.moment = moment
window.$ = jquery

window.show_modal = function (div_name) {

    $('html').css('overflow', 'hidden')
    $('#' + div_name + ' .modal-fade-background').css('display', 'block')
    $('#' + div_name + ' .modal').css('display', 'block')
}

window.close_modal = function (div_name) {
    $('html').css('overflow', 'auto')
    $('#' + div_name + ' .modal-fade-background').css('display', 'none')
    $('#' + div_name + ' .modal').css('display', 'none')
}

moment.locale('pt-br');

import '@/assets/styles/bootstrap.min.css'
import '@/assets/styles/main.css'
import '@/assets/icons/bootstrap-icons.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPenAlt, faRetweet } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

import 'vue-select/dist/vue-select.css';


library.add(faPenAlt);
library.add(faArrowLeft);
library.add(faFile);
library.add(faFileAlt);
library.add(faTrashAlt);
library.add(faChevronRight);
library.add(faChevronLeft);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faFilter);
library.add(faFileExcel);
library.add(faSort);
library.add(faEye);
library.add(faArrowDown);
library.add(faArrowUp);
library.add(faArrowRight);
library.add(faTruck);
library.add(faExclamationTriangle);
library.add(faChartLine);
library.add(faCopy);
library.add(faSave);
library.add(faCog);
library.add(faPlus);
library.add(faMinus);
library.add(faTimes);
library.add(faTrash);
library.add(faDollarSign);
library.add(faRetweet);
library.add(faDownload);
library.add(faSearch);
library.add(faArrowsAlt);
library.add(faMapMarkedAlt);
library.add(faBarcode);

Store.dispatch('initialize')

axios.interceptors.response.use(function (response) {
    const token = localStorage.getItem('token')
    const token_expire = localStorage.getItem('token_expire')

    if (token) {
        var m = moment();
        var isafter = m.isAfter(moment(token_expire));
    }

    if (response.status == 200) {
        localStorage.setItem('token_expire', moment().add(30, 'minutes').format('Y-MM-DD HH:mm:ss'));
    }

    if (response == undefined) {
        window.location.href = "/";
    }
    return response;
}, function (error) {
    var url = window.location.href;
    var parts = url.split('/');
    var route = parts[parts.length - 1];

    if (error.response.status == 401) {
        if (route != login) {
            window.location.href = "/";
        }
    }
    return Promise.reject(error);
});

app
    .use(Router)
    .use(Store)
    .use(Maska)
    .use(VueSweetalert2)
    .use(Toast)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component('Datepicker', Datepicker)
    .use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAVReyDOd0JcVSHy1CvERGbMXbqA1pkiZA',
        },
    })
    .mount('#app')
