<template>
  <router-view></router-view>
</template>

<script>
import env from "./env.js";
export default {
  name: "App",
  created() {
    document.title = env.applicationName;
  },
};
</script>