<template>
  <div class="dashboard-chart" style="zoom: 100% !important;max-height: 350px;display: inline;">
    <!-- <Chart :id="id" :type="type" :data="data" :options="options"></Chart> -->
    <div class="col-12" v-if="!$store.state.chartsAmountMonthLoaded">
      <div class="card-body text-center">
        <span class="fa fa-spinner fa-spin fa-5x"></span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 centered">
        <div class="alert alert-primary" role="alert">
          Você pode ocultar barras do gráfico clicando na legenda abaixo.
        </div>
      </div>
    </div>
    <div
      class="row justify-content-center"
      v-if="$store.state.chartsAmountMonthLoaded"
    >
      <div class="col-md-2 clickable" @click="ocultarQuantidade">
        <div class="retangulo" :style="'background-color: red'">
          <span :class="data.datasets[1].hidden ? 'tachar' : ''"
            >Quantidade</span
          >
        </div>
      </div>
      <div class="col-md-1 clickable" @click="ocultarValor">
        <div
          class="retangulo"
          :style="'background-color: ' + env2.chartBarBackgroundColor"
        >
          <span :class="data.datasets[0].hidden ? 'tachar' : ''">Valor</span>
        </div>
      </div>
    </div>
    <vue3-chart-js
      v-if="$store.state.chartsAmountMonthLoaded"
      :id="id"
      ref="chartRef"
      :type="type"
      :data="data"
      :options="options"
    >
    </vue3-chart-js>
  </div>
</template>

<script>
// import Chart from "../Layouts/Chart.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import env from "../../env.js";

export default {
  data() {
    return {
      id: "chart",
      loading: false,
      env2: {
        chartBarBackgroundColor: "#222",
      },
      data: {
        labels: [
          "Julho/21",
          "Agosto/21",
          "Setembro/21",
          "Outubro/21",
          "Novembro/21",
          "Dezembro/21",
          "Janeiro/22",
        ],
        datasets: [
          {
            label: "Valor",
            type: "bar",
            backgroundColor: env.chartBarBackgroundColor,
            data: [],
            order: 1,
            yAxisID: "yA",
          },
          {
            label: "Quantidade",
            type: "line",
            backgroundColor: ["red"],
            data: [],
            order: 0,
            borderColor: "red",
            yAxisID: "yB",
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          yA: {
            type: "linear",
            position: "left",
            beginAtZero: true,
          },
          yB: {
            type: "linear",
            position: "right",
            beginAtZero: true,
            ticks: {
              max: 1,
              min: 0,
            },
          },
        },
      },
    };
  },
  props: ["contract"],

  mounted() {
    this.env2 = env;
    this.getChartData();
    console.log("loaded?", this.$store.state.chartsAmountMonthLoaded);
  },
  beforeUnmount() {
    this.$store.dispatch("setLoadingChartAmount", false);
  },
  methods: {
    ocultarQuantidade() {
      this.data.datasets[1].hidden = !this.data.datasets[1].hidden;
      this.$refs.chartRef.update();
    },
    ocultarValor() {
      this.data.datasets[0].hidden = !this.data.datasets[0].hidden;
      this.$refs.chartRef.update();
    },
    getChartData() {
      let self = this;
      self.loading = true;

      let filters = "";
      if (this.contract) {
        filters =
          "CN9_FILIAL=" +
          this.contract.CN9_FILIAL.substr(0, 7) +
          "&CN9_NUMERO=" +
          this.contract.CN9_NUMERO +
          "&CN9_DTINIC=" +
          this.contract.CN9_DTINIC;
      }

      let api = self.$store.getters.api + "contracts/chartValue?" + filters;
      axios
        .get(api)
        .then((response) => {
          var data = response.data.data;
          self.data.labels = data.labels;
          self.data.datasets[0].data = data.data;
          self.data.datasets[0].datalabels = {
            color: "#0d0d0d",
            font: {
              weight: "bold",
              size: 9,
            },
            align: 100,
            clamp: true,
            backgroundColor: "",
            formatter: function (value, context) {
              if (value != 0) {
                return (
                  "R$ " +
                  value.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                );
              } else {
                return "";
              }
            },
          };
          self.$emit("setLastMonthValue", data.data[data.data.length - 1]);
          self.$emit(
            "setPenultimateMonthValue",
            data.data[data.data.length - 2]
          );
        })
        .then(() => {
          // self.$refs.chartRef.update(250)
        })
        .then(() => {
          let self = this;

          let filters = "";
          if (this.contract) {
            filters =
              "CN9_FILIAL=" +
              this.contract.CN9_FILIAL.substr(0, 7) +
              "&CN9_NUMERO=" +
              this.contract.CN9_NUMERO +
              "&CN9_DTINIC=" +
              this.contract.CN9_DTINIC;
          }

          let api = self.$store.getters.api + "contracts/chart?" + filters;
          axios
            .get(api)
            .then((response) => {
              var data = response.data.data;
              self.data.datasets[1].data = data.data;
              self.$emit("setLastMonthAmount", data.data[data.data.length - 1]);
              self.$emit(
                "setPenultimateMonthAmount",
                data.data[data.data.length - 2]
              );
            })
            .then(() => {
              // self.$refs.chartRef.update(250);
            });
        })
        .then(() => {
          setTimeout(function () {
            self.loading = false;
            // self.$store.dispatch("setLoadingChartAmount", true);
            // alert("setou aqui");
          }, 5000);
        });
    },
  },
  components: {
    Vue3ChartJs,
  },
};
</script>

<style scoped>
.retangulo {
  display: inline-flex;
  align-items: center;
  width: 50px;
  height: 20px;
  font-size: 16px;
}

.retangulo span {
  margin-left: 58px; /* Espaçamento entre o retângulo e o texto */
}

.tachar {
  text-decoration: line-through;
}
</style>
