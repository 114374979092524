<template>
    <div class="container-fluid">
        <base-table
            :ref="table"
            :filters="filters"
            :crudName="crudName"
            :newText="newText"
            :table="table"
            :endPoint="endPoint"
            :enableAdd="false"
            :enableEdit="true"
            :enableDelete="true"
            :enableSaveColumns="true"
            :enableExtract="true"
            :layoutNames="layoutNames"
            :relationships="relationships"
            :extras="extras"
            :orders="orders"
            :columns="columns"
            @fispq="fispq"
            @report="report"
            @tracking="tracking"
            :styleHeader="'border-bottom: 5px solid #51A6F5'"
            @danfe="danfe"
        ></base-table>
    </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
import env from "../../env.js";

export default {
    data() {
        return {
            loaded: false,
            extras: [],
            orders: [
                {
                    name: "",
                    column: "",
                },
                {
                    name: "Nº do Pedido",
                    column: "C5_NUM",
                },
                {
                    name: "Pedido do Cliente",
                    column: "C6_NUMPCOM",
                },
                {
                    name: "Local de Entrega",
                    column: "A1_ZAPLENT",
                },
                {
                    name: "Previsão de Entrega",
                    column: "C6_ENTREG",
                },
            ],
            filters: [
                {
                    id: "invoiceType",
                    type: "hidden",
                    name: "status",
                    label: "Todos",
                    value: "all",
                    checked: true,
                },
                {
                    id: "concluidos",
                    type: "hidden",
                    name: "concluidos",
                    label: "Concluidos",
                    value: "true",
                    checked: true,
                },
                // {
                //   id: "invoiceType",
                //   type: "radio",
                //   name: "status",
                //   label: "Faturados",
                //   value: "billed",
                // },
                // {
                //   id: "invoiceType",
                //   type: "radio",
                //   name: "status",
                //   label: "Pendentes",
                //   value: "unbilled",
                // },
                {
                    name: "C5_NUM",
                    label: "N° do Pedido",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "C6_NUMPCOM",
                    label: "Pedido do Cliente",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "C5_MDCONTR",
                    label: "Nº da Negociação",
                    value: "",
                    type: "contract",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "B1_DESC",
                    product_column: "B1_ESPECIE",
                    label: "Produto",
                    value: "",
                    type: "product",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3 mt-2",
                },
                // {
                //     name: "C5_EMISSAO",
                //     label: "Data de Emissão",
                //     value: "",
                //     type: "date",
                //     applied: false,
                //     class: "form-control",
                //     columnSize: "col-3 ",
                // },
                {
                    name: "C6_ENTREG",
                    label: "Previsão de Entrega",
                    value: "",
                    type: "date",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3 ",
                },
                {
                    name: "A1_ZAPLENT",
                    label: "Local de Entrega",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "CN9_SITUAC",
                    label: "Status",
                    value: "",
                    type: "select",
                    options: {
                        "": "",
                        "01": "Cancelado",
                        "02": "Elaboração",
                        "03": "Emitido",
                        "04": "Aprovado",
                        "05": "Vigente",
                        "06": "Paralisado",
                        "07": "Solicitação Finalizada",
                        "08": "Finalizado",
                        "09": "Revisão",
                        10: "Revisado",
                        A: "Aprovado Revisão",
                    },
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                // {
                //     column: "text",
                //     label: "Ativos/Finalizados",
                //     value: "",
                //     type: "text",
                //     applied: false,
                // },
            ],
            crudName: "Pedidos Entregues",
            newText: "",
            table: "order-list-finished",
            endPoint: "orders/",
            relationships: [],
            columns: [],
        };
    },
    components: {
        BaseTable,
    },
    methods: {
        tracking(data) {
            var dtEntrega = data.C5_ENTREAL.split("/");

            console.log(dtEntrega);

            var ano = dtEntrega[2];
            var mes = dtEntrega[1];
            var dia = dtEntrega[0];

            if (ano.length == 2) {
                ano = "20" + ano;
            }

            dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

            var prevEntrega = data.C6_ENTREG.split("/");

            var ano_p = prevEntrega[2];
            var mes_p = prevEntrega[1];
            var dia_p = prevEntrega[0];

            if (ano_p.length == 2) {
                ano_p = "20" + ano_p;
            }

            prevEntrega = new Date(ano_p + "-" + mes_p + "-" + dia_p + " 03:00:00");

            var today = new Date();

            var yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            if (
                (dtEntrega != "" && dtEntrega < today) ||
                (prevEntrega != "" && prevEntrega < yesterday)
            ) {
                data = btoa(
                    JSON.stringify({
                        F2_CHVNFE: data.F2_CHVNFE,
                        nota_fiscal: data.C5_NOTA.trim(),
                    })
                );

                this.$router.push("/admin/delivery/canhoto/" + data);
            }
        },
        fispq(data) {
            data = btoa(
                JSON.stringify({
                    nome_produto: data.B1_DESC,
                })
            );
            this.$router.push("/admin/order/fispq/" + data);
        },
        report(data) {
            const obj = {
                filial: data.F2_FILIAL,
                doc: data.F2_DOC,
                serie: data.F2_SERIE
            }
            data = btoa(
                JSON.stringify({
                    nota_fiscal: data.F2_DOC.trim(),
                })
            );
            this.$store.dispatch("setOrderReport", obj);
            this.$router.push("/admin/order/report/" + data);
        },
        encode(string) {
            return btoa(string);
        },
        danfe(data) {
            const self = this;

            if (data.C5_NOTA.trim() && data.C5_LOJAENT.trim()) {
                data = btoa(
                    JSON.stringify({
                        nota_fiscal: data.C5_NOTA.trim(),
                        loja_ent: data.C5_LOJAENT.trim(),
                        contrato: data.C5_MDCONTR.trim(),
                        filial: data.C5_FILIAL.trim(),
                    })
                );
                this.$router.push("/admin/invoice/danfe/" + data);
                // window
                //   .open(
                //     self.$store.state.api +
                //       "invoices/danfe/" +
                //       data.E1_NUM.trim() +
                //       "/" +
                //       data.C5_LOJAENT.trim() +
                //       "/" +
                //       data.C5_MDCONTR,
                //     "_blank"
                //   )
                //   .focus();
            } else {
                alert("DANFE não disponível para esse título");
            }
        },
        setColumns() {
            if (env.isAmbientaly) {
                this.columns.push(
                    {
                        label: "Laudo",
                        type: "button",
                        callbackFunction: "report",
                        class: "btn btn-info btn-sm",
                        condition: function (row) {
                            if (['0650004','0650001','0650002','0650009','0650008'].indexOf(row.F2_FILIAL) > -1) {
                                if (row.C5_NOTA.trim() == "") {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        },
                        conditionShow: function (row) {
                            if (['0650004','0650001','0650002','0650009','0650008'].indexOf(row.F2_FILIAL) > -1) {
                                if (row.C5_NOTA.trim() == "") {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    },
                )
            }
            this.columns.push(
                
                {
                    label: "Canhoto",
                    labelCallback: function (row) {
                        var placa = row.DAK_VEIC2;
                        var placaCavalo = row.DA3_PLACA;
                        var transp = row.A4_NOME;
                        var filial = row.F2_FILIAL.trim();

                        var devolucao = row.D2_QTDEDEV;

                        if (devolucao > 0) {
                            return "NF DEVOLVIDA";
                        }

                        // var dtEntrega = row.C5_ENTREAL;
                        // if (dtEntrega == "  /  /  ") {
                        //   return "Rastreamento Indisponível";
                        // }

                        var prevEntrega = row.C6_ENTREG.split("/");

                        var ano_p = prevEntrega[2];
                        var mes_p = prevEntrega[1];
                        var dia_p = prevEntrega[0];

                        if (ano_p.length == 2) {
                            ano_p = "20" + ano_p;
                        }

                        prevEntrega = new Date(
                            ano_p + "-" + mes_p + "-" + dia_p + " 03:00:00"
                        );

                        var dtEntrega = row.C5_ENTREAL.split("/");

                        var ano = dtEntrega[2];
                        var mes = dtEntrega[1];
                        var dia = dtEntrega[0];

                        if (ano.length == 2) {
                            ano = "20" + ano;
                        }

                        dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

                        // quando vem vazio trocamos pela previsao
                        if (mes.trim() == '') {
                            dtEntrega = prevEntrega
                        }

                        var dtEmissao = row.F2_EMISSAO.split("/");

                        var ano_e = dtEmissao[2];
                        var mes_e = dtEmissao[1];
                        var dia_e = dtEmissao[0];

                        if (ano_e.length == 2) {
                            ano_e = "20" + ano_e;
                        }

                        dtEmissao = new Date(
                            ano_e + "-" + mes_e + "-" + dia_e + " 03:00:00"
                        );

                        dia = parseInt(dia);

                        var today = new Date();

                        var yesterday = new Date(today);
                        yesterday.setDate(today.getDate() - 1);

                        var six_months = new Date(today);
                        six_months.setMonth(six_months.getMonth() - 6);
                        // today = today.getDate();

                        if (env.isAmbientaly) {
                            var transportadoras = [
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                            ];
                        } else {
                            /* elton 02/08/23 tirei essas da lista da bauminas
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                              */
                            var transportadoras = [
                                "BAUMINAS LOG E TRANSPORTE LTDA",
                                "BAUMINAS LOG E TRANSPORTE LTDA.",
                                "BAUMINAS LOG E TRANSPORTES LTDA",
                                "BAUMINAS LOG E TRANSPORTE S/A",
                                "BAUMINAS LOG E TRANSPORTES S/A",
                            ];
                        }

                        if (env.isAmbientaly) {
                            return "Entrega OK / Gerar Canhoto";
                        }

                        if (dtEntrega != "" && dtEntrega < today) {
                            return "Entrega OK / Gerar Canhoto";
                        }

                        return 'outro' + dtEntrega.toString()
                    },
                    type: "buttonLabel",
                    callbackFunction: "tracking",
                    class: "btn btn-info btn-sm",
                    condition: function (row) {
                        
                        var placa = row.DAK_VEIC2;
                        var placaCavalo = row.DA3_PLACA;
                        var transp = row.A4_NOME;

                        var dtEntrega = row.C5_ENTREAL.split("/");

                        var ano = dtEntrega[2];
                        var mes = dtEntrega[1];
                        var dia = dtEntrega[0];

                        if (ano.length == 2) {
                            ano = "20" + ano;
                        }

                        dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

                        var dtEmissao = row.F2_EMISSAO.split("/");

                        var ano_e = dtEmissao[2];
                        var mes_e = dtEmissao[1];
                        var dia_e = dtEmissao[0];

                        if (ano_e.length == 2) {
                            ano_e = "20" + ano_e;
                        }

                        dtEmissao = new Date(
                            ano_e + "-" + mes_e + "-" + dia_e + " 03:00:00"
                        );

                        // dia = parseInt(dia);

                        var today = new Date();

                        var six_months = new Date(today);
                        six_months.setMonth(six_months.getMonth() - 6);

                        if (dtEmissao < six_months) {
                            return true;
                        }

                        if (env.isAmbientaly) {
                            var transportadoras = [
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                            ];
                        } else {
                            var transportadoras = [
                                "BAUMINAS LOG E TRANSPORTE LTDA.",
                                "BAUMINAS LOG E TRANSPORTES LTDA",
                                "BAUMINAS LOG E TRANSPORTE S/A",
                                "BAUMINAS LOG E TRANSPORTES S/A",
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                            ];
                        }

                        if (dtEntrega != "" && dtEntrega < today) {
                            return false;
                        }
                    },

                    conditionShow: function (row) {
                        return true;
                    },
                },
                {
                    label: "Pedido do Cliente",
                    field: "C6_NUMPCOM",
                },
                {
                    label: "N° da Nota Fiscal",
                    type: "buttonLabel",
                    callbackFunction: "danfe",
                    class: "btn btn-info btn-sm",
                    labelCallback: function (row) {
                        return row.C5_NOTA;
                    },
                    condition: function (row) {
                        if (row) {
                            return row.C5_NOTA.trim() && row.C5_LOJAENT.trim() ? false : true;
                        }
                        return false;
                    },
                    conditionShow: function (row) {
                        if (row) {
                            return row.C5_NOTA.trim() == "" ? false : true;
                        }
                        return true;
                    },
                },
                {
                    label: "Local de Entrega",
                    callback: function (row) {
                        var field = row.A1_ZAPLENT;
                        if (field.trim() == "") {
                            return "";
                        }
                        return field;
                    },
                },
                {
                    label: "Município de Entrega",
                    callback: function (row) {
                        var field = row.CC2_MUN;
                        if (field.trim() == "") {
                            return "";
                        }
                        return field;
                    },
                },
                {
                    label: "Produto",
                    field: "B1_ESPECIE",
                },
                {
                    label: "Quantidade Faturada",
                    callback: function (row) {
                        var C5_NOTA = row.C5_NOTA;

                        if (C5_NOTA.trim() != "") {
                            var C6_XUNFAT = row.C6_XUNFAT;

                            var CNE_QUANT = row.CNE_QUANT;
                            var C6_QTDENT2 = row.C6_QTDENT2;

                            if (C6_XUNFAT.trim() != "") {
                                return new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(C6_QTDENT2);
                            } else {
                                return new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(CNE_QUANT);
                            }
                        } else {
                            return "";
                        }
                    },
                },
                {
                    label: "Unidade medida",
                    callback: function (row) {
                        var C6_XUNFAT = row.C6_XUNFAT;

                        var C6_SEGUM = row.C6_SEGUM;
                        var C6_UM = row.C6_UM;

                        if (C6_XUNFAT.trim() != "") {
                            return C6_SEGUM;
                        } else {
                            return C6_UM;
                        }
                    },
                },
                {
                    label: "Previsão de Entrega",
                    field: "C6_ENTREG",
                },
                {
                    label: "Data de Entrega",
                    callback: function (row) {
                        var C5_ENTREAL = row.C5_ENTREAL;

                        if (C5_ENTREAL.split('/')[1].trim() == '') {
                            return row.C6_ENTREG;
                        }

                        return C5_ENTREAL;

                    },
                },
                {
                    label: "Data Faturamento",
                    field: "F2_EMISSAO",
                },
                {
                    label: "Valor da Nota",
                    callback: function (row) {
                        var D2_TOTAL = row.D2_TOTAL;

                        if (D2_TOTAL.trim() != "") {
                            return new Intl.NumberFormat("pt-BR", {
                                style: "decimal",
                                currency: "BRL",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }).format(D2_TOTAL);
                        } else {
                            return "";
                        }
                    },
                },
                {
                    label: "Filial",
                    callback: function (row) {
                        if (row) {
                            var string = row.C5_FILIAL;
                            string = string.substring(string.indexOf(" - ") + 2);
                            return string;
                        } else {
                            return "";
                        }
                    },
                },
                {
                    label: "Nº do Pedido",
                    field: "C5_NUM",
                },
            )
        }
    },
    mounted() {
        this.setColumns();
    }
};
</script>