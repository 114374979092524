<template>
  <div class="container-fluid">
    <button @click="$router.back()" class="btn btn-primary mt-3 mb-3">
      <font-awesome-icon :icon="['fa', 'arrow-left']" /> Voltar
    </button>
    <button @click="getDetail" class="btn btn-primary ml-3 mt-3 mb-3">
      <font-awesome-icon :icon="['fa', 'retweet']" /> Atualizar
    </button>
    <a @click="downloadCanhoto" class="btn btn-primary ml-3 mt-3 mb-3" v-if="loaded">
      <font-awesome-icon :icon="['fa', 'download']" /> Baixar Imagem
    </a>

    <div>
      <div class="detail-container">
        <div class="row" v-if="loaded">
          <div class="col-md-12 mb-3" style="font-size: 16px">
            <strong>Chave da DANFE:</strong> {{ canhoto[0].ChaveDANFE }}
          </div>
          <!-- <div class="col-md-12 mb-3" style="font-size: 16px">
            <strong>Velocidade:</strong> {{ map[0].Velocity }} km/h
          </div> -->
        </div>
        <div class="row" v-if="loaded">
          <div class="col-md-12">
            <iframe :src="canhoto[0].ImagemNF[0].URLImagem" alt="" width="100%" height="250px" id="scaled-frame" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      map: "",
      loaded: false,
      canhoto: {},
      notaFiscal: "",
    };
  },
  computed: {
    positionTime() {
      return moment(this.map[0].PositionTime).format("DD/MM/YYYY H:mm:ss");
    },
  },
  methods: {
    async downloadCanhoto() {
    try {

      const imageUrl = this.canhoto[0].ImagemNF[0].URLImagem + '/download';

      const response = await axios.get(imageUrl, {
        responseType: 'blob' 
      });

    
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpeg' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'canhoto_' + this.notaFiscal + '.jpg'); 
      document.body.appendChild(link);
      link.click();

      // Limpar
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      this.$toast.error("Erro ao tentar baixar o arquivo.");
    }
  },
    getDetail() {
      const self = this;
      self.loaded = false;
      let data = self.$route.params.params;

      var api = self.$store.getters.api + "delivery-canhoto/";

      axios
        .post(api, { info: data })
        .then((response) => {
          self.canhoto = response.data;

          // Armazena a nota fiscal para uso no nome do arquivo
          self.notaFiscal = JSON.parse(atob(data)).nota_fiscal;

          if (!self.canhoto[0].ImagemNF[0].URLImagem) {
            this.$swal
              .fire({
                title:
                  "O canhoto desta Nota Fiscal estará disponível em breve.",
                text: "O canhoto está passando por uma análise e logo estará disponível para consulta, tente novamente mais tarde.",
                icon: "error",
                confirmButtonText: "OK",
              })
              .then((result) => {
                self.$router.back();
              });
          }

          self.loaded = true;
        })
        .catch((error) => {
          this.$toast.error("Canhoto não disponível para visualização.");
        });
    },
  },

  mounted() {
    const self = this;

    self.getDetail();
  },
};
</script>
<style scoped>
.btn{

  color: #ffff !important;
}
</style>