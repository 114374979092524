<template>
  <div>
    <section>
      <div class="row ml-4 mr-4 mt-5">
        <div class="col-md-12">
          <label><span class="required">* </span>Produto</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.product_name"
            disabled
          />
        </div>
        <div class="col-md-4 mt-2">
          <label><span class="required">* </span>Nº da Negociação</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.internal_contract"
            disabled
          />
        </div>
        <!-- <div class="col-md-5 mt-2">
              <label>Contrato Interno</label>
              <input
                type="text"
                class="form-control"
                v-model="new_order.internal_contract"
                disabled
              />
            </div> -->
        <div class="col-md-2 mt-2">
          <label>Valor Unit.</label>
          <input
            type="text"
            class="form-control"
            v-model="valorUnitF"
            disabled
          />
        </div>
        <div class="col-md-2 mt-2">
          <label>Und.</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.und"
            disabled
          />
        </div>
        <div class="col-md-4 mt-2">
          <label>Condição de Pagamento</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.payment_condition"
            disabled
          />
        </div>
        <div class="col-md-12 mt-2">
          <label>Fábrica de Origem</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.manufacturing_origin"
            disabled
          />
        </div>
        <div class="col-md-4 mt-2">
          <label>Vencimento do Contrato</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.contract_expiration"
            disabled
          />
        </div>
        <div class="col-md-4 mt-2">
          <label>Saldo do Contrato</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.contract_balance"
            disabled
          />
        </div>
      </div>
      <div class="row ml-4 mr-4 mt-5">
        <div class="col-md-8">
          <label><span class="required">* </span>Endereço de Entrega</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.delivery_address"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label><span class="required">* </span>Bairro</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.delivery_neighborhood"
            disabled
          />
        </div>
        <div class="col-md-4 mt-2">
          <label><span class="required">* </span>Cidade</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.delivery_city"
            disabled
          />
        </div>
        <div class="col-md-3 mt-2">
          <label><span class="required">* </span>Estado</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.delivery_state"
            disabled
          />
        </div>
        <div class="col-md-3 mt-2">
          <label><span class="required">* </span>Tipo do Frete</label>
          <select
            class="form-select"
            v-model="new_order.delivery_type"
            disabled
          >
            <option value="C">CIF</option>
            <option value="F">FOB</option>
            <option value="T">Por conta terceiros</option>
            <option value="R">Por conta remetente</option>
            <option value="D">Por conta destinatário</option>
            <option value="S">Sem frete</option>
          </select>
        </div>
        <div class="col-md-2 mt-2">
          <label><span class="required">* </span>Data da Solicitação</label>
          <input
            type="date"
            class="form-control"
            v-model="new_order.date"
            disabled
          />
        </div>
        <div class="col-md-3 mt-2">
          <label><span class="required">* </span>Quantidade</label>
          <input
            type="number"
            class="form-control"
            v-model="new_order.quantity"
            disabled
          />
        </div>
        <div class="col-md-2 mt-2">
          <label>Valor</label>
          <input
            type="text"
            class="form-control"
            v-model="orderValue"
            disabled
          />
        </div>
        <div class="col-md-2 mt-2">
          <label>Und.</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.und"
            disabled
          />
        </div>
        <div class="col-md-2 mt-2">
          <label>Pedido do Cliente</label>
          <input
            type="text"
            class="form-control"
            v-model="new_order.client_order"
            disabled
          />
        </div>
        <div class="col-md-3 mt-2">
          <label>Item.Ped.Com</label>
          <input
            disabled
            type="text"
            class="form-control"
            v-model="new_order.item_ped_com"
          />
        </div>
      </div>

      <div class="row ml-4 mr-4">
        <!-- <div class="col-md-4 mt-3">
          <div class="row">
            <div class="col-md-12">
              <label><span class="required">* </span>Dias de Recebimento</label>
            </div>
            <div class="col-md-12 mt-2">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c1"
                  required=""
                  v-model="new_order.all_days"
                  disabled
                />
                <label class="form-check-label" for="c1">Todos</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c2"
                  required=""
                  v-model="new_order.monday"
                  disabled
                />
                <label class="form-check-label" for="c2">Segunda-feira</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c3"
                  required=""
                  v-model="new_order.tuesday"
                  disabled
                />
                <label class="form-check-label" for="c3">Terça-feira</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c4"
                  required=""
                  v-model="new_order.wednesday"
                  disabled
                />
                <label class="form-check-label" for="c4">Quarta-feira</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c5"
                  required=""
                  v-model="new_order.thursday"
                  disabled
                />
                <label class="form-check-label" for="c5">Quinta-feira</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c6"
                  required=""
                  v-model="new_order.friday"
                  disabled
                />
                <label class="form-check-label" for="c6">Sexta-feira</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c7"
                  required=""
                  v-model="new_order.saturday"
                  disabled
                />
                <label class="form-check-label" for="c7">Sábado</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c8"
                  required=""
                  v-model="new_order.sunday"
                  disabled
                />
                <label class="form-check-label" for="c8">Domingo</label>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-check custom-checkbox mb-3 checkbox-info">
                <input
                  type="checkbox"
                  class="form-check-input"
                  checked=""
                  id="c9"
                  required=""
                  v-model="new_order.holiday"
                  disabled
                />
                <label class="form-check-label" for="c9">Feriados</label>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-4 mt-3">
          <div class="row">
            <div class="col-md-12">
              <label><span class="required">*</span> Data de entrega</label>
            </div>
            <div class="col-md-12">
              <input
                type="date"
                class="form-control"
                v-model="new_order.delivery_date"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <div class="row">
            <div class="col-md-6">
              <label><span class="required">*</span> Horário de:</label>
              <input
                type="time"
                class="form-control"
                v-model="new_order.hour_receive_start"
                disabled
              />
            </div>
            <div class="col-md-6">
              <label><span class="required">*</span> Horário até:</label>
              <input
                type="time"
                class="form-control"
                v-model="new_order.hour_receive_end"
                disabled
              />
            </div>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <label>Observações do Pedido</label>
          <textarea
            class="form-control"
            cols="40"
            rows="10"
            v-model="new_order.observation"
            disabled
          ></textarea>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import vSelect from "vue-select";
import _ from "lodash";
import Toggle from "@vueform/toggle";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top-right",
});

export default {
  components: {
    vSelect,
    Toggle,
  },
  data() {
    return {
      contracts: [],
      step: 1,
      company: {},
      currentPage: 0,
      firstPage: 1,
      lastPage: 0,
      count: 0,
      new_order: {
        date: moment().format("YYYY-MM-DD"),
        unit_value: 0,
        all_days: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        holiday: false,
      },
      productSelected: {},
      tipoCliente: 1, // 1 = Publico, 2 = Privado
      searchProductField: "",
      simNao: {
        onLabel: "SIM",
        offLabel: "NÃO",
      },
      products: [],
      value: true,
      customers: [],
      stores: [],
      selectedCustomer: {},
      user: {
        name: "",
        email: "",
        password: "",
        customer_id: null,
        stores: [],
        type_id: "",
        access_contracts: false,
        access_invoices: false,
        access_requests: false,
        access_logistics: false,
      },
    };
  },
  computed: {
    customerList() {
      return this.customers;
    },
    orderValue() {
      return new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        currency: "BRL",
        minimumFractionDigits: 5,
      }).format(this.new_order.value);
    },
    valorUnitF() {
      return new Intl.NumberFormat("pt-BR", {
        style: "decimal",
        currency: "BRL",
        minimumFractionDigits: 5,
      }).format(this.new_order.unit_value);
    },
  },
  methods: {
    getEntity(id) {
      const self = this;
      const api = this.$store.state.api + "orders-not-integrateds/" + id;

      axios
        .get(api)
        .then((response) => {
          self.new_order = response.data.data[0];
          self.new_order.all_days = self.new_order.all_days == 1 ? true : false;
          self.new_order.monday = self.new_order.monday == 1 ? true : false;
          self.new_order.tuesday = self.new_order.tuesday == 1 ? true : false;
          self.new_order.wednesday =
            self.new_order.wednesday == 1 ? true : false;
          self.new_order.thursday = self.new_order.thursday == 1 ? true : false;
          self.new_order.friday = self.new_order.friday == 1 ? true : false;
          self.new_order.saturday = self.new_order.saturday == 1 ? true : false;
          self.new_order.sunday = self.new_order.sunday == 1 ? true : false;
          self.new_order.holiday = self.new_order.holiday == 1 ? true : false;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
    getCustomer() {
      const self = this;
      const api = this.$store.state.api + "get-customer/";

      axios
        .post(api)
        .then((response) => {
          self.company = response.data.data.ZOOM[0];
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message);
          self.loading = false;
        });
    },
  },
  mounted() {
    const self = this;

    let id = this.$route.params.id;

    self.getCustomer();
    self.getEntity(id);
  },
};
</script>
<style scoped>
label {
  font-weight: bold;
}
.card-title {
  font-size: 16px;
  margin-top: -15px;
  margin-bottom: -15px !important;
}
.card-header {
  background-color: #f1f1f1 !important;
  border: 1px solid #e1e1e1 !important;
}
.card-body {
  border: 1px solid #e1e1e1 !important;
}
.bg {
  background-color: blueviolet;
}
.bold {
  font-weight: bold;
  font-size: 16px;
  margin-top: 1px;
}
.toggleStyle {
  --toggle-width: 4rem !important;
  --toggle-height: 1.45rem !important;
  --toggle-font-size: 0.85rem !important;
  font-weight: bold;
}
#ModalProduto {
  padding: 15px;
}
#ModalPedido {
  padding: 15px;
}
.badge-primary {
  background-color: var(--primary);
  color: #fff;
}
.modal-dialog {
  overflow-y: initial !important;
}
.modal-content {
  max-height: 550px;
  overflow-y: auto !important;
}
.step-circle::before {
  /* content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px; */
  width: calc(5rem + 1rem - 2.5rem);
  /* height: 2px;
  transform: translate(-100%, -50%);
  color: #adb5bd;
  background-color: currentColor; */
}
.step-circle {
  /* position: relative;
    display: flex;
    justify-content: center;
    align-items: center; */
  width: 2.5rem;
  height: 2.5rem;
  /* color: #adb5bd;
    border: 2px solid #adb5bd;
    border-radius: 100%;
    background-color: #fff; */
}
.form-control:disabled,
.form-control[readonly] {
  background: rgba(147, 147, 147, 0.17);
  opacity: 1;
}
.form-check-input:disabled ~ .form-check-label {
  color: #7e7e7e !important;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 100 !important;
}
.required {
  color: red;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
