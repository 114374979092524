<template>
    <div class="container-fluid">
        <base-table
            :ref="table"
            :filters="filters"
            :crudName="crudName"
            :newText="newText"
            :table="table"
            :endPoint="endPoint"
            :enableAdd="false"
            :enableEdit="true"
            :enableDelete="true"
            :enableExtract="true"
            :layoutNames="layoutNames"
            :enableSaveColumns="true"
            :relationships="relationships"
            :extras="extras"
            :orders="orders"
            :columns="columns"
            @fispq="fispq"
            @report="report"
            @tracking="tracking"
            :styleHeader="'border-bottom: 5px solid #51A6F5'"
            @danfe="danfe"
        ></base-table>
    </div>
</template>

<script>
import BaseTable from "@/components/Layouts/BaseTable.vue";
import env from "../../env.js";

export default {
    data() {
        return {
            loaded: false,
            extras: [],
            orders: [
                {
                    name: "",
                    column: "",
                },
                {
                    name: "Nº do Pedido",
                    column: "C5_NUM",
                },
                {
                    name: "Pedido Cliente",
                    column: "C6_NUMPCOM",
                },
                {
                    name: "Nº da Nota Fiscal",
                    column: "C5_NOTA",
                },
                {
                    name: "Local de Entrega",
                    column: "A1_ZAPLENT",
                    selected: true,
                },
            ],
            filters: [
                {
                    id: "nao_entregues",
                    type: "hidden",
                    name: "nao_entregues",
                    label: "Não Entregues",
                    value: "true",
                    checked: true,
                },
                {
                    id: "invoiceType",
                    type: "hidden",
                    name: "status",
                    label: "Faturados",
                    value: "billed",
                    checked: true,
                },
                {
                    name: "C5_NUM",
                    label: "N° do Pedido",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "C6_NUMPCOM",
                    label: "Pedido do Cliente",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "C5_MDCONTR",
                    label: "Nº da Negociação",
                    value: "",
                    type: "contract",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "B1_DESC",
                    product_column: "B1_ESPECIE",
                    label: "Produto",
                    value: "",
                    type: "product",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3 mt-2",
                },
                
                {
                    name: "C6_ENTREG",
                    label: "Previsão de Entrega",
                    value: "",
                    type: "date",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "A1_ZAPLENT",
                    label: "Local de Entrega",
                    value: "",
                    type: "text",
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                {
                    name: "CN9_SITUAC",
                    label: "Status",
                    value: "",
                    type: "select",
                    options: {
                        "": "",
                        "01": "Cancelado",
                        "02": "Elaboração",
                        "03": "Emitido",
                        "04": "Aprovado",
                        "05": "Vigente",
                        "06": "Paralisado",
                        "07": "Solicitação Finalizada",
                        "08": "Finalizado",
                        "09": "Revisão",
                        10: "Revisado",
                        A: "Aprovado Revisão",
                    },
                    applied: false,
                    class: "form-control",
                    columnSize: "col-3",
                },
                // {
                //     column: "text",
                //     label: "Ativos/Finalizados",
                //     value: "",
                //     type: "text",
                //     applied: false,
                // },
            ],
            crudName: "Pedidos em Rota",
            newText: "",
            table: "order-list",
            endPoint: "orders/",
            relationships: [],
            columns: [],
        };
    },
    components: {
        BaseTable,
    },
    methods: {
        tracking(data) {
            data = btoa(
                JSON.stringify({
                    placa: data.DA3_PLACA,
                    placaCav: data.DAK_VEIC2,
                    transportadora: data.A4_NOME,
                    notafiscal: data.F2_DOC,
                })
            );

            this.$router.push("/admin/delivery/tracking/" + data);
        },
        fispq(data) {
            data = btoa(
                JSON.stringify({
                    nome_produto: data.B1_DESC,
                })
            );
            this.$router.push("/admin/order/fispq/" + data);
        },
        report(data) {
            const obj = {
                filial: data.F2_FILIAL,
                doc: data.F2_DOC,
                serie: data.F2_SERIE
            }
            data = btoa(
                JSON.stringify({
                    nota_fiscal: data.F2_DOC.trim(),
                })
            );
            this.$store.dispatch("setOrderReport", obj);
            this.$router.push("/admin/order/report/" + data);
        },
        encode(string) {
            return btoa(string);
        },
        danfe(data) {
            const self = this;

            if (data.C5_NOTA.trim() && data.C5_LOJAENT.trim()) {
                data = btoa(
                    JSON.stringify({
                        nota_fiscal: data.C5_NOTA.trim(),
                        loja_ent: data.C5_LOJAENT.trim(),
                        contrato: data.C5_MDCONTR.trim(),
                        filial: data.C5_FILIAL.trim(),
                    })
                );
                this.$router.push("/admin/invoice/danfe/" + data);
                // window
                //   .open(
                //     self.$store.state.api +
                //       "invoices/danfe/" +
                //       data.E1_NUM.trim() +
                //       "/" +
                //       data.C5_LOJAENT.trim() +
                //       "/" +
                //       data.C5_MDCONTR,
                //     "_blank"
                //   )
                //   .focus();
            } else {
                alert("DANFE não disponível para esse título");
            }
        },
        setColumns() {
            if(env.isAmbientaly) {
                this.columns.push(
                    {
                        label: "Laudo",
                        type: "button",
                        callbackFunction: "report",
                        class: "btn btn-info btn-sm",
                        condition: function (row) {
                            if (['0650004','0650001','0650002','0650009','0650008'].indexOf(row.F2_FILIAL) > -1) {
                                if (row.F2_DOC.trim() == "") {
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                        },
                        conditionShow: function (row) {
                            if (['0650004','0650001','0650002','0650009','0650008'].indexOf(row.F2_FILIAL) > -1) {
                                if (row.F2_DOC.trim() == "") {
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    }
                )
            }
            this.columns.push(              
                {
                    label: "Local de Entrega",
                    callback: function (row) {
                        var field = row.A1_ZAPLENT;
                        if (field.trim() == "") {
                            return "";
                        }
                        return field;
                    },
                },
                {
                    label: "Município de Entrega",
                    callback: function (row) {
                        var field = row.CC2_MUN;
                        if (field.trim() == "") {
                            return "";
                        }
                        return field;
                    },
                },
                {
                    label: "Produto",
                    field: "B1_ESPECIE",
                },
                {
                    label: "Quantidade Faturada",
                    callback: function (row) {
                        var C5_NOTA = row.C5_NOTA;

                        if (C5_NOTA.trim() != "") {
                            var C6_XUNFAT = row.C6_XUNFAT;

                            var CNE_QUANT = row.CNE_QUANT;
                            var C6_QTDENT2 = row.C6_QTDENT2;

                            if (C6_XUNFAT.trim() != "") {
                                return new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(C6_QTDENT2);
                            } else {
                                return new Intl.NumberFormat("pt-BR", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(CNE_QUANT);
                            }
                        } else {
                            return "";
                        }
                    },
                },
                {
                    label: "Unidade medida",
                    callback: function (row) {
                        var C6_XUNFAT = row.C6_XUNFAT;

                        var C6_SEGUM = row.C6_SEGUM;
                        var C6_UM = row.C6_UM;

                        if (C6_XUNFAT.trim() != "") {
                            return C6_SEGUM;
                        } else {
                            return C6_UM;
                        }
                    },
                },
                {
                    label: "N° da Nota Fiscal",
                    type: "buttonLabel",
                    callbackFunction: "danfe",
                    class: "btn btn-info btn-sm",
                    labelCallback: function (row) {
                        return row.C5_NOTA;
                    },
                    condition: function (row) {
                        if (row) {
                            return row.C5_NOTA.trim() && row.C5_LOJAENT.trim() ? false : true;
                        }
                        return false;
                    },
                    conditionShow: function (row) {
                        if (row) {
                            return row.C5_NOTA.trim() == "" ? false : true;
                        }
                        return true;
                    },
                },
                {
                    labelCallback: function (row) {
                        var placa = row.DAK_VEIC2;
                        var placaCavalo = row.DA3_PLACA;
                        var transp = row.A4_NOME;

                        // var dtEntrega = row.C5_ENTREAL;
                        // if (dtEntrega == "  /  /  ") {
                        //   return "Rastreamento Indisponível";
                        // }
                        var dtEntrega = row.C5_ENTREAL.split("/");

                        var ano = dtEntrega[2];
                        var mes = dtEntrega[1];
                        var dia = dtEntrega[0];

                        if (ano.length == 2) {
                            ano = "20" + ano;
                        }

                        dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

                        dia = parseInt(dia);

                        var today = new Date();
                        // today = today.getDate();

                        if (dtEntrega != "" && dtEntrega < today) {
                            return "Pedido Entregue";
                        }

                        if (env.isAmbientaly) {
                            var transportadoras = [
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                                "AMBIENTALY IND E COM DE PROD QUIMICOS LT"
                            ];
                        } else {
                            /* elton 02/08/23 tirei essas da lista da bauminas
                              "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                              "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                            */
                            var transportadoras = [
                                "BAUMINAS LOG E TRANSPORTE LTDA.",
                                "BAUMINAS LOG E TRANSPORTES LTDA",
                                "BAUMINAS LOG E TRANSPORTE S/A",
                                "BAUMINAS LOG E TRANSPORTES S/A",
                            ];
                        }

                        if (transportadoras.includes(transp.trim())) {
                            if (dtEntrega != "" && dtEntrega < today) {
                                return "Pedido Entregue";
                            }
                            if (placa.trim() != "") {
                                return row.C6_ENTREG;
                            } else if (placaCavalo.trim() != "") {
                                return row.C6_ENTREG;
                            } else {
                                return row.C6_ENTREG;
                            }
                        } else {
                            return row.C6_ENTREG;
                        }
                    },
                    type: "buttonLabel",
                    callbackFunction: "tracking",
                    class: "btn btn-info btn-sm",
                    condition: function (row) {
                        var placa = row.DAK_VEIC2;
                        var placaCavalo = row.DA3_PLACA;
                        var transp = row.A4_NOME;

                        // var dtEntrega = row.C5_ENTREAL;
                        // if (dtEntrega == "  /  /  ") {
                        //   return true;
                        // }
                        var dtEntrega = row.C5_ENTREAL.split("/");

                        var ano = dtEntrega[2];
                        var mes = dtEntrega[1];
                        var dia = dtEntrega[0];

                        if (ano.length == 2) {
                            ano = "20" + ano;
                        }

                        dtEntrega = new Date(ano + "-" + mes + "-" + dia + " 03:00:00");

                        // dia = parseInt(dia);

                        var today = new Date();
                        // today = today.getDate();

                        if (env.isAmbientaly) {
                            var transportadoras = [
                                "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                                "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                                'AMBIENTALY IND E COM DE PROD QUIMICOS LT'
                            ];
                        } else {
                            /* elton 02/08/23 tirei essas da lista da bauminas
                              "AMBIENTALY INDUSTRIA E COMERCIO DE PRODU",
                              "AMBIENTALY IND E COM DE PRODUTOS QUIMICO",
                            */
                            var transportadoras = [
                                "BAUMINAS LOG E TRANSPORTE LTDA",
                                "BAUMINAS LOG E TRANSPORTE LTDA.",
                                "BAUMINAS LOG E TRANSPORTES LTDA",
                                "BAUMINAS LOG E TRANSPORTE S/A",
                                "BAUMINAS LOG E TRANSPORTES S/A",
                            ];
                        }

                        if (transportadoras.includes(transp.trim())) {

                            return false;

                        } else {
                            return true;
                        }
                    },

                    conditionShow: function (row) {
                        return true;
                    },
                    label: "Previsão de Entrega",
                    },
                    {
                        label: "Pedido do Cliente",
                        field: "C6_NUMPCOM",
                    },
                    {
                        label: "Valor Total do Item",
                        callback: function (row) {
                            var C6_VALOR = row.C6_VALOR;

                            return new Intl.NumberFormat("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                            }).format(C6_VALOR);
                        },
                    },
                    {
                        label: "Filial",
                        callback: function (row) {
                            if (row) {
                                var string = row.C5_FILIAL;
                                string = string.substring(string.indexOf(" - ") + 2);
                                return string;
                            } else {
                                return "";
                            }
                        },
                    },
                    {
                        label: "Nº do Pedido",
                        field: "C5_NUM",
                    }
                    
            )
        }
    },

    mounted() {
        this.setColumns();
    },
};
</script>